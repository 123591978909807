import { FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "./usersApiSlice";
import { memo } from "react";

const User = ({ userId }) => {
  const { user } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[userId],
    }),
  });

  // console.log(user);

  const navigate = useNavigate();

  if (user) {
    const handleEdit = () => navigate(`/users/${userId}`);

    const userRolesString = user.roles.toString().replaceAll(",", ", ");

    const cellStatus = user.active ? "" : "tableCellInactive";

    return (
      <tr className="table__row user">
        <td className={`tableCell ${cellStatus}`}>{user.email}</td>
        <td className={`tableCell ${cellStatus}`}>{user.telephone}</td>
        <td className={`tableCell ${cellStatus}`}>{user.firstname}</td>
        <td className={`tableCell ${cellStatus}`}>{user.lastname}</td>
        <td className={`tableCell ${cellStatus}`}>{userRolesString}</td>
        <td className={`tableCell ${cellStatus}`}>
          {user.companyName || "N/A"}
        </td>
        <td
          className={`tableCell ${cellStatus}`}
        >{`${user.location.latitude}, ${user.location.longitude}`}</td>

        <td className={`tableCell ${cellStatus}`}>
          <button className="icon-button table__button" onClick={handleEdit}>
            <FaPen />
          </button>
        </td>
      </tr>
    );
  } else return null;
};

const memoizedUser = memo(User);
export default memoizedUser;
