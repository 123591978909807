import { useGetUsersQuery } from "./usersApiSlice";
import User from "./User";
import { HashLoader } from "react-spinners";

const UsersList = () => {
  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = users;

    const tableContent = ids?.length
      ? ids.map((userId) => <User key={userId} userId={userId} />)
      : null;

    content = (
      <table className="tableOfUsers">
        <thead className="tableThead">
          <tr>
            <th scope="col" className="userTableEmail">
              Email
            </th>
            <th scope="col" className="userTableTelephone">
              Telephone
            </th>
            <th scope="col" className="userTableFirstname">
              Firstname
            </th>
            <th scope="col" className="userTableLastname">
              Lastname
            </th>
            <th scope="col" className="userTableRoles">
              Roles
            </th>
            <th scope="col" className="userTableCompanyName">
              Company Name
            </th>
            <th scope="col" className="userTableLocation">
              Location
            </th>
            <th scope="col" className="userTableEdit">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>{tableContent}</tbody>
      </table>
    );
  }

  return content;
};
export default UsersList;
