import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllDealersQuery } from "../features/users/usersApiSlice";

const DealerSearchBar = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // New state to track if the dropdown is open
  const dropdownRef = useRef(); // Reference to the dropdown container

  // Fetching dealers from your RTK query
  const {
    data: dealers,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllDealersQuery(undefined, {
    pollingInterval: 1200000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false); // Close the dropdown
      }
    };

    // Add the event listener
    document.addEventListener("click", handleOutsideClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []); // Empty dependency array to ensure it's added/removed on mount/unmount

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError && error) {
    return <div>Error loading dealers: {error?.data?.message}</div>;
  }

  // Filtering dealers based on the search text
  const filteredDealers = isSuccess
    ? Object.values(dealers.entities).filter((dealer) =>
        dealer.companyName.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];

  // Navigate to the selected dealership
  const handleDealerSelect = (dealerId) => {
    navigate(`/${dealerId}`); // Adjust the path as needed
    setIsDropdownOpen(false);
  };

  return (
    <div className="searchBar" ref={dropdownRef}>
      <input
        type="text"
        className="searchInput"
        placeholder="Search for a dealership..."
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          setIsDropdownOpen(true); // Open the dropdown when typing
        }}
      />
      {/* Only render the dropdown if there is search text and filtered results */}
      {isDropdownOpen && searchText && filteredDealers.length > 0 && (
        <div className="searchDropdown">
          {filteredDealers.map((dealer) => (
            <div
              className="searchDropdownItem"
              key={dealer._id}
              onClick={() => handleDealerSelect(dealer._id)}
            >
              <div className="searchDropdownItemLeft">
                {" "}
                <img
                  src={dealer.profilePicture}
                  alt="Dealer PFP"
                  className="dealerSearchBarDropdownImg"
                />
                {dealer.companyName}
              </div>

              <span> {dealer?.address?.postcode}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DealerSearchBar;
