import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  Legend,
  CartesianGrid,
} from "recharts";

import { useEffect, useState } from "react";

import { HashLoader } from "react-spinners";

const SalesLineChart = ({
  filteredBookings,
  isSuccess,
  isLoading,
  isError,
  error,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchRevenueDataForPastSixMonths = () => {
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 5);

      const monthlyRevenueData = [];
      for (let i = 5; i >= 0; i--) {
        const currentMonth = new Date();
        currentMonth.setMonth(currentMonth.getMonth() - i);
        const startOfMonth = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth(),
          1
        );
        const endOfMonth = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth() + 1,
          0
        );

        const month = currentMonth.toLocaleString("en-US", { month: "long" });

        // const revenue = filteredBookings
        //   .filter(
        //     (booking) =>
        //       new Date(booking.dateOfBooking) >= startOfMonth &&
        //       new Date(booking.dateOfBooking) <= endOfMonth
        //   )
        //   .reduce(
        //     (monthRevenue, booking) => monthRevenue + booking.totalPrice,
        //     0
        //   );

        const revenue = filteredBookings
          .filter(
            (booking) =>
              new Date(booking.dateOfBooking) >= startOfMonth &&
              new Date(booking.dateOfBooking) <= endOfMonth
          )
          .reduce(
            (monthRevenue, booking) =>
              monthRevenue +
              (booking.totalPrice - booking.vehicleDetails.deposit), // Subtract deposit
            0
          );

        monthlyRevenueData.push({ month, revenue });
      }

      return monthlyRevenueData;
    };

    const fetchData = async () => {
      try {
        const revenueData = await fetchRevenueDataForPastSixMonths();
        setChartData(revenueData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [filteredBookings]); // Empty dependency array to run only once on mount

  const chartConfig = (
    <LineChart width={600} height={300} data={chartData}>
      <XAxis
        dataKey="month"
        className="custom-x-axis-linechart"
        tick={(props) => (
          <text
            x={props.x}
            y={props.y}
            dy={15}
            textAnchor="middle"
            fill="#ADB1B2"
            fontSize={16}
            fontFamily="EB Garamond"
            fontWeight={600}
            style={{
              lineHeight: "16px",
              letterSpacing: "0.4px",
              marginTop: 10,
            }}
          >
            {props.payload.value}
          </text>
        )}
      />
      <YAxis
        className="custom-y-axis-linechart"
        tick={(props) => (
          <text
            x={props.x}
            y={props.y}
            dy={props.dy}
            textAnchor="end"
            fill="#ADB1B2"
            fontSize={12}
            fontFamily="EB Garamond"
            fontWeight={700}
            style={{ lineHeight: "16px", letterSpacing: "0.4px" }}
          >
            {props.payload.value}
          </text>
        )}
      />
      <CartesianGrid />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="revenue"
        stroke="#0561FC"
        fill="#0561FC;"
        dot={false}
        animationBegin={0}
        animationDuration={1000} // Adjust the duration as needed
      />
    </LineChart>
  );

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="dashboardDealershipAnalytics2LineChartResponsiveContainer">
        <ResponsiveContainer key="revenue-chart" width={700} height={300}>
          {chartConfig}
        </ResponsiveContainer>
      </div>
    );
  }
  return content;
};

export default SalesLineChart;
