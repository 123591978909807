import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Dynamically import all images in the folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("./landingPageImgs", false, /\.(png|jpe?g|svg)$/)
);

const faqItems = [
  {
    question: "What is this and how does it work?",
    answer: (
      <>
        This is a booking platform/software where you can list your vehicles and
        customers can book through the site. All you do is create a vehicle and
        add very specific parameters such as days the vehicle is available,
        minimum age, etc. Once this is complete, you are ready to go! Just share
        the link to your dealership under the Customer tab on the dashboard.
        When an order comes through, it arrives as a request, which you can then
        accept or decline. But it doesn't stop there; you can also view sales
        data, customer data, easily see when vehicles are due to go in/out, plus
        much more. Here is a link to our {""}
        <a
          href="https://youtu.be/lsvHFXmsAdg?si=XIZr-oTfvlD1DRvI"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube channel
        </a>
        {""} for a full in-depth walkthrough.
      </>
    ),
  },
  {
    question: "How Long Does It Take To Get Set Up?",
    answer:
      "You will be up and running within a few minutes. Firstly to create an account requires a few details and only takes around 30s to make. When choosing a plan you need to enter a few more details which takes around 30s. Then you are in. To Add a Vehicle takes around 1-2 Mins to fill in the required parameters. To Add previous bookings also takes around 1-2Mins. ",
  },
  {
    question: "What About Bookings I Get Via Phone or Email?",
    answer:
      "Bookings you get from elsewhere you can add under the Manual Orders Tab. That way the vehicle cannot be booked during those dates. This way it still allows you to track sales data and you still get reminders on when the vehicle is due to go in/out. ",
  },
  {
    question: "What if my vehicle is damaged?",
    answer:
      "You can mark your vehicle as damaged and there is page under the Reports tab which shows damaged vehicles. If the vehicle is in a major collision you can disable the vehicle from being booked any more by editing the vehicle and de-selecting the Available Checkbox. We are working on implementing a feature where you can upload before and after images to each booking.",
  },
  {
    question: "Can My Customer Pay Me Through the Website",
    answer:
      "Currently we do not accept payments through the site. We are working to obtain FCA Approval and expect to have this feature available either late decemeber or early Q1 2025",
  },
  {
    question: "Is There a Free Trial?",
    answer:
      "Yes, there is a free trial. All plans come with a 5 day free trial. You will only be charged once those 5 days are over. You can cancel anytime to avoid being charged, just head over to the Profile tab and manage your plan from there.",
  },
  {
    question: "I Want a Feature That is Not Available Yet, How Can i Add This",
    answer:
      "We frequently roll out updates adding more and more features. If you want to suggest a feature email IMAS-Cars@outlook.com with your request. Alternatively contact our sales representative.",
  },
];

const DealershipLandingPage = () => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === images.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? images.length - 1 : slide - 1);
  };

  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  const navigate = useNavigate();

  const createAccountOnClick = () => {
    navigate("/users/newDealership");
  };

  const content = (
    <div className="dealershipInfo">
      <div className="dealershipLandingPageTopHalf">
        <h1>Manage All Your Bookings in One Place</h1>
        <p>Use the Image Slider to View What The Site Has To Offer</p>
        <p> (Flip Your Device Sideways For a Better View)</p>
        <p>
          All Plans Come With a 5 Day Free Trial Which You Can Cancel Anytime
        </p>
        <p>Takes Only a Few Minutes To Get Set Up and Taking Orders</p>
        <button onClick={createAccountOnClick}>Create Account</button>
      </div>
      <div className="dealershipLandingPageBottomHalf">
        <div className="imagesCollection-DLP">
          <FaArrowCircleLeft
            onClick={prevSlide}
            className="arrow-DLP arrow-left-DLP"
          />
          {images.map((item, idx) => (
            <img
              src={item}
              alt={`Slide ${idx + 1}`}
              key={idx}
              className={
                slide === idx ? "slide-DLP" : "slide-DLP slide-hidden-DLP"
              }
            />
          ))}
          <FaArrowCircleRight
            onClick={nextSlide}
            className="arrow-DLP arrow-right-DLP"
          />
          <span className="indicators-DLP">
            {images.map((_, idx) => (
              <button
                key={idx}
                className={
                  slide === idx
                    ? "indicator-DLP"
                    : "indicator-DLP indicator-inactive-DLP"
                }
                onClick={() => setSlide(idx)}
              ></button>
            ))}
          </span>
        </div>
      </div>
      <div className="DealershipLandingPageFAQ">
        <h1>Frequently Asked Questions</h1>
        <p>
          Find Answers to Common Questions About Our Services and Policies in
          Our FAQ Section. For Further Help, Contact IMAS-Cars@outlook.com
        </p>
        <p>Alternatively Call Our Sales Representitive</p>
        {/* <div className="DealershipLandingPageFAQBox">
          <div className="DealershipLandingPageFAQBoxQ1">
            <div className="DealershipLandingPageFAQBoxQ1Heading">
              <h2>How Does It Work?</h2>
              <button>
                {" "}
                <FaPlus className="DealershipLandingPageFAQBoxIcon" />
              </button>
            </div>

            <div className="DealershipLandingPageFAQBoxQ1Answer">
              <p>Hiiiii</p>
            </div>
          </div>
          <div className="DealershipLandingPageFAQBoxQ2"></div>
          <div className="DealershipLandingPageFAQBoxQ3"></div>
          <div className="DealershipLandingPageFAQBoxQ4"></div>
        </div> */}
        <div className="DealershipLandingPageFAQBox">
          <div className="DealershipLandingPageFAQBoxAccordion">
            {faqItems.map((item, i) => (
              <div className="DealershipLandingPageFAQBoxItem" key={i}>
                <div
                  className="DealershipLandingPageFAQBoxItemTitle"
                  onClick={() => toggle(i)}
                >
                  <h2>{item.question}</h2>
                  <span>{selected === i ? "-" : "+"}</span>
                </div>
                <div
                  className={
                    selected === i
                      ? "DealershipLandingPageFAQBoxContent showBox"
                      : "DealershipLandingPageFAQBoxContent"
                  }
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default DealershipLandingPage;
