import { useGetVehiclesQuery } from "../../vehicles/vehiclesApiSlice";
import useAuth from "../../../hooks/useAuth";
import { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import { FaRandom, FaSearch } from "react-icons/fa";

const DamagedVehicles = () => {
  const { companyName } = useAuth();

  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const {
    data: vehicles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetVehiclesQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    // Check if vehicles data is available and not in a loading/error state
    if (isSuccess && vehicles) {
      // Filter vehicles based on companyName
      const userVehicles = Object.values(vehicles.entities).filter(
        (vehicle) => vehicle.companyName === companyName
      );

      // Set filtered vehicles to state
      setFilteredVehicles(userVehicles);
    }
  }, [companyName, isSuccess, vehicles]);

  const getDamagedVehicles = () => {
    if (filteredVehicles.length === 0) {
      return [];
    }

    const damagedVehicles = filteredVehicles.filter(
      (vehicle) =>
        vehicle.status === "Major Damage" || vehicle.status === "Minor Damage"
    );

    return damagedVehicles;
  };

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    const damagedVehicles = getDamagedVehicles();

    content = (
      <div className="damagedVehicles">
        <div className="damagedVehiclesTable">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Mileage High
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Mileage Low
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>
          {damagedVehicles.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Model Variant</th>
                  <th>Registration</th>
                  <th>Year</th>
                  <th>Colour </th>
                  <th>Mileage</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {damagedVehicles
                  .filter((vehicle) => {
                    const searchTerms = searchQuery.toLowerCase().split(" ");
                    return (
                      searchTerms.length === 0 ||
                      searchTerms.some(
                        (term) =>
                          vehicle.make.toLowerCase().includes(term) ||
                          vehicle.model.toLowerCase().includes(term) ||
                          vehicle.modelVariant.toLowerCase().includes(term) ||
                          vehicle.registration.toLowerCase().includes(term) ||
                          vehicle.year.toString().includes(term) ||
                          vehicle.colour.toLowerCase().includes(term) ||
                          vehicle.mileage.toString().includes(term) ||
                          vehicle.status.toLowerCase().includes(term)
                      )
                    );
                  })
                  .sort((a, b) => {
                    switch (selectedFilter) {
                      case "newest":
                        return b.year - a.year;

                      case "oldest":
                        return a.year - b.year;
                      case "mostExpensive":
                        return b.mileage - a.mileage;
                      case "leastExpensive":
                        return a.mileage - b.mileage;
                      default:
                        return 0;
                    }
                  })
                  .map((vehicle) => (
                    <tr key={vehicle._id}>
                      <td>{vehicle.make}</td>
                      <td>{vehicle.model}</td>
                      <td>{vehicle.modelVariant}</td>
                      <td>{vehicle.registration}</td>
                      <td>{vehicle.year}</td>
                      <td>{vehicle.colour}</td>
                      <td>{vehicle.mileage}</td>
                      <td>{vehicle.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p style={{ marginLeft: "50px", marginTop: "20px" }}>
              No damaged vehicles found.
            </p>
          )}
        </div>
      </div>
    );
  }

  return content;
};

export default DamagedVehicles;
