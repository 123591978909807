import { createContext, useState } from "react";

const DateRangeContext = createContext();

const DateRangeProvider = ({ children }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [makeOption, setMakeOption] = useState("");
  const [modelOption, setModelOption] = useState("");
  const [modelVariantOption, setModelVariantOption] = useState("");
  const [postcode, setPostcode] = useState("");
  const [distanceRangeOption, setDistanceRangeOption] = useState("");
  const [priceRangeOption, setPriceRangeOption] = useState("");
  const [availableVehiclesCount, setAvailableVehiclesCount] = useState(0);

  return (
    <DateRangeContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        makeOption,
        setMakeOption,
        modelOption,
        setModelOption,
        modelVariantOption,
        setModelVariantOption,
        postcode,
        setPostcode,
        distanceRangeOption,
        setDistanceRangeOption,
        priceRangeOption,
        setPriceRangeOption,
        availableVehiclesCount,
        setAvailableVehiclesCount,
      }}
    >
      {children}
    </DateRangeContext.Provider>
  );
};

export { DateRangeProvider, DateRangeContext };
