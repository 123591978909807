import { useParams } from "react-router-dom";
import EditBookingForm from "./EditBookingForm";

import { useGetBookingsQuery } from "./bookingsApiSlice";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { useGetVehiclesQuery } from "../vehicles/vehiclesApiSlice";
import { HashLoader } from "react-spinners";

import useAuth from "../../hooks/useAuth";

const EditBooking = () => {
  const { id } = useParams();

  const { isDealership, isAdmin } = useAuth();

  const { booking } = useGetBookingsQuery("bookingsList", {
    selectFromResult: ({ data }) => ({
      booking: data?.entities[id],
    }),
  });

  const { users } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      users: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const { vehicles } = useGetVehiclesQuery("vehiclesList", {
    selectFromResult: ({ data }) => ({
      vehicles: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  // || !booking?.length ||

  if (!booking || !users || !vehicles) return <HashLoader color="#36d7b7" />;

  if (!isDealership && !isAdmin) {
    return <p className="errmsg">No access</p>;
  }

  const content = (
    <EditBookingForm booking={booking} users={users} vehicles={vehicles} />
  );

  return content;
};

export default EditBooking;
