import { useGetVehiclesQuery } from "../../vehicles/vehiclesApiSlice";
import useAuth from "../../../hooks/useAuth";
import { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { FaRandom, FaSearch } from "react-icons/fa";

const DashboardMyVehicles = () => {
  const { companyName } = useAuth();

  const navigate = useNavigate();

  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const {
    data: vehicles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetVehiclesQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    // Check if vehicles data is available and not in a loading/error state
    if (isSuccess && vehicles) {
      // Filter vehicles based on companyName
      const userVehicles = Object.values(vehicles.entities).filter(
        (vehicle) => vehicle.companyName === companyName
      );

      // Set filtered vehicles to state
      setFilteredVehicles(userVehicles);
    }
  }, [companyName, isSuccess, vehicles]);

  const handleButtonClick = () => {
    // Navigate to the desired route
    navigate("/dashboard/newVehicle");
  };

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="dashboardMyVehicles">
        <div className="dashboardMyvehiclesTitle">
          <h3>Vehicles</h3>
          <button onClick={handleButtonClick}>Add New Vehicle</button>
        </div>

        <div className="dashboardMyVehiclesTable">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price Per Day High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Per Day Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Make</th>
                <th>Model</th>
                <th>Model Variant</th>
                <th>Registration</th>
                <th>Year</th>
                <th>Colour</th>
                <th>Mileage</th>
                <th>Price Per Day</th>
                <th>Deposit</th>
              </tr>
            </thead>
            <tbody>
              {filteredVehicles

                .filter((vehicle) => {
                  const searchTerms = searchQuery.toLowerCase().split(" ");
                  return (
                    searchTerms.length === 0 ||
                    searchTerms.some(
                      (term) =>
                        vehicle.make.toLowerCase().includes(term) ||
                        vehicle.model.toLowerCase().includes(term) ||
                        vehicle.modelVariant.toLowerCase().includes(term) ||
                        vehicle.registration.toLowerCase().includes(term) ||
                        vehicle.year.toString().includes(term) ||
                        vehicle.colour.toLowerCase().includes(term) ||
                        vehicle.mileage.toString().includes(term) ||
                        vehicle.pricePerDay.toString().includes(term) ||
                        vehicle.deposit.toString().includes(term)
                    )
                  );
                })
                .sort((a, b) => {
                  switch (selectedFilter) {
                    case "newest":
                      return b.year - a.year;

                    case "oldest":
                      return a.year - b.year;
                    case "mostExpensive":
                      return b.pricePerDay - a.pricePerDay;
                    case "leastExpensive":
                      return a.pricePerDay - b.pricePerDay;
                    default:
                      return 0;
                  }
                })
                .map((vehicle) => (
                  <tr key={vehicle.id}>
                    <td className="dashboardMyVehiclesMakeLink">
                      <Link to={`/dashboard/myVehicles/${vehicle.id}`}>
                        {vehicle.make}
                      </Link>
                    </td>
                    <td>{vehicle.model}</td>
                    <td>{vehicle.modelVariant}</td>
                    <td>{vehicle.registration}</td>
                    <td>{vehicle.year}</td>
                    <td>{vehicle.colour}</td>
                    <td>{vehicle.mileage}</td>
                    <td>{vehicle.pricePerDay}</td>
                    <td>{vehicle.deposit}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardMyVehicles;
