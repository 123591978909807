import { useGetVehiclesQuery } from "../../vehicles/vehiclesApiSlice";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const MyVehiclePage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [filteredVehicle, setFilteredVehicle] = useState(null);

  const {
    data: vehicles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetVehiclesQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    // Check if vehicles data is available and not in a loading/error state
    if (isSuccess && vehicles) {
      // Extract the array of vehicles from the entities object
      const vehicleArray = Object.values(vehicles.entities);

      // Find the first vehicle that matches the id
      const filtered = vehicleArray.find((vehicle) => vehicle.id === id);

      // Set filtered vehicle to state
      setFilteredVehicle(filtered);
    }
  }, [id, isSuccess, vehicles]);

  // console.log(filteredVehicle);

  const handleScheduleClick = () => {
    navigate(`/dashboard/timeline/${filteredVehicle.id}`);
  };

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess && filteredVehicle) {
    const handleEditClick = () => {
      navigate(`/dashboard/${filteredVehicle.id}`);
    };

    content = (
      <div className="myVehiclePage">
        <div className="myVehiclePageTitle">
          <h3>
            {filteredVehicle.make} {filteredVehicle.model}{" "}
            {filteredVehicle.modelVariant}
          </h3>
          <button onClick={handleEditClick}>Edit</button>
        </div>
        <div className="myVehiclePageBody">
          <div className="myVehiclePageBodyVehicleDetails">
            <div className="myVehiclePageBodyVehicleDetailsIimg">
              {" "}
              <img
                src={filteredVehicle.images[0]}
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="myVehiclePagebodyVehicleDetailsInfo">
              <div className="myVehiclePagebodyVehicleDetailsInfoTitle">
                <h2>
                  {filteredVehicle.make} {filteredVehicle.model}{" "}
                  {filteredVehicle.modelVariant}
                </h2>
              </div>
              <div className="myVehiclePagebodyVehicleDetailsInfoTitleBody">
                <div className="myVehiclePagebodyVehicleDetailsInfoTitleBody1">
                  <p>{filteredVehicle.details}</p>
                  <p>{filteredVehicle.year}</p>
                  <p>{filteredVehicle.registration}</p>
                  <p>{filteredVehicle.mileage} Miles</p>
                  <p>{filteredVehicle.drivetrain}</p>
                </div>
                <div className="myVehiclePagebodyVehicleDetailsInfoTitleBody2">
                  <p>{filteredVehicle.bodyType}</p>
                  <p>{filteredVehicle.doors} Doors</p>
                  <p>{filteredVehicle.seats} Seats</p>
                  <p>{filteredVehicle.power} Hp</p>
                  <p>{filteredVehicle.torque} Nm Torque</p>
                </div>
              </div>
            </div>
          </div>
          <div className="myVehiclePageBodyVehicleNotesAndStatus">
            <div className="myVehiclePageBodyVehicleNotes">
              <h4>Notes</h4>
              <div className="myVehiclesPageBodyVehicleNotesDiv">
                <p>
                  {filteredVehicle.notes !== ""
                    ? filteredVehicle.notes
                    : "No Vehicle notes available right now"}
                </p>
              </div>
            </div>
            <div className="myVehiclePageBodyVehicleStatus">
              <h4>Vehicle Status</h4>
              <div className="myVehiclesPageBodyVehicleStatusDiv">
                <p>
                  {filteredVehicle.status} {""}
                  {filteredVehicle.status === "Good" && "🟢"}
                  {filteredVehicle.status === "Major Damage" && "🔴"}
                  {filteredVehicle.status === "Minor Damage" && "🟡"}
                  {filteredVehicle.status !== "Good" &&
                    filteredVehicle.status !== "Major Damage" &&
                    filteredVehicle.status !== "Minor Damage" &&
                    "Unknown Status"}
                </p>
              </div>
            </div>
          </div>
          <div className="myVehiclePageBodyVehicleSchedule">
            <h4>Schedule</h4>
            <p>
              Please{" "}
              <span
                onClick={handleScheduleClick}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }}
              >
                click here
              </span>{" "}
              to view vehicle schedule
            </p>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default MyVehiclePage;
