import React from "react";

const CookiesPolicy = () => {
  return (
    <div className="termsAndConditions">
      <h3>Cookies Policy</h3>

      <p>Last Updated - 15/10/24</p>

      <p>
        At IMAS Cars (accessible from{" "}
        <a href="https://imascars.co.uk">https://imascars.co.uk</a>), we use
        cookies and similar tracking technologies to enhance your experience on
        our website and analyze site traffic. This Cookies Policy explains what
        cookies are, how we use them, and your acceptance of cookies by using
        our website.
      </p>

      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are stored on your device (computer,
        tablet, or mobile) when you visit a website. They help the website
        remember information about your visit, such as your preferences and
        settings. Cookies can be classified into the following categories:
      </p>

      <ul>
        <li>
          <strong>Essential Cookies:</strong> These are necessary for the
          website to function and cannot be disabled. They enable basic features
          such as page navigation and access to secure areas of the site.
        </li>
        <li>
          <strong>Preference Cookies:</strong> These cookies allow the website
          to remember your preferences and choices (such as username, language,
          or region) to provide a more personalized experience.
        </li>
        <li>
          <strong>Analytics Cookies:</strong> These cookies collect information
          about how you use the website, such as which pages you visit and how
          long you spend on the site. This data helps us understand user
          behavior and improve our website's performance.
        </li>
        <li>
          <strong>Marketing Cookies:</strong> These cookies are used to deliver
          advertisements relevant to you and your interests. They help us
          measure the effectiveness of our marketing campaigns.
        </li>
      </ul>

      <h2>2. How We Use Cookies</h2>
      <p>At IMAS Cars, we use cookies for various purposes, including:</p>
      <ul>
        <li>To enable essential website functionality and security.</li>
        <li>To remember your preferences and settings.</li>
        <li>
          To analyze site traffic and user behavior to improve our website and
          services.
        </li>
        <li>To deliver personalized content and advertisements.</li>
      </ul>

      <h2>3. Acceptance of Cookies</h2>
      <p>
        By continuing to use our website, you automatically accept the use of
        cookies in accordance with this Cookies Policy. You acknowledge that you
        have been informed about the use of cookies and that you consent to the
        storage of cookies on your device.
      </p>

      <h2>4. Third-Party Cookies</h2>
      <p>
        In addition to our own cookies, we may also use third-party cookies from
        trusted service providers to help us analyze site traffic and deliver
        advertisements. These third-party cookies may collect information about
        your online activities across different websites and services.
      </p>

      <h2>5. Your Choices Regarding Cookies</h2>
      <p>
        Currently, we do not offer an option to disable cookies on our website.
        However, you can manage your cookie preferences through your browser
        settings. Most web browsers allow you to control cookies through their
        settings. You can usually find these settings in the "Options" or
        "Preferences" menu of your browser. For more information on how to
        manage cookies in popular browsers, visit:
      </p>

      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en">
            Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/en-us/help/4468242/windows-10-microsoft-edge-privacy-and-security-settings">
            Microsoft Edge
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/en-gb/HT201265">Safari</a>
        </li>
      </ul>

      <h2>6. Changes to This Cookies Policy</h2>
      <p>
        We may update this Cookies Policy from time to time to reflect changes
        in our practices or legal obligations. We will notify you of any
        significant updates by posting the updated policy on our website. Please
        check this page regularly for any updates.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions or concerns regarding this Cookies Policy,
        please contact us at:
      </p>

      <p>
        IMAS Cars
        <a href="https://imascars.co.uk">https://imascars.co.uk</a>
        Email: <a href="mailto:IMAS-Cars@outlook.com">IMAS-Cars@outlook.com</a>
      </p>
    </div>
  );
};

export default CookiesPolicy;
