import React from "react";

const Account = () => {
  const content = (
    <div className="account">
      <div className="customer">hello</div>
      <div className="dealer">hello</div>
    </div>
  );

  return content;
};

export default Account;
