import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="termsAndConditions">
      <h3>Terms And Conditions</h3>
      <p>Last Updated - 14/10/24</p>
      <h2>1. Introduction</h2>
      <p>
        These Terms and Conditions govern the use of IMAS Cars, owned and
        operated by IMAS Cars, a business in the United Kingdom. By signing up
        or using the Platform, you agree to comply with these Terms and
        Conditions. If you do not agree, please do not use the Platform.
      </p>
      <h2>2. Eligibility</h2>
      <p>
        You must be at least 18 years old and legally capable of entering into
        contracts under UK law to use the Platform. Dealerships must be
        registered businesses legally authorised to offer car rental services.
        Failure to comply will result in account termination.
      </p>
      <h2>3. Account Registration</h2>
      <p>
        Both Dealerships and Customers must create an account to use the
        Platform. You are responsible for maintaining the confidentiality of
        your account details and for any activity under your account.
      </p>
      <h2>4. Subscription Plans for Dealerships</h2>
      <p>
        We offer three subscription plans for Dealerships: Monthly Plan, 6-Month
        Plan or Yearly Plan
        <br />
        4.1 Free Trial All subscription plans include a 5-day free trial. During
        this period, you can cancel at any time without being charged. After the
        free trial, payment for the selected plan will be processed. <br />
        4.2 Cancellation Policy You may cancel your subscription at any time;
        however, you will still have access to the Platform until the end of the
        billing period for which you have already paid. No refunds will be
        provided for unused time after cancellation.
        <br /> 4.3 Renewals Your subscription will automatically renew unless
        canceled before the end of the billing period. We will notify you before
        the renewal of any changes to pricing.
      </p>
      <h2>5. Customer Bookings</h2>
      <p>
        Customers may use the Platform to book vehicles directly with
        Dealerships. IMAS Cars does not facilitate payments for these bookings.
        Once a booking is made, the Dealership is solely responsible for
        communicating with the Customer regarding payment and any additional
        terms related to the booking. IMAS Cars is not liable for any payments
        you make to dealerships.
      </p>
      <h2>6. Payment and Billing (Dealerships Only)</h2>
      <p>
        By signing up for a subscription, you authorise us to charge the payment
        method provided for the applicable subscription plan. All prices are
        listed in GBP (£) and are exclusive of any taxes unless stated
        otherwise.
      </p>
      <h2>7. No Liability for Transactions or Services</h2>
      <p>
        IMAS Cars acts solely as an intermediary platform to facilitate
        communication and bookings between Customers and Dealerships. We are not
        liable for: Any issues with the vehicles, including but not limited to
        their condition, availability, or legality. Any disputes between
        Customers and Dealerships, including payment issues, cancellations, or
        booking modifications. Any legal or regulatory compliance issues related
        to vehicle rentals or business operations conducted by Dealerships. All
        dealings and disputes between Customers and Dealerships are the sole
        responsibility of the parties involved.
      </p>
      <h2>8. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by England and Wales law, IMAS Cars and
        its officers, employees, and agents are not liable for any direct,
        indirect, incidental, special, or consequential damages, including but
        not limited to loss of profits, revenue, or data, arising from the use
        or inability to use the Platform.
      </p>
      <h2>9. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless IMAS Cars, its officers,
        employees, and agents from any claims, losses, liabilities, or expenses
        (including legal fees) arising from your use of the Platform, violation
        of these Terms and Conditions, or breach of any applicable England and
        Wales law.
      </p>
      <h2>10. Data Protection and Privacy</h2>
      <p>
        Your privacy is important to us. By using the Platform, you consent to
        the collection and use of your data in accordance with our Privacy
        Policy, which complies with the General Data Protection Regulation
        (GDPR) and UK Data Protection laws.
      </p>
      <h2>11. Intellectual Property</h2>
      <p>
        All content on the Platform, including but not limited to text, images,
        logos, and software, is the intellectual property of IMAS Cars or
        licensed to us. You are prohibited from using our content without
        express written permission.
      </p>
      <h2>12. Modifications to the Service</h2>
      <p>
        We reserve the right to modify, suspend, or discontinue the Platform, in
        whole or in part, at any time without notice. We will not be liable to
        you or any third party for any modifications or interruptions to the
        Platform.
      </p>
      <h2>13. Termination</h2>
      <p>
        We may terminate or suspend your access to the Platform at our
        discretion if you violate these Terms and Conditions or any applicable
        laws. Upon termination, all rights and licenses granted to you will
        cease immediately.
      </p>
      <h2>14. Governing Law and Jurisdiction</h2>
      <p>
        These Terms and Conditions are governed by the laws of England and
        Wales. Any disputes arising under these Terms will be subject to the
        exclusive jurisdiction of the courts of England and Wales.
      </p>
      <h2>15. Changes to These Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time. We will
        notify you of any significant changes by posting the revised terms on
        the Platform. Your continued use of the Platform following the changes
        constitutes acceptance of the revised terms.
      </p>
      <h2>16. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at IMAS-Cars@outlook.com.
      </p>
    </div>
  );
};

export default TermsAndConditions;
