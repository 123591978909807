import { useParams } from "react-router-dom";
import EditDealerForm from "./EditDealerForm";
import EditUserForm from "./EditUserForm";
import { useGetUsersQuery } from "./usersApiSlice";
import { HashLoader } from "react-spinners";

const EditUser = () => {
  const { id } = useParams();

  const { user } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[id],
    }),
  });

  if (!user) return <HashLoader color="#36d7b7" />;

  // console.log(user.role);

  const getEditForm = (user) => {
    if (user.roles.includes("Dealership")) {
      return <EditDealerForm user={user} />;
    } else {
      return <EditUserForm user={user} />;
    }
  };

  const content = user ? getEditForm(user) : <HashLoader color="#36d7b7" />;

  return content;
};

export default EditUser;
