import ManualBookingForm from "./ManualBookingForm";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { useGetVehiclesQuery } from "../vehicles/vehiclesApiSlice";
import { HashLoader } from "react-spinners";
import useAuth from "../../hooks/useAuth";

const ManualBooking = () => {
  const { email } = useAuth();

  const { users } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      users: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const filteredUsers = users?.filter((user) => user.email === email);

  const { vehicles } = useGetVehiclesQuery("vehiclesList", {
    selectFromResult: ({ data }) => ({
      vehicles: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const filteredVehicles = vehicles?.filter(
    (vehicle) => vehicle.ownerEmail === email
  );

  if (!users?.length) return <HashLoader color="#36d7b7" />;
  if (!vehicles?.length) return <HashLoader color="#36d7b7" />;

  const content = (
    <ManualBookingForm users={filteredUsers} vehicles={filteredVehicles} />
  );

  return content;
};

export default ManualBooking;
