import { useGetBookingsQuery } from "../bookings/bookingsApiSlice";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { HashLoader } from "react-spinners";
import { FaRandom, FaSearch } from "react-icons/fa";

import { Tooltip } from "react-tooltip";

import SalesLineChart from "./dashboard/SalesLineChart";
import SalesBarChart from "./dashboard/SalesBarChart";

import useAuth from "../../hooks/useAuth";

const Dashboard = () => {
  const { email } = useAuth(); // Extract email directly

  const navigate = useNavigate();

  // Fetch bookings using the hook directly
  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [filteredBookings, setFilteredBookings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking.vehicleOwner.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]); // Include email in the dependency array

  // console.log(filteredBookings);

  const calculateTotalRevenue = (filteredBookings) => {
    let totalRevenue = 0;
    for (const booking of filteredBookings) {
      const priceWithoutDeposit =
        booking.totalPrice - booking.vehicleDetails.deposit; // Subtract the deposit
      totalRevenue += priceWithoutDeposit; // Add the price after removing deposit
    }
    return totalRevenue;
  };

  const formatNumber = (number) => {
    // Add thousands separators for numbers above 10,000
    const formattedNumber = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Convert to millions with decimals for numbers above 1 million
    if (number >= 1000000) {
      const millions = number / 1000000;
      return `${millions.toFixed(2)}m`;
    } else {
      return formattedNumber;
    }
  };

  const getTodaysBookings = (bookings) => {
    const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    return bookings.filter((booking) => booking.dateOfBooking === today);
  };

  const getBookingsByStatus = (bookings, statuses) => {
    return bookings.filter((booking) =>
      statuses.includes(booking.bookingStatus)
    );
  };

  const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);

  // Set the number of pages you want to show at once
  const maxVisiblePages = 8;

  // Calculate the start and end of the range of buttons to show
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  // Adjust startPage in case we're near the end
  const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    const totalRevenue = calculateTotalRevenue(filteredBookings);
    const formattedTotalRevenue = formatNumber(totalRevenue);

    const todaysBookings = getTodaysBookings(filteredBookings);
    const todaysRevenue = calculateTotalRevenue(todaysBookings);
    const formattedTodaysRevenue = formatNumber(todaysRevenue);

    const todaysOrdersCount = todaysBookings.length;

    const targetStatuses = ["Vehicle Sent Out", "Awaiting Vehicle Return"];
    const vehicleSentOrReturningBookings = getBookingsByStatus(
      filteredBookings,
      targetStatuses
    );
    const vehicleSentOrReturningCount = vehicleSentOrReturningBookings.length;

    // console.log(vehicleSentOrReturningCount);

    const handleActiveVehiclesClick = () => {
      // Navigate to the desired route
      navigate("/dashboard/activeVehicles");
    };

    const handleOrdersTodayClick = () => {
      // Navigate to the desired route
      navigate("/dashboard/ordersToday");
    };

    content = (
      <div className="dashboardDealership">
        <div className="myOrdersSearchbarDealer">
          <div className="myOrdersSearchbarDivDealer">
            <div className="myOrdersSearchbarIcon">
              <FaSearch />
            </div>
            <div className="myOrdersSearchbarInputField">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>
        </div>
        <h2>Analytics</h2>
        <div className="dashboardDealershipAnalytics">
          <div className="dashbaordDealershipAnalyticsTotalRevenue">
            <div className="dashbaordDealershipAnalyticsTotalRevenueIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.06.40.png"
                alt="Dealership Dashboard Total Revenue Icon"
              />
            </div>
            <div className="dashbaordDealershipAnalyticsTotalRevenueDetails">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Value Excludes Deposit."
              >
                Total Revenue
              </p>
              <h3>£{formattedTotalRevenue}</h3>
              {/* Tooltip configuration and rendering */}
              <Tooltip
                id="infoTooltip"
                place="top"
                type="dark"
                effect="float"
              />
            </div>
          </div>
          <div className="dashbaordDealershipAnalyticsTodayRevenue">
            <div className="dashbaordDealershipTodayRevenueIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.53.png"
                alt="Today Revenue Icon"
              />
            </div>
            <div className="dashbaordDealershipTodayRevenueDetails">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Value Excludes Deposit."
              >
                Revenue Today
              </p>
              <h3>£{formattedTodaysRevenue}</h3>
            </div>
          </div>
          <div className="dashbaordDealershipAnalyticsOrdersToday">
            <div className="dashbaordDealershipAnalyticsOrdersTodayIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.14.png"
                alt="Orders Today Icon"
              />
            </div>
            <div className="dashbaordDealershipAnalyticsOrdersTodayDetails">
              <p>Orders Today</p>
              <h3
                onClick={handleOrdersTodayClick}
                className="dashboardActiveVehicles"
              >
                {todaysOrdersCount}
              </h3>
            </div>
          </div>
          <div className="dashbaordDealershipAnalyticsActiveVehicles">
            <div className="dashbaordDealershipAnalyticsActiveVehiclesIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/IMG_2704.JPG"
                alt="Active Vehicles"
              />
            </div>
            <div className="dashbaordDealershipAnalyticsActiveVehiclesDetails">
              <p>Active Vehicles</p>
              <h3
                onClick={handleActiveVehiclesClick}
                className="dashboardActiveVehicles"
              >
                {vehicleSentOrReturningCount}
              </h3>
            </div>
          </div>
        </div>
        <div className="dashboardDealershipAnalytics2">
          <div className="dashboardDealershipAnalytics2LineChart">
            <div className="dashboardDealershipAnalytics2LineChartTitle">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Value Excludes Deposit."
              >
                Report
              </p>
            </div>
            {/* <div className="dashboardDealershipAnalytics2LineChartResponsiveContainer">
              <ResponsiveContainer key="revenue-chart" width={700} height={300}>
                {chartConfig}
              </ResponsiveContainer>
            </div> */}

            <SalesLineChart
              isSuccess={isSuccess}
              isLoading={isLoading}
              isError={error}
              error={error}
              filteredBookings={filteredBookings}
            />
          </div>
          <div className="dashboardDealershipAnalytics2BarChart">
            <div className="dashboardDealershipAnalytics2BarChartTitle">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Value Excludes Deposit."
              >
                Sales Chart
              </p>
            </div>
            <SalesBarChart
              filteredBookings={filteredBookings}
              isSuccess={isSuccess}
              isError={isError}
              error={error}
              isLoading={isLoading}
            />
          </div>
        </div>
        <h2>Recent Orders</h2>
        <div className="dashboardDealershipRecentOrders">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>

          <table className="dashboardDealershipRecentOrdersTable">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Item</th>
                <th>Duration</th>
                <th>Customer</th>
                <th>Fulfilment Status</th>
                <th
                  data-tooltip-id="infoTooltip"
                  data-tooltip-content="These Value Include Deposit."
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings
                .filter((booking) => {
                  const searchTerms = searchQuery.toLowerCase().split(" ");
                  // Case-insensitive search across multiple fields
                  return (
                    searchTerms.length === 0 ||
                    searchTerms.some(
                      (term) =>
                        booking.id.toLowerCase().includes(term) ||
                        booking.dateOfBooking.toLowerCase().includes(term) ||
                        booking.vehicleDetails.make
                          .toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails.model
                          .toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails.modelVariant
                          .toLowerCase()
                          .includes(term) ||
                        booking.customer?.firstname
                          .toLowerCase()
                          .includes(term) ||
                        booking.customer?.lastname
                          .toLowerCase()
                          .includes(term) ||
                        booking.bookingStatus.toLowerCase().includes(term) ||
                        booking.totalPrice.toString().includes(term)
                    )
                  );
                })
                .sort((a, b) => {
                  switch (selectedFilter) {
                    case "newest":
                      return (
                        new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                      );
                    case "oldest":
                      return (
                        new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                      );
                    case "mostExpensive":
                      return b.totalPrice - a.totalPrice;
                    case "leastExpensive":
                      return a.totalPrice - b.totalPrice;
                    default:
                      return 0;
                  }
                })
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((booking) => (
                  <tr key={booking.id}>
                    <td
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      className="dashboardOrdersBookingId"
                    >
                      {booking.id}
                    </td>
                    <td>{booking.dateOfBooking}</td>
                    <td>
                      {booking.vehicleDetails.make}{" "}
                      {booking.vehicleDetails.model}{" "}
                      {booking.vehicleDetails.modelVariant}
                    </td>
                    <td>
                      {" "}
                      {/* Extract month and day from startDate and endDate */}
                      {new Date(booking.startDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}
                      -
                      {new Date(booking.endDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}{" "}
                      (
                      {/* Calculate duration in days, considering edge cases */}
                      {Math.max(
                        0,
                        Math.ceil(
                          (new Date(booking.endDate) -
                            new Date(booking.startDate)) /
                            (1000 * 3600 * 24)
                        )
                      )}
                      days)
                    </td>{" "}
                    {/* Replace with duration calculation */}
                    <td>
                      {booking.customer
                        ? `${booking.customer.firstname} ${booking.customer.lastname}`
                        : booking.mainCustomer.fullname}
                    </td>
                    <td>{booking.bookingStatus}</td>
                    <td>£{booking.totalPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="pagination">
            {/* Previous Button */}
            {currentPage > 1 && (
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="paginationArrows"
              >
                Prev
              </button>
            )}

            {/* {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={index + 1 === currentPage ? "active" : ""}
              >
                {index + 1}
              </button>
            ))} */}

            {Array.from(
              { length: endPage - adjustedStartPage + 1 },
              (_, index) => adjustedStartPage + index
            ).map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={pageNumber === currentPage ? "active" : ""}
              >
                {pageNumber}
              </button>
            ))}

            {/* Next Button */}
            {currentPage < totalPages && (
              <button onClick={() => setCurrentPage(currentPage + 1)}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default Dashboard;
