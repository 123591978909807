// import { Outlet } from "react-router-dom";
// import DashSideBar from "./DashSideBar";
// import Pricing from "../features/auth/payment/Pricing";
// import useAuth from "../hooks/useAuth";

// const DashLayout = () => {
//   const { allowAccess } = useAuth();

//   return (
//     <>
//       <DashSideBar />
//       <div className="dashboard-container">
//         {/* <Outlet /> */}
//         {allowAccess ? <Outlet /> : <Pricing />}
//       </div>
//     </>
//   );
// };
// export default DashLayout;

import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import DashSideBar from "./DashSideBar";
import Pricing from "../features/auth/payment/Pricing";
import useAuth from "../hooks/useAuth";

const DashLayout = () => {
  const { allowAccess } = useAuth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 750); // You can adjust this threshold for mobile screens
    };

    handleResize(); // Check size on initial load
    window.addEventListener("resize", handleResize); // Add event listener for resizing

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener on component unmount
    };
  }, []);

  return (
    <>
      {isMobile && (
        <div className="mobile-warning">
          If you are seeing this, please use a larger device eg Laptop or Ipad
          for a better experience
        </div>
      )}
      <div className="dashboard-layout">
        <DashSideBar />
        <div className="dashboard-container">
          {allowAccess ? <Outlet /> : <Pricing />}
        </div>
      </div>
    </>
  );
};

export default DashLayout;
