import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const usersAdapter = createEntityAdapter({});

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/users",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedUsers = responseData.map((user) => {
          user.id = user._id;
          return user;
        });
        return usersAdapter.setAll(initialState, loadedUsers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "User", id: "LIST" },
            ...result.ids.map((id) => ({ type: "User", id })),
          ];
        } else return [{ type: "User", id: "LIST" }];
      },
    }),
    getAllDealers: builder.query({
      query: () => ({
        url: "/users/dealers",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedUsers = responseData.map((user) => {
          user.id = user._id;
          return user;
        });
        return usersAdapter.setAll(initialState, loadedUsers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "User", id: "LIST" },
            ...result.ids.map((id) => ({ type: "User", id })),
          ];
        } else return [{ type: "User", id: "LIST" }];
      },
    }),

    // addNewUser: builder.mutation({
    //   query: (initialUserData) => ({
    //     url: "/users",
    //     method: "POST",
    //     body: {
    //       ...initialUserData,
    //     },
    //   }),

    //   invalidatesTags: [{ type: "User", id: "LIST" }],
    // }),
    addNewUser: builder.mutation({
      query: (initialUserData) => {
        const formData = new FormData();
        // Append form fields to FormData object
        formData.append("email", initialUserData.email);
        formData.append("password", initialUserData.password);
        formData.append("firstname", initialUserData.firstname);
        formData.append("lastname", initialUserData.lastname);
        formData.append("telephone", initialUserData.telephone);
        formData.append("roles", initialUserData.roles);
        formData.append("companyName", initialUserData.companyName);

        formData.append(
          "address[houseNumberAndStreetName]",
          initialUserData.address.houseNumberAndStreetName
        );
        formData.append("address[locality]", initialUserData.address.locality);
        formData.append(
          "address[cityOrTown]",
          initialUserData.address.cityOrTown
        );
        formData.append("address[postcode]", initialUserData.address.postcode);
        formData.append("address[country]", initialUserData.address.country);

        // File
        formData.append("profilePicture", initialUserData.profilePicture);

        return {
          url: "/users",
          method: "POST",
          body: formData, // Use FormData object as the body
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    updateUser: builder.mutation({
      // query: (initialUserData) => ({
      //   url: "/users",
      //   method: "PATCH",
      //   body: {
      //     ...initialUserData,
      //   },
      // }),
      query: (initialUserData) => {
        const formData = new FormData();
        // Append form fields to FormData object

        formData.append("id", initialUserData.id);
        formData.append("email", initialUserData.email);
        formData.append("password", initialUserData.password);
        formData.append("firstname", initialUserData.firstname);
        formData.append("lastname", initialUserData.lastname);
        formData.append("telephone", initialUserData.telephone);
        formData.append("roles", initialUserData.roles);

        if (initialUserData.companyName !== undefined) {
          formData.append("companyName", initialUserData.companyName);
        }

        formData.append(
          "address[houseNumberAndStreetName]",
          initialUserData.address.houseNumberAndStreetName
        );
        formData.append("address[locality]", initialUserData.address.locality);
        formData.append(
          "address[cityOrTown]",
          initialUserData.address.cityOrTown
        );
        formData.append("address[postcode]", initialUserData.address.postcode);
        formData.append("address[country]", initialUserData.address.country);

        // File
        formData.append("profilePicture", initialUserData.profilePicture);

        return {
          url: "/users",
          method: "PATCH",
          body: formData, // Use FormData object as the body
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/users`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetAllDealersQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApiSlice;

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select();

// creates memoized selector
const selectUsersData = createSelector(
  selectUsersResult,
  (usersResult) => usersResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
  // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(
  (state) => selectUsersData(state) ?? initialState
);
