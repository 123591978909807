import { loadStripe } from "@stripe/stripe-js";
import useAuth from "../../../hooks/useAuth";

const PLANS = [
  { id: "price_1QC67wHq9UJEMCt77FO1teRL", label: "£300/month", amount: 300 },
  {
    id: "price_1QC68bHq9UJEMCt7hT32FygC",
    label: "£1600/6 months",
    amount: 1600,
  },
  { id: "price_1QC696Hq9UJEMCt7kjlD1IZj", label: "£2800/year", amount: 2800 },
];

const stripePromise = loadStripe(
  "pk_live_51Q6tCEHq9UJEMCt7qGPmZO3M4bz6gRO4gDV7MNhm6KuUS6SuHUc9suAMEXHyyyWLRWvK8trK72qmPXriD1B7OHkz008K1YWgKQ"
);

// const BASE_URL = "https://imas-cars-backend.onrender.com/api/payments"; // Update the base URL to your backend's port
const BASE_URL = "https://api.imascars.co.uk/api/payments";

const Pricing = () => {
  const { customerId, email, stripeCustomerId } = useAuth();

  const handleCheckout = async (planId) => {
    const stripe = await stripePromise; // Get Stripe object

    // Create a checkout session for the selected plan
    const checkoutSession = await fetch(`${BASE_URL}/create-subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: planId, // Send selected plan id to backend
        customerId,
        email,
        stripeCustomerId,
        trialPeriodDays: 5,
      }),
    });

    const sessionData = await checkoutSession.json();

    // console.log(sessionData);

    // Redirect user to Stripe checkout page
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionData.sessionId, // Use sessionId returned from backend
    });

    if (error) {
      console.error("Error during Stripe redirection:", error.message);
    }
  };

  const content = (
    <div className="pricingDealer">
      <h3>Pricing (All plans come with a 5 day free trial)</h3>
      <div className="pricingCardContainer">
        <div className="pricingCard">
          <div className="pricingCardTopHalf">
            <h4>Monthly</h4>
            <p>£300 Per Month</p>
            <p>Full Access to all Features</p>
            <p>Cancel Anytime</p>
          </div>
          <div className="pricingCardBottomHalf">
            <button onClick={() => handleCheckout(PLANS[0].id)}>
              Continue
            </button>
          </div>
        </div>
        <div className="pricingCard">
          <div className="pricingCardTopHalf">
            <h4>6 Months</h4>
            <p>£1600 Per 6 Months</p>
            <p>Full Access to all Features</p>
            <p>11%+ saved compared to Monthly Plan</p>
            <p>Cancel Anytime</p>
          </div>
          <div className="pricingCardBottomHalf">
            <button onClick={() => handleCheckout(PLANS[1].id)}>
              Continue
            </button>
          </div>
        </div>
        <div className="pricingCard">
          <div className="pricingCardTopHalf">
            <h4>12 Months</h4>
            <p>£2800 Per 12 Months</p>
            <p>Full Access to all Features</p>
            <p>22%+ saved compared to Monthly Plan</p>
            <p>Cancel Anytime</p>
          </div>
          <div className="pricingCardBottomHalf">
            <button onClick={() => handleCheckout(PLANS[2].id)}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Pricing;
