import { FaAngleRight } from "react-icons/fa";
import { FaBars } from "react-icons/fa"; // Import the hamburger icon
import { DateRangeContext } from "../context/DateRangeContext";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { useCallback } from "react";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";

const HomeSideBar = () => {
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  const { email, isDealership } = useAuth();

  let linkPath = "/dealershipInfo"; // Default path

  if (email && email.length > 0) {
    // If email is set
    if (isDealership) {
      linkPath = "/dashboard"; // Dealership dashboard
    }
  }

  // State to manage sidebar visibility
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    // startTime,
    // setStartTime,
    // endTime,
    // setEndTime,
    makeOption,
    setMakeOption,
    modelOption,
    setModelOption,
    modelVariantOption,
    setModelVariantOption,
    postcode,
    setPostcode,
    distanceRangeOption,
    setDistanceRangeOption,
    priceRangeOption,
    setPriceRangeOption,
    availableVehiclesCount,
  } = useContext(DateRangeContext);

  const onStartDateChanged = (e) => {
    const selectedDate = e.target.value;
    // To prevent selecting a date before the current date
    const currentDate = new Date().toISOString().split("T")[0];
    if (selectedDate < currentDate) {
      // Don't update the state if selected date is before the current date
      return;
    }
    setStartDate(selectedDate);
  };
  const onEndDateChanged = (e) => {
    const selectedDate = e.target.value;
    // To prevent selecting a date before the start date
    if (selectedDate < startDate) {
      // Don't update the state if selected date is before the start date
      return;
    }
    setEndDate(selectedDate);
  };

  // const onStartTimeChanged = (e) => setStartTime(e.target.value);
  // const onEndTimeChanged = (e) => setEndTime(e.target.value);

  const onMakeChanged = (e) => setMakeOption(e.target.value);
  const onModelChanged = (e) => setModelOption(e.target.value);
  const onModelVariantChanged = (e) => setModelVariantOption(e.target.value);

  const onPostcodeChanged = (e) => setPostcode(e.target.value);
  const ondistanceRangeOptionChanged = (e) =>
    setDistanceRangeOption(e.target.value);
  const onPricePerDayChanged = (e) => setPriceRangeOption(e.target.value);

  const validStartDateClass = !startDate ? "form__input--incomplete" : "";
  const validEndDateClass = !endDate ? "form__input--incomplete" : "";

  // const validStartTimeClass = !startTime ? "form__input--incomplete" : "";
  // const validEndTimeClass = !endTime ? "form__input--incomplete" : "";

  const validMakeClass = !makeOption ? "form__input--incomplete" : "";
  const validModelClass = !modelOption ? "form__input--incomplete" : "";
  const validModelVariantClass = !modelVariantOption
    ? "form__input--incomplete"
    : "";
  const validPostcodeClass = !postcode ? "form__input--incomplete" : "";
  const validDistanceRangeOptionsClass = !distanceRangeOption
    ? "form__input--incomplete"
    : "";
  const validPriceRangeOptionsClass = !priceRangeOption
    ? "form__input--incomplete"
    : "";

  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);

  // Function to calculate the number of selected filters
  const calculateSelectedFiltersCount = useCallback(() => {
    let count = 0;
    if (makeOption) count++;
    if (modelOption) count++;
    if (modelVariantOption) count++;
    if (postcode && distanceRangeOption) count++;
    if (priceRangeOption) count++;
    if (startDate && endDate) count++;
    // if (startTime && endTime) count++;
    setSelectedFiltersCount(count);
  }, [
    makeOption,
    modelOption,
    modelVariantOption,
    postcode,
    distanceRangeOption,
    priceRangeOption,
    startDate,
    endDate,
    // startTime,
    // endTime,
    setSelectedFiltersCount,
  ]);

  // const timeOptions = [
  //   "12:00am",
  //   "12:30am",
  //   "1:00am",
  //   "1:30am",
  //   "2:00am",
  //   "2:30am",
  //   "3:00am",
  //   "3:30am",
  //   "4:00am",
  //   "4:30am",
  //   "5:00am",
  //   "5:30am",
  //   "6:00am",
  //   "6:30am",
  //   "7:00am",
  //   "7:30am",
  //   "8:00am",
  //   "8:30am",
  //   "9:00am",
  //   "9:30am",
  //   "10:00am",
  //   "10:30am",
  //   "11:00am",
  //   "11:30am",
  //   "12:00pm",
  //   "12:30pm",
  //   "1:00pm",
  //   "1:30pm",
  //   "2:00pm",
  //   "2:30pm",
  //   "3:00pm",
  //   "3:30pm",
  //   "4:00pm",
  //   "4:30pm",
  //   "5:00pm",
  //   "5:30pm",
  //   "6:00pm",
  //   "6:30pm",
  //   "7:00pm",
  //   "7:30pm",
  //   "8:00pm",
  //   "8:30pm",
  //   "9:00pm",
  //   "9:30pm",
  //   "10:00pm",
  //   "10:30pm",
  //   "11:00pm",
  //   "11:30pm",
  // ];

  // Use effect to update the selected filters count whenever any filter option changes
  useEffect(() => {
    calculateSelectedFiltersCount();
  }, [calculateSelectedFiltersCount]);

  const content = (
    // <div className="homeSideBar">
    <div className={`homeSideBar ${isSidebarVisible ? "visible" : ""}`}>
      <div className="homeSideBarInfo">
        <h1>{availableVehiclesCount} Cars for Hire</h1>
        <h3>{selectedFiltersCount} Filters selected </h3>
      </div>
      <div className="homeSideBarDistance">
        <div className="homeSideBarDistanceInput">
          <label className="form__label--visually-hidden" htmlFor="make">
            Postcode
          </label>
          <input
            className={`form__input__filter--distance ${validPostcodeClass}`}
            id="postcode"
            name="postcode"
            type="text"
            autoComplete="off"
            value={postcode}
            onChange={onPostcodeChanged}
            onFocus={(e) => {
              if (e.target.value === "Postcode") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Postcode";
              }
            }}
            placeholder="Postcode"
          />
        </div>
        <div className="homeSideBarDistanceInput">
          <label className="form__label--visually-hidden" htmlFor="make">
            Distance
          </label>
          <select
            className={`form__input__filter--distanceOptions ${validDistanceRangeOptionsClass}`}
            id="distance"
            name="distance"
            value={distanceRangeOption}
            onChange={ondistanceRangeOptionChanged}
          >
            <option value="">Distance</option>
            <option value="upTo5Miles">Up to 5 Miles</option>
            <option value="upTo10Miles">Up to 10 Miles</option>
            <option value="upTo15Miles">Up to 15 Miles</option>
            <option value="upTo20Miles">Up to 20 Miles</option>
            <option value="upTo25Miles">Up to 25 Miles</option>
            <option value="upTo30Miles">Up to 30 Miles</option>
            <option value="upTo40Miles">Up to 40 Miles</option>
            <option value="upTo50Miles">Up to 50 Miles</option>
            <option value="60MilesPlus">60 Miles Plus</option>
          </select>
        </div>
      </div>
      <div className="homeSideBarVehicle">
        <div className="homeSideBarVehicleInput">
          {/* <p>Make</p>
          <div className="homeSideBarVehicleInputData">
            <span>Lamborghini</span>
            <FaAngleRight className="homeSideBarVehicleInputIcon" />
          </div> */}
          <label className="form__label--visually-hidden" htmlFor="make">
            Vehicle Make
          </label>
          <input
            className={`form__input__filter--make ${validMakeClass}`}
            id="make"
            name="make"
            type="text"
            autoComplete="off"
            value={makeOption}
            onChange={onMakeChanged}
            onFocus={(e) => {
              if (e.target.value === "Make") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Make";
              }
            }}
            placeholder="Make"
          />
          <FaAngleRight className="homeSideBarVehicleInputIcon" />
        </div>
        <div className="homeSideBarVehicleInput">
          {/* <p>Model </p>
          <div className="homeSideBarVehicleInputData">
            <span>Huracan</span>
            <FaAngleRight className="homeSideBarVehicleInputIcon" />
          </div> */}
          <label className="form__label--visually-hidden" htmlFor="model">
            Vehicle Model
          </label>
          <input
            className={`form__input__filter--make ${validModelClass}`}
            id="model"
            name="model"
            type="text"
            autoComplete="off"
            value={modelOption}
            onChange={onModelChanged}
            onFocus={(e) => {
              if (e.target.value === "Model") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Model";
              }
            }}
            placeholder="Model"
          />
          <FaAngleRight className="homeSideBarVehicleInputIcon" />
        </div>
        <div className="homeSideBarVehicleInput">
          {/* <p>Model Variant</p>
          <div className="homeSideBarVehicleInputData">
            <span>Evo</span>
            <FaAngleRight className="homeSideBarVehicleInputIcon" />
          </div> */}
          <label
            className="form__label--visually-hidden"
            htmlFor="modelVariant"
          >
            Vehicle Model Variant
          </label>
          <input
            className={`form__input__filter--make ${validModelVariantClass}`}
            id="modelVariant"
            name="modelVariant"
            type="text"
            autoComplete="off"
            value={modelVariantOption}
            onChange={onModelVariantChanged}
            onFocus={(e) => {
              if (e.target.value === "Model Variant") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Model Variant";
              }
            }}
            placeholder="Model Variant"
          />
          <FaAngleRight className="homeSideBarVehicleInputIcon" />
        </div>
      </div>
      <div className="homeSideBarPrice">
        <div className="homeSideBarPriceInput">
          <p>Price Per</p>
          <div className="homeSideBarPriceInputData">
            <span>Day</span>
            <FaAngleRight className="homeSideBarPriceInputIcon" />
          </div>
        </div>
        <div className="homeSideBarPriceInput">
          <label className="form__label--visually-hidden" htmlFor="make">
            Price
          </label>
          <select
            className={`form__input__filter--price ${validPriceRangeOptionsClass}`}
            id="price"
            name="price"
            value={priceRangeOption}
            onChange={onPricePerDayChanged}
          >
            <option value="">Price</option>
            <option value="upTo500">Up to £500</option>
            <option value="upTo1000">Up to £1,000</option>
            <option value="upTo1500">Up to £1,500</option>
            <option value="upTo2000">Up to £2,000</option>
            <option value="upTo2500">Up to £2,500</option>
            <option value="upTo3000">Up to £3,000</option>
            <option value="upTo3500">Up to £3,500</option>
            <option value="upTo4000">Up to £4,000</option>
            <option value="upTo4500">Up to £4,500</option>
            <option value="upTo5000">Up to £5,000</option>
            <option value="5000Plus">£5,000 Plus</option>
          </select>

          <FaAngleRight className="homeSideBarPriceInputIcon" />
        </div>
      </div>
      <div className="homeSideBarDates">
        <div className="homeSideBarDatesInput">
          <label className="form__label__filter" htmlFor="startDate">
            Start Date
          </label>

          <input
            className={`form__input__filter ${validStartDateClass}`}
            id="startDate"
            name="startDate"
            type="date" // Set the type to "date" for date input
            value={startDate}
            onChange={onStartDateChanged}
            required // Add the "required" attribute for validation
          />
        </div>
        <div className="homeSideBarDatesInput">
          <label className="form__label__filter" htmlFor="endDate">
            End Date
          </label>

          <input
            className={`form__input__filter ${validEndDateClass}`}
            id="endDate"
            name="endDate"
            type="date" // Set the type to "date" for date input
            value={endDate}
            onChange={onEndDateChanged}
            required // Add the "required" attribute for validation
          />
        </div>
      </div>
      {/* <div className="homeSideBarTimes">
        <div className="homeSideBarTimesInput">
          <label className="form__label__filter" htmlFor="startTime">
            Start Time
          </label>

          <select
            className={`form__input__filter--startTime ${validStartTimeClass}`}
            id="startTime"
            name="startTime"
            value={startTime}
            onChange={onStartTimeChanged}
          >
            <option value=""></option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>

          <FaAngleRight className="homeSideBarPriceInputIcon" />
        </div>
        <div className="homeSideBarTimesInput">
          <label className="form__label__filter" htmlFor="endTime">
            End Time
          </label>

          <select
            className={`form__input__filter--endTime ${validEndTimeClass}`}
            id="endTime"
            name="endTime"
            value={endTime}
            onChange={onEndTimeChanged}
          >
            <option value=""></option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>

          <FaAngleRight className="homeSideBarPriceInputIcon" />
        </div> 
      </div>*/}
    </div>
  );

  // return content;
  return (
    <div className="iphoneMainPage">
      <div className="homeSideBarHam" onClick={toggleSidebar}>
        <FaBars className="hamburger-icon" />
        <span className="hamburger-icon-text">Filter</span>
      </div>
      <div className="dealershipButtonHomepage">
        {" "}
        <Link to={linkPath}>
          <button>Dealerships Click Here</button>
        </Link>
      </div>

      {content}
    </div>
  );
};

export default HomeSideBar;
