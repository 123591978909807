import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "../users/usersApiSlice";
import useAuth from "../../hooks/useAuth";
import { HashLoader } from "react-spinners";

async function getUserLocation(latitude, longitude) {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();

    const location = {
      city: data.address.city,
      region: data.address.state,
      country: data.address.country,
    };

    return location;
  } catch (error) {
    console.error("Error fetching location:", error);
    return null; // Or return a default location if desired
  }
}

function DashboardCustomerProfile() {
  const { email } = useAuth();

  const navigate = useNavigate();

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [filteredUser, setFilteredUser] = useState(null); // Use a state for filteredUser
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      const filteredUserId = Object.keys(users.entities).find(
        (userId) => users.entities[userId].email === email
      );
      const user = filteredUserId ? users.entities[filteredUserId] : null;
      setFilteredUser(user); // Update filteredUser state

      if (filteredUser && filteredUser.location) {
        const { latitude, longitude } = filteredUser.location;
        getUserLocation(latitude, longitude)
          .then((location) => setUserLocation(location))
          .catch((error) => console.error("Error fetching location:", error));
      }
    }
  }, [isSuccess, users, email, filteredUser]); // Dependency array updated

  let content;

  if (isLoading) {
    content = <HashLoader color="#36d7b7" />;
  } else if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  } else if (isSuccess) {
    const filteredUserId = Object.keys(users.entities).find(
      (userId) => users.entities[userId].email === email
    );

    // If a matching user is found, access it using its ID
    const filteredUser = filteredUserId ? users.entities[filteredUserId] : null;

    content = (
      <div className="dashboardCustomerProfilePage">
        <h1 className="dashboardCustomerProfilePageTitle">Profile</h1>
        <div className="dashboardCustomerProfilePageDetails">
          <p className="dashboardCustomerProfilePageMyDetailsPTag">
            My Details
          </p>
          <div class="dashboardCustomerProfilePageDetailsGrid">
            <div class="grid-item">{filteredUser.firstname}</div>
            <div class="grid-item">{filteredUser.lastname}</div>
            <div class="grid-item">{filteredUser.email}</div>
            <div class="grid-item">{filteredUser.telephone}</div>
            <div class="grid-item">{filteredUser.roles}</div>
            <div class="grid-item">
              {userLocation ? (
                <>
                  {userLocation.city}, {userLocation.region},{" "}
                  {userLocation.country}
                </>
              ) : (
                <p>Location not available</p>
              )}
            </div>
          </div>
          <div className="dashboardCustomerProfilePageDetialsProfilePicture">
            <img
              src={filteredUser.profilePicture}
              alt={
                filteredUser.firstname +
                " " +
                filteredUser.lastname +
                "'s profile picture"
              }
              className="profile-picture-circle"
            />
          </div>
          <button
            onClick={() => {
              navigate(`/users/${filteredUser.id}`);
            }}
            className="dashboardCustomerProfileEditButton"
            style={{ cursor: "pointer" }}
          >
            Edit
          </button>
        </div>
      </div>
    );
  }

  return content;
}

export default DashboardCustomerProfile;
