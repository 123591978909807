import { useState, useEffect, useCallback } from "react";
import { useAddNewUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../config/roles";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useRef } from "react";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PWD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/; // Password with minimum length and at least one uppercase letter, one lowercase letter, one digit, and one special character

const ASPECT_RATIO = 5 / 3;
const MIN_DIMENSION = 150;

const NewDealershipForm = () => {
  const [addNewUser, { isLoading, isSuccess, isError, error }] =
    useAddNewUserMutation();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [roles, setRoles] = useState(["Dealership"]);
  const [profilePicture, setProfilePicture] = useState("");

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();
  const [errorPfp, setErrorPfp] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [originalFileName, setOriginalFileName] = useState("");

  const [companyName, setCompanyName] = useState("");
  //   const [location, setLocation] = useState("");
  // const [location, setLocation] = useState({
  //   latitude: "",
  //   longitude: "",
  // });

  const [address, setAddress] = useState({
    houseNumberAndStreetName: "",
    locality: "",
    cityOrTown: "",
    postcode: "",
    country: "United Kingdom", // Assuming default is UK
  });

  const [allowAccess, setAllowAccess] = useState(false);

  const setCanvasPreview = (image, canvas, crop) => {
    if (!crop || !canvas || !image) {
      return;
    }

    const ctx = canvas.getContext("2d");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    canvas.style.width = `${crop.width}px`;
    canvas.style.height = `${crop.height}px`;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  };

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    if (isSuccess) {
      setEmail("");
      setPassword("");
      setRoles([]);
      setFirstname("");
      setLastname("");
      setTelephone("");
      setProfilePicture("");
      setAllowAccess(false);
      setCompanyName("");
      // setLocation({ latitude: "", longitude: "" });
      setAddress({
        houseNumberAndStreetName: "",
        locality: "",
        cityOrTown: "",
        postcode: "",
        country: "",
      });
      navigate("/login=dealership");
    }
  }, [isSuccess, navigate]);

  const onEmailChanged = (e) => setEmail(e.target.value);
  // const onPasswordChanged = (e) => setPassword(e.target.value);

  const onPasswordChanged = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const onConfirmPasswordChanged = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const onFirstnameChanged = (e) => setFirstname(e.target.value);
  const onLastnameChanged = (e) => setLastname(e.target.value);
  const onTelephoneChanged = (e) => setTelephone(e.target.value);
  // const onProfilePictureChanged = (e) => setProfilePicture(e.target.value);

  // const onProfilePictureChanged = (event) => {
  //   const selectedFile = event.target.files[0];
  //   console.log(selectedFile);
  //   setProfilePicture(selectedFile);
  // };

  // Function to handle image selection
  // const onProfilePictureChanged = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSrc(reader.result); // Load the image source for cropping
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setOriginalFileName(file.name); // Store the original filename

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (errorPfp) setErrorPfp("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setErrorPfp("Image must be at least 150 x 150 pixels.");
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  // const onImageLoad = (e) => {
  //   const { width, height } = e.currentTarget;
  //   const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

  //   const crop = makeAspectCrop(
  //     {
  //       unit: "%",
  //       width: cropWidthInPercent,
  //     },
  //     ASPECT_RATIO,
  //     width,
  //     height
  //   );
  //   const centeredCrop = centerCrop(crop, width, height);
  //   setCrop(centeredCrop);

  //   // Automatically crop and generate the Blob when the image is loaded
  //   if (imgRef.current && previewCanvasRef.current && centeredCrop) {
  //     setCanvasPreview(
  //       imgRef.current, // HTMLImageElement
  //       previewCanvasRef.current, // HTMLCanvasElement
  //       convertToPixelCrop(
  //         centeredCrop,
  //         imgRef.current.width,
  //         imgRef.current.height
  //       )
  //     );

  //     // Ensure the canvas exists before calling toBlob()
  //     previewCanvasRef.current.toBlob((blob) => {
  //       if (blob) {
  //         const croppedImageFile = new File([blob], "cropped_image.jpg", {
  //           type: "image/jpeg",
  //         });
  //         setProfilePicture(croppedImageFile); // Automatically set the cropped image file
  //       }
  //     }, "image/jpeg");
  //   }
  // };

  // const handleCropImage = () => {
  //   if (!imgRef.current || !crop) return;

  //   // Generate cropped image on the canvas
  //   setCanvasPreview(
  //     imgRef.current, // HTMLImageElement
  //     previewCanvasRef.current, // HTMLCanvasElement
  //     convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
  //   );

  //   // Convert the canvas to a Blob and send it as a file
  //   previewCanvasRef.current.toBlob((blob) => {
  //     if (blob) {
  //       const croppedImageFile = new File([blob], "cropped_image.jpg", {
  //         type: "image/jpeg",
  //       });
  //       // Now you can send `croppedImageFile` to the backend as the profile picture
  //       setProfilePicture(croppedImageFile); // Update profilePicture state with the file
  //     }
  //   }, "image/jpeg");
  // };

  const handleCropImage = useCallback(() => {
    if (!imgRef.current || !crop) return;

    // Generate cropped image on the canvas
    setCanvasPreview(
      imgRef.current, // HTMLImageElement
      previewCanvasRef.current, // HTMLCanvasElement
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );

    // Convert the canvas to a Blob and send it as a file
    previewCanvasRef.current.toBlob((blob) => {
      if (blob) {
        // const croppedImageFile = new File([blob], "cropped_image.jpg", {
        //   type: "image/jpeg",
        // });
        // const croppedImageFile = new File(
        //   [blob],
        //   `${originalFileName}_cropped.jpg`,
        //   {
        //     type: "image/jpeg",
        //   }
        // );
        const sanitizedFileName = originalFileName.replace(/\s+/g, "_");
        const croppedImageFile = new File(
          [blob],
          `${sanitizedFileName}_cropped.jpg`,
          {
            type: "image/jpeg",
          }
        );
        setProfilePicture(croppedImageFile); // Update profilePicture state with the file
      }
    }, "image/jpeg");
  }, [crop, originalFileName]); // Add crop as a dependency

  // useEffect to handle cropping when a new file is selected
  useEffect(() => {
    if (imgSrc) {
      handleCropImage(); // Automatically crop when the image source is set
    }
  }, [imgSrc, handleCropImage]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const onCompanyNameChanged = (e) => setCompanyName(e.target.value);
  // const onLocationChanged = (e) => {
  //   const { name, value } = e.target;
  //   setLocation((prevLocation) => ({
  //     ...prevLocation,
  //     [name]: value,
  //   }));
  // };
  const onAddressChanged = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const canSave =
    roles.length > 0 &&
    password &&
    password === confirmPassword &&
    validEmail &&
    validPassword &&
    firstname.trim().length > 0 &&
    lastname.trim().length > 0 &&
    (telephone.trim().length === 0 || /^[0-9]+$/.test(telephone)) &&
    // location.latitude !== undefined &&
    // location.latitude !== null &&
    // location.longitude !== undefined &&
    // location.longitude !== null &&
    address.houseNumberAndStreetName.trim().length > 0 &&
    address.locality.trim().length > 0 &&
    address.cityOrTown.trim().length > 0 &&
    address.postcode.trim().length > 0 &&
    !isLoading;

  const onSaveUserClicked = async (e) => {
    e.preventDefault();
    if (canSave && password === confirmPassword) {
      await addNewUser({
        email,
        password,
        roles,
        firstname,
        lastname,
        telephone,
        profilePicture,
        companyName,
        // location,
        address,
        allowAccess,
      });
    }
  };

  // console.log(profilePicture);

  // const onSaveUserClicked = async (e) => {
  //   e.preventDefault();
  //   if (profilePicture) {
  //     // Call the getCroppedImg function before saving
  //     await getCroppedImg();

  //     // Once you have the cropped image, send it to the backend as a file (profilePicture)
  //     await addNewUser({
  //       email,
  //       password,
  //       roles,
  //       firstname,
  //       lastname,
  //       telephone,
  //       profilePicture, // Send the cropped image blob to the backend
  //       companyName,
  //       address,
  //     });
  //   }
  // };

  //   const options = Object.values(ROLES).map((role) => {
  //     return (
  //       <option key={role} value={role}>
  //         {" "}
  //         {role}
  //       </option>
  //     );
  //   });

  const errClass = isError ? "errmsg" : "offscreen";
  const validEmailClass = validEmail ? "" : "form__input--incomplete";
  const validPwdClass = validPassword ? "" : "form__input--incomplete";
  //   const validRolesClass = roles.length > 0 ? "" : "form__input--incomplete";
  const validFirstNameClass =
    firstname.trim().length > 0 ? "" : "form__input--incomplete";
  const validLastNameClass =
    lastname.trim().length > 0 ? "" : "form__input--incomplete";
  const validTelephoneClass =
    telephone.trim().length === 0 || /^[0-9]+$/.test(telephone)
      ? ""
      : "form__input--incomplete";
  const validCompanyNameClass =
    !Object.values(ROLES).includes("Dealership") ||
    companyName.trim().length > 0
      ? ""
      : "form__input--incomplete";
  // const validProfilePictureClass =
  //   profilePicture.trim().length > 0 ? "" : "form__input--incomplete";

  //   const validLocationClass =
  //     location.trim().length > 0 ? "" : "form__input--incomplete";

  // const validLatitudeClass =
  //   location.latitude.trim().length > 0 ? "" : "form__input--complete";

  // const validLongitudeClass =
  //   location.longitude.trim().length > 0 ? "" : "form__input--complete";

  const validhouseNumberAndStreetNameClass = address.houseNumberAndStreetName
    ? ""
    : "form__input--incomplete";
  const validlocalityClass = address.locality ? "" : "form__input--incomplete";
  const validcityOrTownClass = address.cityOrTown
    ? ""
    : "form__input--incomplete";
  const validpostcodeClass = address.postcode ? "" : "form__input--incomplete";

  const content = (
    <>
      <p className={errClass}>{error?.data?.message}</p>

      <form className="newDealershipForm" onSubmit={onSaveUserClicked}>
        <h2 className="signUp">List With Us</h2>
        <p className={errClass}>{error?.data?.message}</p>

        <p>By Signing Up You </p>
        <p style={{ marginBottom: "10px" }}>
          Agree To Our <a href="/termsAndConditions">Terms And Conditions</a>
        </p>

        <div className="inputFields">
          <label className="form__label--visually-hidden" htmlFor="email">
            Email: <span className="nowrap">[Must include @]</span>
          </label>
          <input
            className={`form__input ${validEmailClass}`}
            id="email"
            name="email"
            type="text"
            autoComplete="off"
            value={email}
            onChange={onEmailChanged}
            onFocus={(e) => {
              if (e.target.value === "Email") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Email";
              }
            }}
            placeholder="Email"
          />

          <label className="form__label--visually-hidden" htmlFor="address">
            Address: <span className="nowrap">[houseNumberAndStreetName]</span>
          </label>
          <input
            className={`form__input ${validhouseNumberAndStreetNameClass}`}
            id="houseNumberAndStreetName"
            name="houseNumberAndStreetName"
            type="text"
            autoComplete="off"
            value={address.houseNumberAndStreetName}
            onChange={onAddressChanged}
            onFocus={(e) => {
              if (e.target.value === "House Number and Street Name") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "House Number and Street Name";
              }
            }}
            placeholder="House Number and Street Name"
          />

          <label className="form__label--visually-hidden" htmlFor="address">
            Address: <span className="nowrap">[locality]</span>
          </label>
          <input
            className={`form__input ${validlocalityClass}`}
            id="locality"
            name="locality"
            type="text"
            autoComplete="off"
            value={address.locality}
            onChange={onAddressChanged}
            onFocus={(e) => {
              if (e.target.value === "Line 2") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Line 2";
              }
            }}
            placeholder="Line 2"
          />

          <label className="form__label--visually-hidden" htmlFor="address">
            Address: <span className="nowrap">[cityOrTown]</span>
          </label>
          <input
            className={`form__input ${validcityOrTownClass}`}
            id="cityOrTown"
            name="cityOrTown"
            type="text"
            autoComplete="off"
            value={address.cityOrTown}
            onChange={onAddressChanged}
            onFocus={(e) => {
              if (e.target.value === "City or Town") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "City or Town";
              }
            }}
            placeholder="City or Town"
          />

          <label className="form__label--visually-hidden" htmlFor="address">
            Address: <span className="nowrap">[postcode]</span>
          </label>
          <input
            className={`form__input ${validpostcodeClass}`}
            id="postcode"
            name="postcode"
            type="text"
            autoComplete="off"
            value={address.postcode}
            onChange={onAddressChanged}
            onFocus={(e) => {
              if (e.target.value === "Postcode") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Postcode";
              }
            }}
            placeholder="Postcode"
          />

          <label className="form__label--visually-hidden" htmlFor="telephone">
            Telephone:{" "}
            <span className="nowrap">[Must be Valid Telephone number]</span>
          </label>
          <input
            className={`form__input ${validTelephoneClass}`}
            id="telephone"
            name="telephone"
            type="tel"
            value={telephone}
            onChange={onTelephoneChanged}
            placeholder="Telephone"
            onFocus={(e) => {
              if (e.target.value === "Telephone") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Telephone";
              }
            }}
          />

          <label className="form__label--visually-hidden" htmlFor="firstname">
            Firstname: <span className="nowrap">[Must be your firstname]</span>
          </label>
          <input
            className={`form__input ${validFirstNameClass}`}
            id="firstname"
            name="firstname"
            type="text"
            value={firstname}
            onChange={onFirstnameChanged}
            onFocus={(e) => {
              if (e.target.value === "Firstname") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Firstname";
              }
            }}
            placeholder="Firstname"
          />

          <label className="form__label--visually-hidden" htmlFor="lastname">
            Lastname: <span className="nowrap">[Must be your lastname]</span>
          </label>
          <input
            className={`form__input ${validLastNameClass}`}
            id="lastname"
            name="lastname"
            type="text"
            value={lastname}
            onChange={onLastnameChanged}
            onFocus={(e) => {
              if (e.target.value === "Lastname") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Lastname";
              }
            }}
            placeholder="Lastname"
          />

          {password && !validPassword && (
            <div style={{ color: "red" }} className="passwordInstructions">
              <p>Must contain a number, one lowercase letter,</p>
              <p> one uppercase letter, one symbol</p>
              <p> and must be more than 8 characters long</p>
            </div>
          )}

          <label className="form__label--visually-hidden" htmlFor="password">
            Password:{" "}
            <span className="nowrap">
              [Must contain a number, one lowercase letter, one uppercase
              letter, one symbol and must be more than 8 characters long]
            </span>
          </label>
          <input
            className={`form__input ${validPwdClass}`}
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={onPasswordChanged}
            onFocus={(e) => {
              if (e.target.value === "Password") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Password";
              }
            }}
            placeholder="Password    A-Z,a-z,0-9,@£$%,Min 8 Length"
          />

          {passwordError && (
            <div style={{ color: "red" }} className="passwordConfirmText">
              {passwordError}
            </div>
          )}

          <label
            className="form__label--visually-hidden"
            htmlFor="passwordConfirm"
          >
            Confirm Password:
          </label>
          <input
            id="confirmPassword"
            className={`form__input ${validPwdClass}`}
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={onConfirmPasswordChanged}
            placeholder="Confirm Password"
          />

          {/* <label className="form__label--visually-hidden" htmlFor="roles">
            ASSIGNED ROLES:
          </label>
          <select
            id="roles"
            name="roles"
            className={`form__select ${validRolesClass}`}
            multiple={true}
            size="3"
            value={roles}
            onChange={onRolesChanged}
          >
            {options}
          </select> */}

          <label className="form__label--visually-hidden" htmlFor="companyName">
            Company Name
          </label>
          <input
            className={`form__input ${validCompanyNameClass}`}
            id="companyName"
            name="companyName"
            type="txt"
            value={companyName}
            onChange={onCompanyNameChanged}
            onFocus={(e) => {
              if (e.target.value === "Company Name") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Company Name";
              }
            }}
            placeholder="Company Name"
          />

          {/* <label
            className="form__label--visually-hidden"
            htmlFor="profilePicture"
          >
            Profile Picture
          </label>
          <input
            className={`form__input ${validProfilePictureClass}`}
            id="profilePicture"
            name="profilePicture"
            type="text"
            value={profilePicture}
            onChange={onProfilePictureChanged}
            onFocus={(e) => {
              if (e.target.value === "Profile Picture") {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "Profile Picture";
              }
            }}
            placeholder="Profile Picture"
          /> */}
          <label
            className="form__label--visually-hidden"
            htmlFor="profilePicture"
          >
            Profile Picture
          </label>
          <p
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              color: "#a3a3a3",
              marginBottom: "10px",
            }}
          >
            Profile Picture
          </p>

          <input
            className={`form__input`}
            id="profilePicture"
            name="profilePicture"
            type="file"
            // accept="image/*"
            // value={profilePicture}
            // onChange={onProfilePictureChanged}
            onChange={onSelectFile}
            // onFocus={(e) => {
            //   if (e.target.value === "Profile Picture") {
            //     e.target.value = "";
            //   }
            // }}
            // onBlur={(e) => {
            //   if (e.target.value === "") {
            //     e.target.value = "Profile Picture";
            //   }
            // }}
            // placeholder="Profile Picture"
          />
          <div className="newDealerFormCropper">
            {errorPfp && <p style={{ color: "red" }}>{errorPfp}</p>}

            {imgSrc && (
              <div className="flex flex-col items-center">
                <ReactCrop
                  crop={crop}
                  // onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                  onChange={(pixelCrop, percentCrop) => {
                    setCrop(percentCrop);
                    handleCropImage(); // Automatically crop when selection changes
                  }}
                  keepSelection
                  aspect={ASPECT_RATIO}
                  minWidth={MIN_DIMENSION}
                >
                  <img
                    ref={imgRef}
                    src={imgSrc}
                    alt="Profile"
                    style={{
                      maxHeight: "50vh", // Adjust max height to make the image smaller
                      maxWidth: "360px",
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                {/* <button type="button" onClick={handleCropImage}>
      Crop Image
    </button> */}
              </div>
            )}

            {crop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  display: "none", // Hide the canvas (or display it if you want to preview)
                  border: "1px solid black",
                  objectFit: "contain",
                  width: 150,
                  height: 150,
                }}
              />
            )}
          </div>
        </div>

        {/* <label className="form__label--visually-hidden" htmlFor="latitude">
          Latitude
        </label>
        <input
          className={`form__input ${validLatitudeClass}`}
          id="latitude"
          name="latitude"
          value={location.latitude}
          onChange={onLocationChanged}
          onFocus={(e) => {
            if (e.target.value === "Latiude") {
              e.target.value = "";
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              e.target.value = "Latitude";
            }
          }}
          placeholder="Latitude"
        />

        <label className="form__label--visually-hidden" htmlFor="longitude">
          Longitude
        </label>
        <input
          className={`form__input ${validLongitudeClass}`}
          id="longitude"
          name="longitude"
          value={location.longitude}
          onChange={onLocationChanged}
          onFocus={(e) => {
            if (e.target.value === "Latiude") {
              e.target.value = "";
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              e.target.value = "Longitude";
            }
          }}
          placeholder="Longitude"
        /> */}

        <div className="form__action-buttons">
          <button className="icon-button" title="Save" disabled={!canSave}>
            Sign Up
          </button>
        </div>
      </form>
    </>
  );

  return content;
};

export default NewDealershipForm;
