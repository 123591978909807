import { useState, useEffect } from "react";
// import { useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom";
import { useAddNewVehicleMutation } from "./vehiclesApiSlice";
import { Tooltip } from "react-tooltip";
// import ReactCrop, {
//   centerCrop,
//   convertToPixelCrop,
//   makeAspectCrop,
// } from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

// const ASPECT_RATIO = 14 / 9;
// const MIN_DIMENSION = 150;

const NewVehicleForm = ({ filteredUser }) => {
  const [addNewVehicle, { isLoading, isSuccess, isError, error }] =
    useAddNewVehicleMutation();

  const navigate = useNavigate();

  // const [userId, setUserId] = useState(
  //   users && users.length > 0 ? users[0].id : ""
  // );
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [modelVariant, setModelVariant] = useState("");
  const [details, setDetails] = useState(""); // this field is optional
  const [year, setYear] = useState("");
  const [pricePerDay, setPricePerDay] = useState("");
  const [registration, setRegistration] = useState("");
  const [drivetrain, setDrivetrain] = useState("four-wheel drive"); // ["rear-wheel drive", "four-wheel drive", "front-wheel drive"]
  const [bodyType, setBodyType] = useState("Convertible"); // [ "convertible", "coupe", "estate", "hatchback", "limo", "suv", "saloon",]
  const [doors, setDoors] = useState("");
  const [seats, setSeats] = useState("");
  const [power, setPower] = useState("");
  const [torque, setTorque] = useState("");

  const [images, setImages] = useState([]);
  // const imgRef = useRef(null);
  // const previewCanvasRef = useRef(null);
  // const [crops, setCrops] = useState([]);
  // const [errorImages, setErrorImages] = useState("");
  // const [imgSrcs, setImgSrcs] = useState([]); // To store image sources for all images
  // const [originalFileNames, setOriginalFileNames] = useState([]);

  // const [imagesUrl, setImagesUrl] = useState([]);
  const [slide, setSlide] = useState(0);
  const [imagesUrl, setImagesUrl] = useState([]);

  // const [location, setLocation] = useState({
  //   latitude: "",
  //   longitude: "",
  // });

  const [address, setAddress] = useState({
    houseNumberAndStreetName: "",
    locality: "",
    cityOrTown: "",
    postcode: "",
    country: "United Kingdom", // Assuming default is UK
  });

  const [delivery, setDelivery] = useState({
    acceptHomeDelivery: false,
    acceptPickUpFromDealership: true,
    homeDeliveryMaximumMileage: "",
    homeDeliveryChargePricePerMile: "",
  });

  const [available, setAvailable] = useState(false);
  // const [owner, setOwner] = useState(
  //   users && users.length > 0 ? users[0].id : ""
  // );
  const [owner, setOwner] = useState(
    filteredUser && filteredUser.length > 0 ? filteredUser[0].id : ""
  );
  const [mileage, setMileage] = useState("");
  const [status, setStatus] = useState("Good"); // ["Good", "Major Damage", "Minor Damage"]
  const [notes, setNotes] = useState(""); // this field is optional
  const [colour, setColour] = useState("");
  const [mileageLimit, setMileageLimit] = useState("");
  const [deposit, setDeposit] = useState("");
  const [additionalDriverPricePerDay, setAdditionalDriverPricePerDay] =
    useState("");
  const [chauffeurAvailable, setChauffeurAvailable] = useState(false);
  const [chauffeurPricePerDay, setChauffeurPricePerDay] = useState("");
  const [minimumAge, setMinimumAge] = useState("");
  const [availability, setAvailability] = useState({
    startTimes: "",
    endTimes: "",
    daysOfWeekDealershipOpenForThisVehicle: "",
  });
  const [sameDayReturnAndStart, setSameDayReturnAndStart] = useState(() => ({
    acceptSameDayReturnAndStart: false,
    minGapBetweenBookings: 0,
  }));

  const nextSlide = () => {
    setSlide(slide === imagesUrl.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? imagesUrl.length - 1 : slide - 1);
  };

  // Next and previous slide handlers
  // const nextSlide = () => {
  //   setSlide((prev) => (prev === imgSrcs.length - 1 ? 0 : prev + 1));
  // };

  // const prevSlide = () => {
  //   setSlide((prev) => (prev === 0 ? imgSrcs.length - 1 : prev - 1));
  // };

  useEffect(() => {
    if (isSuccess) {
      // setUserId("");
      setMake("");
      setModel("");
      setModelVariant("");
      setDetails("");
      setYear("");
      setPricePerDay("");
      setRegistration("");
      setDrivetrain("four-wheel drive");
      setBodyType("convertible");
      // setDrivetrain(["four-wheel drive"]);
      // setBodyType(["convertible"]);
      setDoors("");
      setImagesUrl("");
      setSeats("");
      setPower("");
      setTorque("");
      setImages([]);
      // setLocation({
      //   latitude: "",
      //   longitude: "",
      // });
      setAddress({
        houseNumberAndStreetName: "",
        locality: "",
        cityOrTown: "",
        postcode: "",
        country: "",
      });
      setDelivery({
        acceptHomeDelivery: false,
        acceptPickUpFromDealership: true,
        homeDeliveryMaximumMileage: "",
        homeDeliveryChargePricePerMile: "",
      });
      setAvailability({
        startTimes: "",
        endTimes: "",
        daysOfWeekDealershipOpenForThisVehicle: "",
      });
      setSameDayReturnAndStart({
        acceptSameDayReturnAndStart: false,
        minGapBetweenBookings: "",
      });
      setAvailable(false);
      setOwner("");
      setMileage("");
      setStatus(["Good"]);
      setNotes("");
      setColour("");
      setMileageLimit("");
      setDeposit("");
      setAdditionalDriverPricePerDay("");
      setChauffeurAvailable(false);
      setChauffeurPricePerDay("");
      setMinimumAge("");
      navigate("/dashboard/myVehicles");
    }
  }, [isSuccess, navigate]);

  const onMakeChanged = (e) => setMake(e.target.value);
  const onModelChanged = (e) => setModel(e.target.value);
  const onModelVariantChanged = (e) => setModelVariant(e.target.value);
  const onDetailsChanged = (e) => setDetails(e.target.value);
  const onYearChanged = (e) => setYear(e.target.value);
  const onPricePerDayChanged = (e) => setPricePerDay(e.target.value);
  const onRegistrationChanged = (e) => setRegistration(e.target.value);
  const onDrivetrainChanged = (e) => setDrivetrain(e.target.value);
  const onBodyTypeChanged = (e) => setBodyType(e.target.value);
  const onDoorsChanged = (e) => setDoors(e.target.value);
  const onSeatsChanged = (e) => setSeats(e.target.value);
  const onPowerChanged = (e) => setPower(e.target.value);
  const onTorqueChanged = (e) => setTorque(e.target.value);

  const onDeliveryChanged = (e) => {
    const { name, type, value, checked } = e.target;

    setDelivery((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? Number(value)
          : value,
    }));
  };

  // const onImagesChanged = (e) => setImages(e.target.value);

  // const onImagesChanged = (e) => {
  //   const files = e.target.files;
  //   if (files.length > 0) {
  //     // Convert files to an array of file objects
  //     const fileObjects = Array.from(files);
  //     // Now you can do whatever you want with the fileObjects array
  //     console.log("Selected files:", fileObjects);
  //     // For example, you can set them in state

  //     // Generate URLs for the selected files
  //     const fileUrls = fileObjects.map((file) => URL.createObjectURL(file));

  //     setImages(fileObjects);
  //     setImagesUrl(fileUrls);
  //   }
  // };
  const onSelectFile = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      // Convert files to an array of file objects
      const fileObjects = Array.from(files);
      // Now you can do whatever you want with the fileObjects array
      console.log("Selected files:", fileObjects);
      // For example, you can set them in state

      // Generate URLs for the selected files
      const fileUrls = fileObjects.map((file) => URL.createObjectURL(file));

      setImages(fileObjects);
      setImagesUrl(fileUrls);
    }
  };

  // Set canvas preview
  // const setCanvasPreview = (image, canvas, crop) => {
  //   if (!crop || !canvas || !image) return;

  //   const ctx = canvas.getContext("2d");
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   const pixelRatio = window.devicePixelRatio;

  //   canvas.width = crop.width * pixelRatio;
  //   canvas.height = crop.height * pixelRatio;
  //   canvas.style.width = `${crop.width}px`;
  //   canvas.style.height = `${crop.height}px`;

  //   ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  //   ctx.imageSmoothingQuality = "high";

  //   ctx.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   );
  // };

  // Handle file selection This was the one previously used
  // const onSelectFile = (e) => {
  //   const files = Array.from(e.target.files);
  //   if (!files.length) return;

  //   const imageSrcs = [];
  //   const fileNames = [];

  //   files.forEach((file, index) => {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => {
  //       const imageElement = new Image();
  //       const imageUrl = reader.result?.toString() || "";
  //       imageElement.src = imageUrl;

  //       imageElement.addEventListener("load", (e) => {
  //         const { naturalWidth, naturalHeight } = e.currentTarget;
  //         if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
  //           setErrorImages(
  //             `Image ${file.name} must be at least 150 x 150 pixels.`
  //           );
  //           return;
  //         }

  //         imageSrcs.push(imageUrl);
  //         fileNames.push(file.name);

  //         if (index === files.length - 1) {
  //           setImgSrcs(imageSrcs); // Set all image sources once loaded
  //           setOriginalFileNames(fileNames);
  //           setErrorImages(""); // Clear error if valid
  //         }
  //       });
  //     });
  //     reader.readAsDataURL(file);
  //   });
  // };

  // Handle cropping for each image
  // const handleCropImage = useCallback(
  //   (index) => {
  //     if (!imgRef.current || !crops) return;

  //     // Generate cropped image on the canvas
  //     setCanvasPreview(
  //       imgRef.current, // HTMLImageElement
  //       previewCanvasRef.current, // HTMLCanvasElement
  //       convertToPixelCrop(crops, imgRef.current.width, imgRef.current.height)
  //     );

  //     // Convert the canvas to a Blob and send it as a file
  //     previewCanvasRef.current.toBlob((blob) => {
  //       if (blob) {
  //         const sanitizedFileName = originalFileNames[index].replace(
  //           /\s+/g,
  //           "_"
  //         );
  //         const croppedImageFile = new File(
  //           [blob],
  //           `${sanitizedFileName}_cropped.jpg`,
  //           {
  //             type: "image/jpeg",
  //           }
  //         );

  //         // Update the specific image in the images array
  //         setImages((prevImages) => {
  //           const updatedImages = [...prevImages];
  //           updatedImages[index] = croppedImageFile;
  //           return updatedImages;
  //         });
  //       }
  //     }, "image/jpeg");
  //   },
  //   [crops, originalFileNames]
  // );

  // const handleCropImage = useCallback(
  //   (index) => {
  //     if (!imgRef.current || !crops || !crops[index]) return;

  //     // Get the specific crop for this image index
  //     const currentCrop = crops[index];

  //     // Generate cropped image on the canvas
  //     setCanvasPreview(
  //       imgRef.current, // HTMLImageElement
  //       previewCanvasRef.current, // HTMLCanvasElement
  //       convertToPixelCrop(
  //         currentCrop,
  //         imgRef.current.width,
  //         imgRef.current.height
  //       ) // Use the crop for the current image
  //     );

  //     // Convert the canvas to a Blob and send it as a file
  //     previewCanvasRef.current.toBlob((blob) => {
  //       if (blob) {
  //         const sanitizedFileName = originalFileNames[index].replace(
  //           /\s+/g,
  //           "_"
  //         );
  //         const croppedImageFile = new File(
  //           [blob],
  //           `${sanitizedFileName}_cropped.jpg`,
  //           {
  //             type: "image/jpeg",
  //           }
  //         );

  //         // Update the specific image in the images array
  //         setImages((prevImages) => {
  //           const updatedImages = [...prevImages];
  //           updatedImages[index] = croppedImageFile; // Set the cropped image for this index
  //           return updatedImages;
  //         });
  //       }
  //     }, "image/jpeg");
  //   },
  //   [crops, originalFileNames]
  // );

  // console.log(images);
  // this was the one previously used
  // const handleCropImage = useCallback(
  //   (index) => {
  //     if (!imgRef.current || !crops || !crops[index]) return;

  //     const currentCrop = crops[index];
  //     setCanvasPreview(
  //       imgRef.current,
  //       previewCanvasRef.current,
  //       convertToPixelCrop(
  //         currentCrop,
  //         imgRef.current.width,
  //         imgRef.current.height
  //       )
  //     );

  //     previewCanvasRef.current.toBlob((blob) => {
  //       if (blob) {
  //         const sanitizedFileName = originalFileNames[index].replace(
  //           /\s+/g,
  //           "_"
  //         );
  //         const croppedImageFile = new File(
  //           [blob],
  //           `${sanitizedFileName}_cropped.jpg`,
  //           { type: "image/jpeg" }
  //         );

  //         // Only update the images state if the cropped file differs from the current one
  //         setImages((prevImages) => {
  //           const updatedImages = [...prevImages];
  //           if (!prevImages[index] || prevImages[index] !== croppedImageFile) {
  //             updatedImages[index] = croppedImageFile; // Set the cropped image for this index
  //           }
  //           return updatedImages; // Return the updated array
  //         });
  //       }
  //     }, "image/jpeg");
  //   },
  //   [crops, originalFileNames]
  // );

  // // useEffect to handle cropping when a new file is selected
  // useEffect(() => {
  //   if (imgSrcs.length > 0) {
  //     imgSrcs.forEach((_, index) => handleCropImage(index)); // Automatically crop all images
  //   }
  // }, [imgSrcs, handleCropImage]);

  // useEffect(() => {
  //   if (imgSrcs.length > 0) {
  //     // Initialize a crop for each image
  //     const initialCrops = imgSrcs.map(() => ({
  //       unit: "%",
  //       width: 75, // Default width (adjust as needed)
  //       height: 75, // Default height (adjust as needed)
  //       x: 0,
  //       y: 0,
  //     }));
  //     setCrops(initialCrops); // Set the crops for all images
  //   }
  // }, [imgSrcs]);

  // useEffect(() => {
  //   if (imgSrcs.length > 0) {
  //     const initialCrops = imgSrcs.map(() => {
  //       const width = 75; // Default width (in %)
  //       const height = 75; // Default height (in %)

  //       return {
  //         unit: "%",
  //         width: width,
  //         height: height,
  //         x: (100 - width) / 2, // Center x
  //         y: (100 - height) / 2, // Center y
  //       };
  //     });

  //     setCrops(initialCrops); // Set the crops for all images

  //     // Automatically crop each image after crops are set
  //     imgSrcs.forEach((_, index) => {
  //       handleCropImage(index); // Call the crop function for each image
  //     });
  //   }
  // }, [imgSrcs, handleCropImage]);

  // this was the one previously used
  // useEffect(() => {
  //   if (imgSrcs.length > 0) {
  //     const initialCrops = imgSrcs.map(() => ({
  //       unit: "%",
  //       width: 75,
  //       height: 75,
  //       x: (100 - 75) / 2, // Center x
  //       y: (100 - 75) / 2, // Center y
  //     }));

  //     setCrops(initialCrops); // Set the crops for all images
  //   }
  // }, [imgSrcs]); // Run this effect when imgSrcs changes

  // this was the one previously used
  // useEffect(() => {
  //   // Crop images whenever crops are updated
  //   if (crops.length > 0) {
  //     crops.forEach((_, index) => {
  //       handleCropImage(index); // Call the crop function for each image
  //     });
  //   }
  // }, [crops, handleCropImage]); // This effect runs when crops change

  // useEffect(() => {
  //   if (imgSrcs.length > 0) {
  //     const initialCrops = imgSrcs.map(() => {
  //       const width = 75;
  //       const height = 75;
  //       return {
  //         unit: "%",
  //         width: width,
  //         height: height,
  //         x: (100 - width) / 2,
  //         y: (100 - height) / 2,
  //       };
  //     });

  //     setCrops(initialCrops);

  //     imgSrcs.forEach((_, index) => {
  //       handleCropImage(index); // Crop each image after initialization
  //     });
  //   }
  // }, [imgSrcs, handleCropImage]); // Add dependencies as needed

  // Set crop on image load
  // const onImageLoad = (e, idx) => {
  //   const { width, height } = e.currentTarget;
  //   const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

  //   const crop = makeAspectCrop(
  //     {
  //       unit: "%",
  //       width: cropWidthInPercent,
  //     },
  //     ASPECT_RATIO,
  //     width,
  //     height
  //   );
  //   const centeredCrop = centerCrop(crop, width, height);

  //   // Set the crop for the specific image
  //   const updatedCrops = [...crops];
  //   updatedCrops[idx] = centeredCrop; // Assign the crop for this specific image
  //   setCrops(updatedCrops);
  // };

  // this was the one previously used
  // const onImageLoad = (e, idx) => {
  //   const { width, height } = e.currentTarget;
  //   const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

  //   const crop = makeAspectCrop(
  //     {
  //       unit: "%",
  //       width: cropWidthInPercent,
  //     },
  //     ASPECT_RATIO,
  //     width,
  //     height
  //   );
  //   const centeredCrop = centerCrop(crop, width, height);

  //   // Set the crop for the specific image
  //   const updatedCrops = [...crops];
  //   updatedCrops[idx] = centeredCrop; // Assign the crop for this specific image
  //   setCrops(updatedCrops);
  //};

  const onAvailableChanged = (e) => {
    setAvailable(e.target.checked);
  };

  const onOwnerChanged = (e) => setOwner(e.target.value); // may not need this
  const onMileageChanged = (e) => setMileage(e.target.value);
  const onStatusChanged = (e) => setStatus(e.target.value);
  const onNotesChanged = (e) => setNotes(e.target.value);
  const onColourChanged = (e) => setColour(e.target.value);
  const onMileageLimitChanged = (e) => setMileageLimit(e.target.value);
  const onDepositChanged = (e) => setDeposit(e.target.value);
  const onAdditionalDriverPricePerDay = (e) =>
    setAdditionalDriverPricePerDay(e.target.value);
  const onChauffeurAvailableChanged = (e) =>
    setChauffeurAvailable(e.target.checked);
  const onChauffeurPricePerDayChanged = (e) =>
    setChauffeurPricePerDay(e.target.value);
  const onMinimumAgeChanged = (e) => setMinimumAge(e.target.value);
  // const onLocationChanged = (e) => {
  //   const { name, value } = e.target;
  //   setLocation((prevLocation) => ({
  //     ...prevLocation,
  //     [name]: value,
  //   }));
  // };

  const onAddressChanged = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  // const onAvailabilityChanged = (e) => {
  //   const { name, value, checked } = e.target;

  //   setAvailability((prevAvailability) => {
  //     const updatedArray = checked
  //       ? [...prevAvailability[name], value]
  //       : prevAvailability[name].filter((item) => item !== value);

  //     return {
  //       ...prevAvailability,
  //       [name]: updatedArray,
  //     };
  //   });
  // };

  const onAvailabilityChanged = (e) => {
    const { name, value, checked } = e.target;

    setAvailability((prevAvailability) => {
      const updatedArray = checked
        ? [...prevAvailability[name], value].sort() // Sort after adding/removing
        : prevAvailability[name].filter((item) => item !== value);

      return {
        ...prevAvailability,
        [name]: updatedArray,
      };
    });
  };

  const onSameDayReturnAndStartChange = (e) => {
    const { name, value, type, checked } = e.target;

    setSameDayReturnAndStart((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : parseInt(value),
    }));
  };

  const canSave =
    [
      make,
      model,
      modelVariant,
      year,
      pricePerDay,
      registration,
      drivetrain,
      bodyType,
      doors,
      seats,
      power,
      torque,
      images,
      available,
      owner,
      mileage,
      status,
      colour,
      mileageLimit,
      deposit,
      additionalDriverPricePerDay,
      minimumAge,
      // chauffeurAvailable,
      // chauffeurPricePerDay,
      // location,
      address,
      availability,
      sameDayReturnAndStart,
      delivery,
    ].every(Boolean) && !isLoading;

  const onSaveVehicleClicked = async (e) => {
    e.preventDefault();

    const updatedDelivery = {
      acceptHomeDelivery: delivery?.acceptHomeDelivery ?? false, // Default to false if not provided
      acceptPickUpFromDealership: delivery?.acceptPickUpFromDealership ?? true, // Default to true if not provided
      homeDeliveryMaximumMileage: delivery?.homeDeliveryMaximumMileage
        ? delivery.homeDeliveryMaximumMileage
        : 0, // Default to 0 if falsy
      homeDeliveryChargePricePerMile: delivery?.homeDeliveryChargePricePerMile
        ? delivery.homeDeliveryChargePricePerMile
        : 0, // Default to 0 if falsy
    };

    if (canSave) {
      await addNewVehicle({
        owner,
        make,
        model,
        modelVariant,
        year,
        pricePerDay,
        registration,
        drivetrain,
        bodyType,
        doors,
        seats,
        power,
        torque,
        images,
        available,
        mileage,
        status,
        colour,
        mileageLimit,
        deposit,
        additionalDriverPricePerDay,
        // location,
        address,
        notes,
        details,
        chauffeurAvailable,
        chauffeurPricePerDay: chauffeurPricePerDay ? chauffeurPricePerDay : 0,
        minimumAge,
        availability,
        sameDayReturnAndStart,
        delivery: updatedDelivery,
        // id: vehicle.id,
        // user: userId,
      });
    }
  };

  const options = filteredUser?.length ? (
    filteredUser.map((user) => (
      <option key={user.id} value={user.id}>
        {" "}
        {user.email}
      </option>
    ))
  ) : (
    <option disabled>No users available</option>
  );

  const driveTrainOptions = [
    "rear-wheel drive",
    "four-wheel drive",
    "front-wheel drive",
  ];
  // const bodyTypeOptions = [
  //   "convertible",
  //   "coupe",
  //   "estate",
  //   "hatchback",
  //   "limo",
  //   "suv",
  //   "saloon",
  // ];
  const bodyTypeOptions = [
    "Convertible",
    "Coupe",
    "Estate",
    "Hatchback",
    "Limo",
    "Suv",
    "Saloon",
  ];

  // Time Options
  // const timeOptions = [
  //   "0-1am",
  //   "1-2am",
  //   "2-3am",
  //   "3-4am",
  //   "4-5am",
  //   "5-6am",
  //   "6-7am",
  //   "7-8am",
  //   "8-9am",
  //   "9-10am",
  //   "10-11am",
  //   "11-12pm",
  //   "12-1pm",
  //   "1-2pm",
  //   "2-3pm",
  //   "3-4pm",
  //   "4-5pm",
  //   "5-6pm",
  //   "6-7pm",
  //   "7-8pm",
  //   "8-9pm",
  //   "9-10pm",
  //   "10-11pm",
  //   "11-12am",
  // ];

  // Time Options
  const timeOptions = [
    "12:00am",
    "12:30am",
    "1:00am",
    "1:30am",
    "2:00am",
    "2:30am",
    "3:00am",
    "3:30am",
    "4:00am",
    "4:30am",
    "5:00am",
    "5:30am",
    "6:00am",
    "6:30am",
    "7:00am",
    "7:30am",
    "8:00am",
    "8:30am",
    "9:00am",
    "9:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "1:00pm",
    "1:30pm",
    "2:00pm",
    "2:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
    "4:30pm",
    "5:00pm",
    "5:30pm",
    "6:00pm",
    "6:30pm",
    "7:00pm",
    "7:30pm",
    "8:00pm",
    "8:30pm",
    "9:00pm",
    "9:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
  ];

  // Days of the Week Options
  const daysOfWeekOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const vehicleStatusOptions = ["Good", "Major Damage", "Minor Damage"];

  const errClass = isError ? "errmsg" : "offscreen";
  const validMakeClass = !make ? "form__input--incomplete" : "";
  const validModelClass = !model ? "form__input--incomplete" : "";
  const validModelVariantClass = !modelVariant ? "form__input--incomplete" : "";
  const validDetailsClass = !details ? "form__input--incomplete" : "";
  const validYearClass = !year ? "form__input--incomplete" : "";
  const validPricePerDayClass = !pricePerDay ? "form__input--incomplete" : "";
  const validRegistrationClass = !registration ? "form__input--incomplete" : "";
  const validDrivetrainClass = !drivetrain ? "form__input--incomplete" : "";
  const validBodyTypeClass = !bodyType ? "form__input--incomplete" : "";
  const validDoorsClass = !doors ? "form__input--incomplete" : "";
  const validSeatsClass = !seats ? "form__input--incomplete" : "";
  const validPowerClass = !power ? "form__input--incomplete" : "";
  const validTorqueClass = !torque ? "form__input--incomplete" : "";

  // const validImagesClass = !images ? "form__input--incomplete" : "";

  const validDeliveryClass = !delivery ? "form__input--incomplete" : "";

  const validAvailableClass = !available ? "form__input--incomplete" : "";
  const validOwnerClass = !owner ? "form__input--incomplete" : "";
  const validMileageClass = !mileage ? "form__input--incomplete" : "";
  const validStatusClass = !status ? "form__input--incomplete" : "";
  const validNotesClass = !notes ? "form__input--incomplete" : "";
  const validColourClass = !colour ? "form__input--incomplete" : "";
  const validMileageLimitClass = !mileageLimit ? "form__input--incomplete" : "";
  const validDepositClass = !deposit ? "form__input--incomplete" : "";
  const validAdditionalDriverPricePerDayClass = !additionalDriverPricePerDay
    ? "form__input--incomplete"
    : "";
  const validChauffeurAvailableClass = !chauffeurAvailable
    ? "form__input--incomplete"
    : "";
  const validChauffeurPricePerDayClass = !chauffeurPricePerDay
    ? "form__input--incomplete"
    : "";
  const validMinimumAgeClass = !minimumAge ? "form__input--incomplete" : "";
  // const validLocationClass = !location ? "form__input--incomplete" : "";
  // const validLatitudeClass =
  //   location.latitude.trim().length > 0 ? "" : "form__input--complete";
  // const validLatitudeClass =
  //   typeof location.latitude === "string" && location.latitude.trim().length > 0
  //     ? ""
  //     : "form__input--complete";

  // // const validLongitudeClass =
  // //   location.longitude.trim().length > 0 ? "" : "form__input--complete";
  // const validLongitudeClass =
  //   typeof location.longitude === "string" &&
  //   location.longitude.trim().length > 0
  //     ? ""
  //     : "form__input--complete";
  const validhouseNumberAndStreetNameClass = address.houseNumberAndStreetName
    ? ""
    : "form__input--incomplete";
  const validlocalityClass = address.locality ? "" : "form__input--incomplete";
  const validcityOrTownClass = address.cityOrTown
    ? ""
    : "form__input--incomplete";
  const validpostcodeClass = address.postcode ? "" : "form__input--incomplete";

  // console.log(delivery);

  const content = (
    <>
      <p className={errClass} style={{ marginLeft: "700px" }}>
        {error?.data?.message}
      </p>
      <form className="newVehicleForm" onSubmit={onSaveVehicleClicked}>
        <h1>Add New Vehicle</h1>
        <div className="newVehicleFormSaveBtn">
          <button
            className="saveBtn"
            title="save"
            disabled={!canSave}
            onClick={onSaveVehicleClicked}
          >
            Save
          </button>
        </div>
        <div className="newVehicleFormDivs">
          <div className="newVehicleFormVehicleDetails">
            <div className="newVehicleFormVehicleDetailsColumn">
              <p className="newVehicleFormPTag">Vehicle Details</p>
              <label className="form__label--visually-hidden" htmlFor="make">
                Vehicle Make
              </label>
              <input
                className={`form__input__vehicle ${validMakeClass}`}
                id="make"
                name="make"
                type="text"
                autoComplete="off"
                value={make}
                onChange={onMakeChanged}
                onFocus={(e) => {
                  if (e.target.value === "Make") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Make";
                  }
                }}
                placeholder="Make"
              />
              <label className="form__label--visually-hidden" htmlFor="model">
                Vehicle Model
              </label>
              <input
                className={`form__input__vehicle ${validModelClass}`}
                id="model"
                name="model"
                type="text"
                autoComplete="off"
                value={model}
                onChange={onModelChanged}
                onFocus={(e) => {
                  if (e.target.value === "Model") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Model";
                  }
                }}
                placeholder="Model"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="modelVariant"
              >
                Vehicle Model Variant
              </label>
              <input
                className={`form__input__vehicle ${validModelVariantClass}`}
                id="modelVariant"
                name="modelVariant"
                type="text"
                autoComplete="off"
                value={modelVariant}
                onChange={onModelVariantChanged}
                onFocus={(e) => {
                  if (e.target.value === "Model Variant") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Model Variant";
                  }
                }}
                placeholder="Model Variant"
              />
              <label className="form__label--visually-hidden" htmlFor="details">
                Vehicle Details
              </label>
              <input
                className={`form__input__vehicle ${validDetailsClass}`}
                id="details"
                name="details"
                type="text"
                autoComplete="off"
                value={details}
                onChange={onDetailsChanged}
                onFocus={(e) => {
                  if (e.target.value === "Details") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Details";
                  }
                }}
                placeholder="Details"
              />
              <label className="form__label--visually-hidden" htmlFor="year">
                Vehicle Year
              </label>
              <input
                className={`form__input__vehicle ${validYearClass}`}
                id="year"
                name="year"
                type="number"
                autoComplete="off"
                value={year}
                onChange={onYearChanged}
                onFocus={(e) => {
                  if (e.target.value === "Year") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Year";
                  }
                }}
                placeholder="Year"
              />
            </div>
            <div
              className="newVehicleFormVehicleDetailsColumn"
              style={{ marginTop: "20px" }}
            >
              <label
                className="form__label--visually-hidden"
                htmlFor="registration"
              >
                Vehicle Registration
              </label>
              <input
                className={`form__input__vehicle ${validRegistrationClass}`}
                id="registration"
                name="registration"
                type="text"
                autoComplete="off"
                value={registration}
                onChange={onRegistrationChanged}
                onFocus={(e) => {
                  if (e.target.value === "Registration") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Registration";
                  }
                }}
                placeholder="Registration"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="drivetrain"
              >
                Vehicle Drivetrain
              </label>
              <p className="newVehicleFormDrivetrain">Drivetrain</p>
              <select
                className={`form__input__vehicle ${validDrivetrainClass}`}
                name="drivetrain"
                value={drivetrain}
                onChange={onDrivetrainChanged}
              >
                {driveTrainOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label
                className="form__label--visually-hidden"
                htmlFor="bodytype"
              >
                Vehicle Bodytype
              </label>
              <p className="newVehicleFormDrivetrain">Bodytype</p>
              <select
                className={`form__input__vehicle ${validBodyTypeClass}`}
                name="bodytype"
                value={bodyType}
                onChange={onBodyTypeChanged}
              >
                {bodyTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label className="form__label--visually-hidden" htmlFor="doors">
                Vehicle Doors
              </label>
              <input
                className={`form__input__vehicle ${validDoorsClass}`}
                id="doors"
                name="doors"
                type="number"
                autoComplete="off"
                value={doors}
                onChange={onDoorsChanged}
                onFocus={(e) => {
                  if (e.target.value === "Doors") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Doors";
                  }
                }}
                placeholder="Doors"
              />
              <label className="form__label--visually-hidden" htmlFor="seats">
                Vehicle Seats
              </label>
              <input
                className={`form__input__vehicle ${validSeatsClass}`}
                id="seats"
                name="seats"
                type="number"
                autoComplete="off"
                value={seats}
                onChange={onSeatsChanged}
                onFocus={(e) => {
                  if (e.target.value === "Seats") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Seats";
                  }
                }}
                placeholder="Seats"
              />
            </div>

            <div
              className="newVehicleFormVehicleDetailsColumn"
              style={{ marginTop: "20px" }}
            >
              <label className="form__label--visually-hidden" htmlFor="power">
                Vehicle Power
              </label>
              <input
                className={`form__input__vehicle ${validPowerClass}`}
                id="power"
                name="power"
                type="number"
                autoComplete="off"
                value={power}
                onChange={onPowerChanged}
                onFocus={(e) => {
                  if (e.target.value === "Power") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Power";
                  }
                }}
                placeholder="Power"
              />
              <label className="form__label--visually-hidden" htmlFor="torque">
                Vehicle Torque
              </label>
              <input
                className={`form__input__vehicle ${validTorqueClass}`}
                id="torque"
                name="torque"
                type="number"
                autoComplete="off"
                value={torque}
                onChange={onTorqueChanged}
                onFocus={(e) => {
                  if (e.target.value === "Torque") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Torque";
                  }
                }}
                placeholder="Torque"
              />
              <label className="form__label--visually-hidden" htmlFor="colour">
                Vehicle Colour
              </label>
              <input
                className={`form__input__vehicle ${validColourClass}`}
                id="colour"
                name="colour"
                type="text"
                autoComplete="off"
                value={colour}
                onChange={onColourChanged}
                onFocus={(e) => {
                  if (e.target.value === "Colour") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Colour";
                  }
                }}
                placeholder="Colour"
              />
              <label className="form__label--visually-hidden" htmlFor="mileage">
                Vehicle Mileage
              </label>
              <input
                className={`form__input__vehicle ${validMileageClass}`}
                id="mileage"
                name="mileage"
                type="number"
                autoComplete="off"
                value={mileage}
                onChange={onMileageChanged}
                onFocus={(e) => {
                  if (e.target.value === "Mileage") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Mileage";
                  }
                }}
                placeholder="Mileage"
              />
              <label className="form__label--visually-hidden" htmlFor="images">
                Vehicle Images
              </label>
              <p className="newVehicleFormDrivetrain">Images Max 5</p>
              <input
                className={`form__input__images`}
                id="images"
                name="images"
                type="file"
                accept="image/*"
                multiple
                // value={images}
                // onChange={onImagesChanged}
                onChange={onSelectFile}
                // onFocus={(e) => {
                //   if (e.target.value === "Image") {
                //     e.target.value = "";
                //   }
                // }}
                // onBlur={(e) => {
                //   if (e.target.value === "") {
                //     e.target.value = "Image";
                //   }
                // }}
                // placeholder="Image"
              />
            </div>

            <div
              className="newVehicleFormVehicleDetailsColumn"
              style={{ marginTop: "20px" }}
            >
              {/* <label
                className="form__label--visually-hidden"
                htmlFor="latitude"
              >
                Latitude
              </label>
              <input
                className={`form__input__vehicle ${validLatitudeClass}`}
                id="latitude"
                name="latitude"
                type="number"
                value={location.latitude}
                onChange={onLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "Latiude") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Latitude";
                  }
                }}
                placeholder="Latitude"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="longitude"
              >
                Longitude
              </label>
              <input
                className={`form__input__vehicle ${validLongitudeClass}`}
                id="longitude"
                name="longitude"
                type="number"
                value={location.longitude}
                onChange={onLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "Latiude") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Longitude";
                  }
                }}
                placeholder="Longitude"
              /> */}
              <label className="form__label--visually-hidden" htmlFor="address">
                Address:{" "}
                <span className="nowrap">[houseNumberAndStreetName]</span>
              </label>
              <input
                className={`form__input__vehicle ${validhouseNumberAndStreetNameClass}`}
                id="houseNumberAndStreetName"
                name="houseNumberAndStreetName"
                type="text"
                autoComplete="off"
                value={address.houseNumberAndStreetName}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "House No and Street Name") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "House No and Street Name";
                  }
                }}
                placeholder="House No and Street Name"
              />

              <label className="form__label--visually-hidden" htmlFor="address">
                Address: <span className="nowrap">[locality]</span>
              </label>
              <input
                className={`form__input__vehicle ${validlocalityClass}`}
                id="locality"
                name="locality"
                type="text"
                autoComplete="off"
                value={address.locality}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "Line 2") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Line 2";
                  }
                }}
                placeholder="Line 2"
              />

              <label className="form__label--visually-hidden" htmlFor="address">
                Address: <span className="nowrap">[cityOrTown]</span>
              </label>
              <input
                className={`form__input__vehicle ${validcityOrTownClass}`}
                id="cityOrTown"
                name="cityOrTown"
                type="text"
                autoComplete="off"
                value={address.cityOrTown}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "City or Town") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "City or Town";
                  }
                }}
                placeholder="City or Town"
              />

              <label className="form__label--visually-hidden" htmlFor="address">
                Address: <span className="nowrap">[postcode]</span>
              </label>
              <input
                className={`form__input__vehicle ${validpostcodeClass}`}
                id="postcode"
                name="postcode"
                type="text"
                autoComplete="off"
                value={address.postcode}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "Postcode") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Postcode";
                  }
                }}
                placeholder="Postcode"
              />
            </div>
          </div>

          {imagesUrl.length > 0 && (
            <div className="newVehicleFormImagesCollection">
              <div className="imagesCollectionNewVehicle">
                <FaArrowCircleLeft
                  onClick={prevSlide}
                  className="arrow arrow-left"
                />
                {imagesUrl.map((item, idx) => {
                  // console.log(item);
                  return (
                    <img
                      src={item}
                      alt={item.alt}
                      key={idx}
                      className={slide === idx ? "slide" : "slide slide-hidden"}
                    />
                  );
                })}
                <FaArrowCircleRight
                  onClick={nextSlide}
                  className="arrow arrow-right"
                />
                <span className="indicators">
                  {imagesUrl.map((_, idx) => {
                    return (
                      <button
                        key={idx}
                        className={
                          slide === idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() => setSlide(idx)}
                      ></button>
                    );
                  })}
                </span>
              </div>
            </div>
          )}

          {/* <div className="newVehicleFormImagesCollection">
            {errorImages && <p style={{ color: "red" }}>{errorImages}</p>}
            <div className="imagesCollectionNewVehicle">
              <FaArrowCircleLeft
                onClick={prevSlide}
                className="arrow arrow-left"
              />

              {imgSrcs.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={slide === idx ? "slide" : "slide slide-hidden"}
                  > */}
          {/* Crop each image in the slider */}
          {/* <ReactCrop
                      crop={crops[idx]}
                      // onChange={(pixelCrop, percentCrop) => {
                      //   setCrops(percentCrop);
                      //   handleCropImage(idx); // Pass the index to crop each image
                      // }}
                      onChange={(pixelCrop, percentCrop) => {
                        const updatedCrops = [...crops];
                        updatedCrops[idx] = percentCrop; // Update the crop for this specific image
                        setCrops(updatedCrops);
                        handleCropImage(idx); // Pass the index to crop each image individually
                      }}
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    > */}
          {/* <img
                        ref={imgRef}
                        src={item}
                        alt={` ${idx + 1}`}
                        style={{
                          width: "700px", // Fixed width
                          height: "450px", // Fixed height
                          objectFit: "cover",
                        }}
                        // onLoad={onImageLoad}
                        onLoad={(e) => onImageLoad(e, idx)}
                      />
                    </ReactCrop>

                    <canvas
                      ref={previewCanvasRef}
                      style={{ display: "none" }}
                    />
                  </div>
                );
              })}

              <FaArrowCircleRight
                onClick={nextSlide}
                className="arrow arrow-right"
              />

              <span className="indicators">
                {imgSrcs.map((_, idx) => (
                  <button
                    key={idx}
                    className={
                      slide === idx
                        ? "indicator"
                        : "indicator indicator-inactive"
                    }
                    onClick={() => setSlide(idx)}
                  ></button>
                ))}
              </span>
            </div>
          </div> */}

          <div className="newVehicleFormFinancialDetailis">
            <div
              className="newVehicleFormFinancialDetailisColumn"
              style={{ marginTop: "20px" }}
            >
              <p>Extra Details</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="pricePerDay"
              >
                Price Per Day
              </label>
              <input
                className={`form__input__vehicle ${validPricePerDayClass}`}
                id="pricePerDay"
                name="pricePerDay"
                type="number"
                autoComplete="off"
                value={pricePerDay}
                onChange={onPricePerDayChanged}
                onFocus={(e) => {
                  if (e.target.value === "PricePerDay") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "PricePerDay";
                  }
                }}
                placeholder="PricePerDay"
              />
              <label className="form__label--visually-hidden" htmlFor="deposit">
                Vehicle Deposit
              </label>
              <input
                className={`form__input__vehicle ${validDepositClass}`}
                id="deposit"
                name="deposit"
                type="number"
                autoComplete="off"
                value={deposit}
                onChange={onDepositChanged}
                onFocus={(e) => {
                  if (e.target.value === "Deposit") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Deposit";
                  }
                }}
                placeholder="Deposit"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="chauffeurAvailable"
              >
                Vehicle Chauffeur Availability
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for chauffeur?
              </p>
              <input
                className={`form__input__vehicle ${validChauffeurAvailableClass}`}
                id="chauffeurAvailable"
                name="chauffeurAvailable"
                type="checkbox"
                checked={chauffeurAvailable}
                onChange={onChauffeurAvailableChanged}
              />
              <label
                className="form__label--visually-hidden"
                htmlFor=" chauffeurPricePerDay"
              >
                chauffeurPricePerDay
              </label>
              <p className="newVehicleFormCheckboxPTag">
                This is on top of the Price Per Day
              </p>
              <input
                className={`form__input__vehicle ${validChauffeurPricePerDayClass}`}
                id=" chauffeurPricePerDay"
                name=" additionalDriverPricePerDay"
                type="number"
                chauffeurPricePerDay
                autoComplete="off"
                value={chauffeurPricePerDay}
                onChange={onChauffeurPricePerDayChanged}
                onFocus={(e) => {
                  if (e.target.value === " chauffeurPricePerDay") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = " chauffeurPricePerDay";
                  }
                }}
                placeholder="Chauffeur Price/Day"
              />
            </div>
            <div
              className="newVehicleFormFinancialDetailisColumn"
              style={{ marginTop: "40px" }}
            >
              <label
                className="form__label--visually-hidden"
                htmlFor="mileageLimit"
              >
                Vehicle Mileage Limit
              </label>
              <input
                className={`form__input__vehicle ${validMileageLimitClass}`}
                id="mileageLimit"
                name="mileageLimit"
                type="number"
                autoComplete="off"
                value={mileageLimit}
                onChange={onMileageLimitChanged}
                onFocus={(e) => {
                  if (e.target.value === "MileageLimit") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "MileageLimit";
                  }
                }}
                placeholder="MileageLimit/day"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor=" additionalDriverPricePerDay"
              >
                additionalDriverPricePerDay
              </label>
              <input
                className={`form__input__vehicle ${validAdditionalDriverPricePerDayClass}`}
                id=" additionalDriverPricePerDay"
                name=" additionalDriverPricePerDay"
                type="number"
                autoComplete="off"
                value={additionalDriverPricePerDay}
                onChange={onAdditionalDriverPricePerDay}
                onFocus={(e) => {
                  if (e.target.value === " additionalDriverPricePerDay") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = " additionalDriverPricePerDay";
                  }
                }}
                placeholder="Extra Driver/day Fee"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor=" minimum age"
              >
                minimum age
              </label>
              <input
                className={`form__input__vehicle ${validMinimumAgeClass}`}
                id=" minimumAge"
                name=" minimumAge"
                type="number"
                autoComplete="off"
                value={minimumAge}
                onChange={onMinimumAgeChanged}
                onFocus={(e) => {
                  if (e.target.value === " Minimum Age") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = " Minimum Age";
                  }
                }}
                placeholder="Minimum Age"
              />
            </div>
            <div
              className="newVehicleFormFinancialDetailisColumn"
              style={{ marginTop: "20px" }}
            >
              <label
                className="form__label--visually-hidden form__checkbox-container "
                htmlFor="owner"
              >
                ASSIGNED TO:
              </label>
              <select
                id="owner"
                name="owner"
                className={`form__input__vehicle ${validOwnerClass}`}
                value={owner}
                onChange={onOwnerChanged}
                disabled
              >
                {options}
              </select>
              <label className="form__label--visually-hidden" htmlFor="status">
                Vehicle Status
              </label>
              <p className="newVehicleFormCheckboxPTag">Vehicle Status</p>
              <select
                className={`form__input__vehicle ${validStatusClass}`}
                name="status"
                value={status}
                onChange={onStatusChanged}
              >
                {vehicleStatusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label
                className="form__label--visually-hidden"
                htmlFor="available"
              >
                Vehicle Availability
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for hire?
              </p>
              <input
                className={`form__input__vehicle ${validAvailableClass}`}
                id="available"
                name="available"
                type="checkbox"
                checked={available}
                onChange={onAvailableChanged}
              />
            </div>
          </div>
          <div className="newVehicleFormDeliveryDetails">
            <div className="newVehicleFormDeliveryDetailsColumn">
              {" "}
              <p>Delivery Details</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="delivery"
              >
                Accept Home Delivery
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for home Delivery?
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="acceptHomeDelivery"
                name="acceptHomeDelivery"
                type="checkbox"
                checked={delivery.acceptHomeDelivery}
                onChange={onDeliveryChanged}
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="delivery"
              >
                Accept Dealership Pick Up
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for to pick up at Dealership?
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="acceptPickUpFromDealership"
                name="acceptPickUpFromDealership"
                type="checkbox"
                checked={delivery.acceptPickUpFromDealership}
                onChange={onDeliveryChanged}
              />
            </div>
            <div className="newVehicleFormDeliveryDetailsColumn">
              <label
                className="form__label--visually-hidden"
                htmlFor="Home Delivery Max Mileage"
              >
                Price Per Day
              </label>
              <p className="maxDeliveryMileage">
                Home Delivery Maximum Distance (miles){" "}
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="homeDeliveryMaximumMileage"
                name="homeDeliveryMaximumMileage"
                type="number"
                autoComplete="off"
                value={delivery.homeDeliveryMaximumMileage}
                onChange={onDeliveryChanged}
                onFocus={(e) => {
                  if (e.target.value === "Max Distance") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Max Distance";
                  }
                }}
                placeholder="Max Distance"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="Home Delivery Max Mileage"
              >
                Price Per Day
              </label>
              <p className="deliveryCostPerMile">
                Home Delivery Charge Per Mile
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="homeDeliveryChargePricePerMile"
                name="homeDeliveryChargePricePerMile"
                type="number"
                autoComplete="off"
                value={delivery.homeDeliveryChargePricePerMile}
                onChange={onDeliveryChanged}
                onFocus={(e) => {
                  if (e.target.value === "Price Per Mile") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Price Per Mile";
                  }
                }}
                placeholder="Price Per Mile"
              />
            </div>
          </div>
          <div className="newVehicleFormAvailabilityDetailis">
            <div className="newVehicleFormAvailabilityDetailisColumn">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="What times can the vehicle be collected?"
              >
                Vehicle Start Times
              </p>
              {/* Start Times */}
              {timeOptions.map((timeOption) => (
                <div key={timeOption}>
                  <input
                    type="checkbox"
                    name="startTimes"
                    value={timeOption}
                    checked={availability.startTimes.includes(timeOption)}
                    onChange={onAvailabilityChanged}
                  />
                  <label className="vehicleStartTimesLabel">{timeOption}</label>
                </div>
              ))}
            </div>
            <div className="newVehicleFormAvailabilityDetailisColumn">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="What times can the vehicle be Returned?"
              >
                Vehicle Drop Off Times
              </p>
              {/* End Times */}
              {timeOptions.map((timeOption) => (
                <div key={timeOption}>
                  <input
                    type="checkbox"
                    name="endTimes"
                    value={timeOption}
                    checked={availability.endTimes.includes(timeOption)}
                    onChange={onAvailabilityChanged}
                  />
                  <label className="vehicleStartTimesLabel">{timeOption}</label>
                </div>
              ))}
            </div>
            <div className="newVehicleFormAvailabilityDetailisColumn">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="What Days Can The Vehicle Be Either Collected or Returned/Dealership Business Days"
              >
                Vehicle Availability Days
              </p>
              <Tooltip
                id="infoTooltip"
                place="top"
                type="dark"
                effect="float"
                className="custom_tooltip--VehicleForms"
              />
              {/* Days of the Week */}
              {daysOfWeekOptions.map((dayOption) => (
                <div key={dayOption}>
                  <input
                    type="checkbox"
                    name="daysOfWeekDealershipOpenForThisVehicle"
                    value={dayOption}
                    checked={availability.daysOfWeekDealershipOpenForThisVehicle.includes(
                      dayOption
                    )}
                    onChange={onAvailabilityChanged}
                  />
                  <label className="vehicleStartTimesLabel">{dayOption}</label>
                </div>
              ))}
              <div className="newVehicleFormSameDayReturnAndStart">
                <h4>Same Day Return and Pick Up</h4>
                <input
                  type="checkbox"
                  name="acceptSameDayReturnAndStart"
                  checked={sameDayReturnAndStart.acceptSameDayReturnAndStart}
                  onChange={onSameDayReturnAndStartChange}
                  className="newVehicleFormSameDayReturnAndStartCheck"
                />
                <label
                  className="vehicleStartTimesLabel"
                  data-tooltip-id="infoTooltip"
                  data-tooltip-content="If a Booking is Returned During the Day, Can a New Booking Start the Same Day"
                >
                  Accept Same Day Return and Start
                </label>
                <p
                  data-tooltip-id="infoTooltip"
                  data-tooltip-content="This will only apply if above chekcbox is ticked"
                >
                  Minimum Time Gap Between Return of Vehicle and New Booking
                  [Hours]
                </p>
                <input
                  type="number"
                  name="minGapBetweenBookings"
                  value={sameDayReturnAndStart.minGapBetweenBookings}
                  onChange={onSameDayReturnAndStartChange}
                  className={`form__input__vehicle ${validAvailableClass}`}
                />
              </div>
            </div>
          </div>

          <div className="newVehicleFormNotes">
            <p>Notes</p>
            <div className="newVehicleFormNotesInput">
              <label className="form__label--visually-hidden" htmlFor="notes">
                Vehicle Notes
              </label>
              {/* <input
                className={`form__input__vehicle__notes ${validNotesClass}`}
                id="notes"
                name="notes"
                type="text"
                autoComplete="off"
                value={notes}
                onChange={onNotesChanged}
                onFocus={(e) => {
                  if (e.target.value === "Notes") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Notes";
                  }
                }}
              /> */}
              <textarea
                className={`form__input__vehicle__notes ${validNotesClass}`}
                id="notes"
                name="notes"
                autoComplete="off"
                value={notes}
                onChange={onNotesChanged}
                placeholder="Notes"
                onFocus={(e) => {
                  if (e.target.value === "Notes") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Notes";
                  }
                }}
                rows="4" // <!-- This defines the height of the textarea. Adjust the value as needed. -->
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </>
  );
  return content;
};

export default NewVehicleForm;
