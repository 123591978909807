// import { Outlet } from "react-router-dom";
// import DashSideBarCustomer from "./DashSideBarCustomer";

// const DashboardLayoutCustomer = () => {
//   return (
//     <>
//       <DashSideBarCustomer />
//       <div className="dashboard-container">
//         <Outlet />
//       </div>
//     </>
//   );
// };
// export default DashboardLayoutCustomer;

import { useLocation, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import DashSideBarCustomer from "./DashSideBarCustomer";

const DashboardLayoutCustomer = () => {
  const { bookingId } = useParams();
  const location = useLocation();

  const isBookingPage =
    location.pathname === `/customerdashboard/myOrders/${bookingId}`;

  // console.log(isBookingPage);

  return (
    <>
      {!isBookingPage && <DashSideBarCustomer />}{" "}
      {/* Render sidebar only if not on booking page */}
      <div className="dashboard-container">
        <Outlet />
      </div>
    </>
  );
};

export default DashboardLayoutCustomer;
