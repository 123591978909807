import useAuth from "../hooks/useAuth";

import { NavLink, Link } from "react-router-dom";

import { FaAngleRight, FaCogs } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";

const DashSideBarCustomer = () => {
  const { firstname, lastname } = useAuth();

  const content = (
    <div className="dashSideBarCustomer">
      <div className="dashSideBarCustomerAccountDivForSeperation">
        <Link to="/customerdashboard/profile">
          <div className="dashSideBarAccountCustomer">
            <p>
              {firstname} {lastname}
            </p>
            <FaAngleRight className="angleRightIcon" />
          </div>
        </Link>
      </div>

      <div className="dashSideBarRestOfLinks">
        <div className="dashSideBarHomeCustomer">
          <NavLink
            to="/customerdashboard"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            end
          >
            <FaHome className="home" size={22} />
            Home
          </NavLink>
        </div>

        <div className="dashSideBarProfileCustomer">
          <NavLink
            to="/customerdashboard/profile"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaUserEdit className="profileEdit" size={22} />
            Profile
          </NavLink>
        </div>

        <div className="dashSideBarOrdersCustomer">
          <NavLink
            to="/customerdashboard/myOrders"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaShoppingCart className="orders" size={22} />
            My Orders
          </NavLink>
        </div>

        <div className="dashSideBarOrdersCustomer">
          <NavLink
            to="/customerdashboard/myRequests"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaCogs className="orders" size={22} />
            My Requests
          </NavLink>
        </div>

        <div className="dashSideBarRemindersCustomer">
          <NavLink
            to="/customerdashboard/reminders"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaMailBulk className="reminders" size={22} />
            Reminders
          </NavLink>
        </div>

        <div className="dashSideBarSupportCustomer">
          <NavLink
            to="/customerdashboard/support"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaRegQuestionCircle className="support" size={22} />
            Support
          </NavLink>
        </div>
      </div>
    </div>
  );

  return content;
};

export default DashSideBarCustomer;
