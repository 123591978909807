import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const vehiclesAdapter = createEntityAdapter({});

const initialState = vehiclesAdapter.getInitialState();

export const vehiclesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query({
      query: () => ({
        url: "/vehicles",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedVehicles = responseData.map((vehicle) => {
          vehicle.id = vehicle._id;
          return vehicle;
        });
        return vehiclesAdapter.setAll(initialState, loadedVehicles);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Vehicle", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Vehicle", id })),
          ];
        } else return [{ type: "Vehicle", id: "LIST" }];
      },
    }),
    getAvailableVehicles: builder.query({
      query: ({
        startDate,
        endDate,
        startTime,
        endTime,
        makeOption,
        modelOption,
        modelVariantOption,
        postcode,
        distanceRangeOption,
        priceRangeOption,
      }) => ({
        url: `/vehicles/availability`,
        method: "GET",
        params: {
          startDate,
          endDate,
          startTime,
          endTime,
          makeOption,
          modelOption,
          modelVariantOption,
          postcode,
          distanceRangeOption,
          priceRangeOption,
        },
      }),
      transformResponse: (responseData) => {
        const normalizedData = responseData.map((vehicle) => {
          // Map the response data to normalized format
          return {
            id: vehicle._id,
            // Add other properties as needed
            // For example:
            make: vehicle.make,
            model: vehicle.model,
            owner: vehicle.owner,
            // etc.
          };
        });
        return vehiclesAdapter.setAll(initialState, normalizedData);
      },
    }),
    // addNewVehicle: builder.mutation({
    //   query: (initialVehicle) => ({
    //     url: "/vehicles",
    //     method: "POST",
    //     body: {
    //       ...initialVehicle,
    //     },
    //   }),
    //   invalidatesTags: [{ type: "Vehicle", id: "LIST" }],
    // }),
    addNewVehicle: builder.mutation({
      query: (initialVehicleData) => {
        const formData = new FormData();
        // Append form fields to FormData object
        formData.append("make", initialVehicleData.make);
        formData.append("model", initialVehicleData.model);
        formData.append("modelVariant", initialVehicleData.modelVariant);
        formData.append("details", initialVehicleData.details);
        formData.append("year", initialVehicleData.year);
        formData.append("pricePerDay", initialVehicleData.pricePerDay);
        formData.append("registration", initialVehicleData.registration);
        formData.append("drivetrain", initialVehicleData.drivetrain);
        formData.append("bodyType", initialVehicleData.bodyType);
        formData.append("doors", initialVehicleData.doors);
        formData.append("seats", initialVehicleData.seats);
        formData.append("power", initialVehicleData.power);
        formData.append("torque", initialVehicleData.torque);

        // Append multiple images
        initialVehicleData.images.forEach((image) => {
          formData.append("images", image);
        });

        formData.append(
          "address[houseNumberAndStreetName]",
          initialVehicleData.address.houseNumberAndStreetName
        );
        formData.append(
          "address[locality]",
          initialVehicleData.address.locality
        );
        formData.append(
          "address[cityOrTown]",
          initialVehicleData.address.cityOrTown
        );
        formData.append(
          "address[postcode]",
          initialVehicleData.address.postcode
        );
        formData.append("address[country]", initialVehicleData.address.country);

        formData.append(
          "delivery[acceptHomeDelivery]",
          initialVehicleData.delivery.acceptHomeDelivery
        );
        formData.append(
          "delivery[acceptPickUpFromDealership]",
          initialVehicleData.delivery.acceptPickUpFromDealership
        );
        formData.append(
          "delivery[homeDeliveryMaximumMileage]",
          initialVehicleData.delivery.homeDeliveryMaximumMileage
        );
        formData.append(
          "delivery[homeDeliveryChargePricePerMile]",
          initialVehicleData.delivery.homeDeliveryChargePricePerMile
        );

        formData.append("available", initialVehicleData.available);
        formData.append("owner", initialVehicleData.owner);
        formData.append("mileage", initialVehicleData.mileage);
        formData.append("status", initialVehicleData.status);
        formData.append("notes", initialVehicleData.notes);
        formData.append("colour", initialVehicleData.colour);
        formData.append("mileageLimit", initialVehicleData.mileageLimit);
        formData.append("deposit", initialVehicleData.deposit);
        formData.append(
          "additionalDriverPricePerDay",
          initialVehicleData.additionalDriverPricePerDay
        );
        formData.append(
          "chauffeurAvailable",
          initialVehicleData.chauffeurAvailable
        );
        formData.append(
          "chauffeurPricePerDay",
          initialVehicleData.chauffeurPricePerDay
        );
        formData.append("minimumAge", initialVehicleData.minimumAge);

        // formData.append(
        //   "availability[startTimes]",
        //   initialVehicleData.availability.startTimes
        // );
        // formData.append(
        //   "availability[endTimes]",
        //   initialVehicleData.availability.endTimes
        // );
        // formData.append(
        //   "availability[daysOfWeekDealershipOpenForThisVehicle]",
        //   initialVehicleData.availability.daysOfWeekDealershipOpenForThisVehicle
        // );

        // Stringify arrays before appending
        formData.append(
          "availability[startTimes]",
          JSON.stringify(initialVehicleData.availability.startTimes)
        );
        formData.append(
          "availability[endTimes]",
          JSON.stringify(initialVehicleData.availability.endTimes)
        );
        formData.append(
          "availability[daysOfWeekDealershipOpenForThisVehicle]",
          JSON.stringify(
            initialVehicleData.availability
              .daysOfWeekDealershipOpenForThisVehicle
          )
        );

        formData.append(
          "sameDayReturnAndStart[acceptSameDayReturnAndStart]",
          initialVehicleData.sameDayReturnAndStart.acceptSameDayReturnAndStart
        );
        formData.append(
          "sameDayReturnAndStart[minGapBetweenBookings]",
          initialVehicleData.sameDayReturnAndStart.minGapBetweenBookings
        );

        return {
          url: "/vehicles",
          method: "POST",
          body: formData, // Use FormData object as the body
        };
      },
      invalidatesTags: [{ type: "Vehicle", id: "LIST" }],
    }),

    // updateVehicle: builder.mutation({
    //   query: (initialVehicle) => ({
    //     url: "/vehicles",
    //     method: "PATCH",
    //     body: {
    //       ...initialVehicle,
    //     },
    //   }),
    //   invalidatesTags: (result, error, arg) => [
    //     { type: "Vehicle", id: arg.id },
    //   ],
    // }),
    updateVehicle: builder.mutation({
      query: (initialVehicle) => {
        const formData = new FormData();
        // Append form fields to FormData object
        formData.append("id", initialVehicle.id);
        formData.append("make", initialVehicle.make);
        formData.append("model", initialVehicle.model);
        formData.append("modelVariant", initialVehicle.modelVariant);
        formData.append("details", initialVehicle.details);
        formData.append("year", initialVehicle.year);
        formData.append("pricePerDay", initialVehicle.pricePerDay);
        formData.append("registration", initialVehicle.registration);
        formData.append("drivetrain", initialVehicle.drivetrain);
        formData.append("bodyType", initialVehicle.bodyType);
        formData.append("doors", initialVehicle.doors);
        formData.append("seats", initialVehicle.seats);
        formData.append("power", initialVehicle.power);
        formData.append("torque", initialVehicle.torque);

        // Append multiple images
        initialVehicle.images.forEach((image) => {
          formData.append("images", image);
        });

        formData.append(
          "address[houseNumberAndStreetName]",
          initialVehicle.address.houseNumberAndStreetName
        );
        formData.append("address[locality]", initialVehicle.address.locality);
        formData.append(
          "address[cityOrTown]",
          initialVehicle.address.cityOrTown
        );
        formData.append("address[postcode]", initialVehicle.address.postcode);
        formData.append("address[country]", initialVehicle.address.country);

        formData.append("available", initialVehicle.available);
        formData.append("owner", initialVehicle.owner);
        formData.append("mileage", initialVehicle.mileage);
        formData.append("status", initialVehicle.status);
        formData.append("notes", initialVehicle.notes);
        formData.append("colour", initialVehicle.colour);
        formData.append("mileageLimit", initialVehicle.mileageLimit);
        formData.append("deposit", initialVehicle.deposit);
        formData.append(
          "additionalDriverPricePerDay",
          initialVehicle.additionalDriverPricePerDay
        );
        formData.append(
          "chauffeurAvailable",
          initialVehicle.chauffeurAvailable
        );
        formData.append(
          "chauffeurPricePerDay",
          initialVehicle.chauffeurPricePerDay
        );

        formData.append(
          "delivery[acceptHomeDelivery]",
          initialVehicle.delivery.acceptHomeDelivery
        );
        formData.append(
          "delivery[acceptPickUpFromDealership]",
          initialVehicle.delivery.acceptPickUpFromDealership
        );
        formData.append(
          "delivery[homeDeliveryMaximumMileage]",
          initialVehicle.delivery.homeDeliveryMaximumMileage
        );
        formData.append(
          "delivery[homeDeliveryChargePricePerMile]",
          initialVehicle.delivery.homeDeliveryChargePricePerMile
        );

        formData.append("minimumAge", initialVehicle.minimumAge);

        // formData.append(
        //   "availability[startTimes]",
        //   initialVehicle.availability.startTimes
        // );
        // formData.append(
        //   "availability[endTimes]",
        //   initialVehicle.availability.endTimes
        // );
        // formData.append(
        //   "availability[daysOfWeekDealershipOpenForThisVehicle]",
        //   initialVehicle.availability.daysOfWeekDealershipOpenForThisVehicle
        // );

        // Stringify arrays before appending
        formData.append(
          "availability[startTimes]",
          JSON.stringify(initialVehicle.availability.startTimes)
        );
        formData.append(
          "availability[endTimes]",
          JSON.stringify(initialVehicle.availability.endTimes)
        );
        formData.append(
          "availability[daysOfWeekDealershipOpenForThisVehicle]",
          JSON.stringify(
            initialVehicle.availability.daysOfWeekDealershipOpenForThisVehicle
          )
        );

        formData.append(
          "sameDayReturnAndStart[acceptSameDayReturnAndStart]",
          initialVehicle.sameDayReturnAndStart.acceptSameDayReturnAndStart
        );
        formData.append(
          "sameDayReturnAndStart[minGapBetweenBookings]",
          initialVehicle.sameDayReturnAndStart.minGapBetweenBookings
        );

        return {
          url: "/vehicles",
          method: "PATCH",
          body: formData, // Use FormData object as the body
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Vehicle", id: arg.id },
      ],
    }),
    deleteVehicle: builder.mutation({
      query: ({ id }) => ({
        url: "/vehicles",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Vehicle", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetAvailableVehiclesQuery,
  useAddNewVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,
} = vehiclesApiSlice;

// returns the query result object
export const selectVehiclesResult =
  vehiclesApiSlice.endpoints.getVehicles.select();

// creates memoized selector
const selectVehiclesData = createSelector(
  selectVehiclesResult,
  (vehiclesResult) => vehiclesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllVehicles,
  selectById: selectVehicleById,
  selectIds: selectVehicleIds,
  // Pass in a selector that returns the vehicles slice of state
} = vehiclesAdapter.getSelectors(
  (state) => selectVehiclesData(state) ?? initialState
);
