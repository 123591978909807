import { useParams } from "react-router-dom";
import VehicleDetailsPage from "./VehicleDetailsPage";
import { useGetVehiclesQuery } from "./vehiclesApiSlice";
import { HashLoader } from "react-spinners";

const VehicleDetails = () => {
  const { vehicleId } = useParams();

  const { vehicle } = useGetVehiclesQuery("vehiclesList", {
    selectFromResult: ({ data }) => ({
      vehicle: data?.entities[vehicleId],
    }),
  });

  //   const dealershipId = vehicle.owner;

  //   console.log(dealershipId);

  //   const { dealership } = useGetUsersQuery("usersList", {
  //     selectFromResult: ({ data }) => ({
  //       dealership: data?.entities[dealershipId],
  //     }),
  //   });

  if (!vehicle) return <HashLoader color="#36d7b7" />;

  if (vehicle) return <VehicleDetailsPage vehicle={vehicle} />;
};

export default VehicleDetails;
