import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  BarChart,
  Bar,
} from "recharts";

import { HashLoader } from "react-spinners";

import { useState, useEffect } from "react";

const SalesBarChart = ({
  filteredBookings,
  isLoading,
  isSuccess,
  isError,
  error,
}) => {
  const [amountData, setAmountData] = useState([]);

  // useEffect(() => {
  //   // Calculate amount generated per day for the past 7 days using filteredBookings
  //   const past7Days = Array.from({ length: 7 }, (_, i) => {
  //     const date = new Date();
  //     date.setDate(date.getDate() - i);
  //     return {
  //       date: date.toLocaleDateString("en-US"),
  //       dayName: date.toLocaleDateString("en-US", { weekday: "long" }),
  //     };
  //   }).reverse();

  //   const amountByDay = {};
  //   filteredBookings.forEach((booking) => {
  //     const bookingDate = new Date(booking.dateOfBooking); // Assume dateOfBooking holds the date

  //     const formattedDate = bookingDate.toLocaleDateString("en-US");

  //     // Use the formatted date consistently for comparison
  //     amountByDay[formattedDate] =
  //       (amountByDay[formattedDate] || 0) + booking.totalPrice; // Add price for each day
  //   });

  //   const formattedAmountData = past7Days.map(({ date, dayName }) => ({
  //     name: dayName,
  //     value: amountByDay[date] || 0, // Handle days with no bookings
  //   }));

  //   setAmountData(formattedAmountData);
  // }, [filteredBookings]); // Trigger the calculation when filteredBookings change

  useEffect(() => {
    // Calculate the dates for the past 7 days
    const past7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return {
        date: date.toLocaleDateString("en-US"),
        dayName: date.toLocaleDateString("en-US", { weekday: "long" }),
      };
    }).reverse();

    const amountByDay = {}; // Initialize object to store revenue by day

    // Process each booking to accumulate revenue for the respective day
    filteredBookings.forEach((booking) => {
      const bookingDate = new Date(booking.dateOfBooking); // Booking's date
      const formattedDate = bookingDate.toLocaleDateString("en-US"); // Formatted date for consistency

      const revenueWithoutDeposit =
        booking.totalPrice - booking.vehicleDetails.deposit; // Subtract deposit

      // Accumulate revenue for the specific day
      amountByDay[formattedDate] =
        (amountByDay[formattedDate] || 0) + revenueWithoutDeposit; // Add to existing or initialize
    });

    // Create the data structure to be used in the bar chart
    const formattedAmountData = past7Days.map(({ date, dayName }) => ({
      name: dayName,
      value: amountByDay[date] || 0, // Set value to 0 if no bookings for the day
    }));

    // Update state with the newly calculated data
    setAmountData(formattedAmountData);
  }, [filteredBookings]); // Recalculate when filteredBookings change

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="dashboardDealershipAnalytics2BarChartResponsiveContainer">
        <BarChart width={230} height={340} data={amountData}>
          <XAxis
            dataKey="name"
            tick={(props) => (
              <text
                x={props.x}
                y={props.y}
                dy={15}
                textAnchor="middle"
                fill="#ADB1B2"
                fontSize={16}
                fontFamily="EB Garamond"
                fontWeight={600}
                style={{
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  marginTop: 10,
                }}
              >
                {props.payload.value}
              </text>
            )}
          />
          <YAxis
            width={60}
            tick={(props) => (
              <text
                x={props.x - 22}
                y={props.y}
                dy={props.dy}
                textAnchor="middle"
                fill="#ADB1B2"
                fontSize={16}
                fontFamily="EB Garamond"
                fontWeight={600}
                style={{
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  marginTop: 10,
                }}
              >
                {props.payload.value}
              </text>
            )}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="value"
            fill="#0561FC"
            radius={[8, 8, 8, 8]}
            barSize={18}
          />
        </BarChart>
      </div>
    );
  }

  return content;
};

export default SalesBarChart;
