import { useGetBookingsQuery } from "../../bookings/bookingsApiSlice";
import { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";

import TimeLine from "./TimeLine";

import DealerVehicleList from "./DealerVehicleList";

const VehicleTimeLine = () => {
  const { email } = useAuth();

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [filteredBookings, setFilteredBookings] = useState([]);

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking.vehicleOwner.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <>
        <TimeLine
          filteredBookings={filteredBookings}
          isSuccess={isSuccess}
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
        <h2 className="vehicleTimeLineH2">Individual Timelines</h2>
        <div className="myVehiclesListDashboard">
          <DealerVehicleList />
        </div>
      </>
    );
  }

  return content;
};

export default VehicleTimeLine;
