import { useParams } from "react-router-dom";
import { useGetBookingsQuery } from "../../bookings/bookingsApiSlice";
import { HashLoader } from "react-spinners";
import { useState, useEffect } from "react";
import { FaRandom, FaSearch, FaInfo } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const CustomerOrders = () => {
  // Access the id parameter from the URL
  const { id } = useParams();

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [filteredBookings, setFilteredBookings] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  useEffect(() => {
    if (isSuccess && bookings) {
      // Filter bookings where mainCustomer.customer matches the id
      const customerBookings = Object.values(bookings.entities).filter(
        (booking) => booking.mainCustomer.customer === id
      );

      setFilteredBookings(customerBookings);
    }
  }, [isSuccess, bookings, id]);

  // console.log(filteredBookings);

  // Function to calculate the total spent
  // const calculateTotalSpent = () => {
  //   if (filteredBookings.length > 0) {
  //     // Use reduce to sum up the totalPrice values
  //     return filteredBookings.reduce(
  //       (total, booking) => total + booking.totalPrice,
  //       0
  //     );
  //   } else {
  //     return 0;
  //   }
  // };

  const calculateTotalSpent = () => {
    if (filteredBookings.length > 0) {
      // Sum up the totalPrice minus deposit for each booking
      return filteredBookings.reduce(
        (total, booking) =>
          total + (booking.totalPrice - booking.vehicleDetails.deposit), // Subtract deposit
        0
      );
    } else {
      return 0; // Return 0 if there are no bookings
    }
  };

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="customerOrders">
        <h3>
          Customer -{" "}
          {filteredBookings.length > 0
            ? `${filteredBookings[0].customer.firstname} ${filteredBookings[0].customer.lastname}`
            : "N/A"}
        </h3>
        <div className="customerOrdersTable">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Item</th>
                <th>Duration</th>
                <th>Fulfilment Status</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings
                .filter((booking) => {
                  const searchTerms = searchQuery.toLowerCase().split(" ");
                  return (
                    searchTerms.length === 0 ||
                    searchTerms.some(
                      (term) =>
                        booking.id.toLowerCase().includes(term) ||
                        booking.dateOfBooking.toLowerCase().includes(term) ||
                        booking.vehicleDetails.make
                          .toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails.model
                          .toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails.modelVariant
                          .toLowerCase()
                          .includes(term) ||
                        booking.customer.firstname
                          .toLowerCase()
                          .includes(term) ||
                        booking.customer.lastname
                          .toLowerCase()
                          .includes(term) ||
                        booking.bookingStatus.toLowerCase().includes(term) ||
                        booking.totalPrice.toString().includes(term)
                    )
                  );
                })
                .sort((a, b) => {
                  switch (selectedFilter) {
                    case "newest":
                      return (
                        new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                      );
                    case "oldest":
                      return (
                        new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                      );
                    case "mostExpensive":
                      return b.totalPrice - a.totalPrice;
                    case "leastExpensive":
                      return a.totalPrice - b.totalPrice;
                    default:
                      return 0;
                  }
                })
                .map((booking) => (
                  <tr key={booking.id}>
                    <td className="customerOrdersId noStyle">
                      <Link to={`/bookings/${booking.id}`}>{booking.id}</Link>
                    </td>
                    <td>{booking.dateOfBooking}</td>
                    <td>
                      {booking.vehicleDetails.make}{" "}
                      {booking.vehicleDetails.model}{" "}
                      {booking.vehicleDetails.modelVariant}
                    </td>
                    <td>
                      {new Date(booking.startDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}
                      -
                      {new Date(booking.endDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}{" "}
                      (
                      {Math.max(
                        0,
                        Math.ceil(
                          (new Date(booking.endDate) -
                            new Date(booking.startDate)) /
                            (1000 * 3600 * 24)
                        )
                      )}
                      days)
                    </td>
                    <td>{booking.bookingStatus}</td>
                    <td>£ {booking.totalPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <footer>
            <div className="dashboardMyCustomersFooter"></div>
            <div className="dashboardMyCustomersFooter2">
              <div className="dashboardMyCustomersFooter2Info">
                {" "}
                <span
                  data-tooltip-id="infoTooltip"
                  data-tooltip-content="This Value Excludes Deposit."
                >
                  <FaInfo className="infoIcon" />
                </span>{" "}
              </div>
              Total Spent - <span>£{calculateTotalSpent()}</span>
            </div>
            {/* Tooltip configuration and rendering */}
            <Tooltip id="infoTooltip" place="top" type="dark" effect="float" />
          </footer>
        </div>
      </div>
    );
  }
  return content;
};

export default CustomerOrders;
