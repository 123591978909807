import { useState, useEffect, useMemo } from "react";
import {
  useUpdateBookingMutation,
  useDeleteBookingMutation,
} from "./bookingsApiSlice";
import { useNavigate } from "react-router-dom";

function formatDate(dateString) {
  if (!dateString) {
    return ""; // Or another default value, depending on your use case
  }

  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return ""; // Or another default value
  }

  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
}

const EditBookingForm = ({ booking, users, vehicles }) => {
  const [updateBooking, { isLoading, isSuccess, isError, error }] =
    useUpdateBookingMutation();

  const [
    deleteBooking,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteBookingMutation();

  const navigate = useNavigate();

  const [vehicle, setVehicle] = useState(booking.vehicle);
  const [dealership, setDealership] = useState(booking.dealership);
  const [pickUpLocation, setPickUpLocation] = useState(
    booking?.pickUpLocation || ""
  );
  const [dropOffLocation, setDropOffLocation] = useState(
    booking?.dropOffLocation || ""
  );

  const [pickUpLocationInDepth, setPickUpLocationInDepth] = useState({
    type: booking.pickUpLocationInDepth?.type || "", // Use existing type or default to empty string
    address: {
      line1: booking.pickUpLocationInDepth?.address?.line1 || "", // Use existing address values or default to empty string
      line2: booking.pickUpLocationInDepth?.address?.line2 || "",
      city: booking.pickUpLocationInDepth?.address?.city || "",
      county: booking.pickUpLocationInDepth?.address?.county || "",
      postCode: booking.pickUpLocationInDepth?.address?.postCode || "",
    },
  });

  const [dropOffLocationInDepth, setDropOffLocationInDepth] = useState({
    type: booking.dropOffLocationInDepth?.type || "", // Use existing type or default to empty string
    address: {
      line1: booking.dropOffLocationInDepth?.address?.line1 || "", // Use existing address values or default to empty string
      line2: booking.dropOffLocationInDepth?.address?.line2 || "",
      city: booking.dropOffLocationInDepth?.address?.city || "",
      county: booking.dropOffLocationInDepth?.address?.county || "",
      postCode: booking.dropOffLocationInDepth?.address?.postCode || "",
    },
  });

  // const [startDate, setStartDate] = useState(booking.startDate);
  // const [endDate, setEndDate] = useState(booking.endDate);

  const formatDateForInput = (date) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0]; // Extract 'YYYY-MM-DD' portion
  };

  const [startDate, setStartDate] = useState(
    formatDateForInput(booking.startDate)
  );
  const [endDate, setEndDate] = useState(formatDateForInput(booking.endDate));

  const [dateOfBooking, setDateOfBooking] = useState(booking.dateOfBooking);
  const [orderNumber, setOrderNumber] = useState(booking.orderNumber);

  const [totalPrice, setTotalPrice] = useState(booking.totalPrice);
  const [chauffeur, setChauffeur] = useState(booking.chauffeur);
  const [description, setDescription] = useState(booking.description);
  const [mainCustomer, setMainCustomer] = useState({
    customer: booking.mainCustomer.customer,
    fullname: booking?.mainCustomer?.fullname || "",
    drivingLicense: {
      number: booking.mainCustomer.drivingLicense.number,
      dateOfIssue: booking.mainCustomer.drivingLicense.dateOfIssue,
      dateOfExpiry: booking.mainCustomer.drivingLicense.dateOfExpiry,
      placeOfIssue: booking.mainCustomer.drivingLicense.placeOfIssue,
    },
    passport: {
      number: booking.mainCustomer.passport.number,
      dateOfIssue: booking.mainCustomer.passport.dateOfIssue,
      dateOfExpiry: booking.mainCustomer.passport.dateOfExpiry,
    },
    dateOfBirth: booking.mainCustomer.dateOfBirth,
    address: {
      line1: booking.mainCustomer.address.line1,
      line2: booking.mainCustomer.address.line2,
      city: booking.mainCustomer.address.city,
      county: booking.mainCustomer.address.county,
      postCode: booking.mainCustomer.address.postCode,
    },
    connections: {
      email: booking.mainCustomer.connections.email,
      number: booking.mainCustomer.connections.number,
    },
  });
  //   const [additionalDriver, setAdditionalDriver] = useState({
  //     customer: booking.additionalDriver.customer,
  //     drivingLicense: {
  //       number: booking.additionalDriver.drivingLicense.number,
  //       dateOfIssue: booking.additionalDriver.drivingLicense.dateOfIssue,
  //       dateOfExpiry: booking.additionalDriver.drivingLicense.dateOfExpiry,
  //       placeOfIssue: booking.additionalDriver.drivingLicense.placeOfIssue,
  //     },
  //     passport: {
  //       number: booking.additionalDriver.passport.number,
  //       dateOfIssue: booking.additionalDriver.passport.dateOfIssue,
  //       dateOfExpiry: booking.additionalDriver.passport.dateOfExpiry,
  //     },
  //     dateOfBirth: booking.additionalDriver.dateOfBirth,
  //     address: {
  //       line1: booking.additionalDriver.address.line1,
  //       line2: booking.additionalDriver.address.line2,
  //       city: booking.additionalDriver.address.city,
  //       county: booking.additionalDriver.address.county,
  //       postCode: booking.additionalDriver.address.postCode,
  //     },
  //   });

  const [additionalDriver, setAdditionalDriver] = useState({
    customer: booking.additionalDriver?.customer || "",
    drivingLicense: {
      number: booking.additionalDriver?.drivingLicense?.number || "",
      dateOfIssue: booking.additionalDriver?.drivingLicense?.dateOfIssue || "",
      dateOfExpiry:
        booking.additionalDriver?.drivingLicense?.dateOfExpiry || "",
      placeOfIssue:
        booking.additionalDriver?.drivingLicense?.placeOfIssue || "",
    },
    passport: {
      number: booking.additionalDriver?.passport?.number || "",
      dateOfIssue: booking.additionalDriver?.passport?.dateOfIssue || "",
      dateOfExpiry: booking.additionalDriver?.passport?.dateOfExpiry || "",
    },
    dateOfBirth: booking.additionalDriver?.dateOfBirth || "",
    address: {
      line1: booking.additionalDriver?.address?.line1 || "",
      line2: booking.additionalDriver?.address?.line2 || "",
      city: booking.additionalDriver?.address?.city || "",
      county: booking.additionalDriver?.address?.county || "",
      postCode: booking.additionalDriver?.address?.postCode || "",
    },
    connections: {
      email: booking.additionalDriver.connections.email,
      number: booking.additionalDriver.connections.number,
    },
  });

  const [bookingStatus, setBookingStatus] = useState(booking.bookingStatus);
  const [complete, setComplete] = useState(booking.complete);
  const [insuranceDetails, setInsuranceDetails] = useState({
    insuranceCompany: booking.insuranceDetails
      ? booking.insuranceDetails.insuranceCompany
      : "",
    policyNumber: booking.insuranceDetails
      ? booking.insuranceDetails.policyNumber
      : "",
    validFrom: booking.insuranceDetails
      ? booking.insuranceDetails.validFrom
      : "",
    validUntil: booking.insuranceDetails
      ? booking.insuranceDetails.validUntil
      : "",
  });

  const [beforeImages, setBeforeImages] = useState(
    booking.beforeImages ? [booking.beforeImages] : []
  );
  const [afterImages, setAfterImages] = useState(
    booking.afterImages ? [booking.afterImages] : []
  );

  // console.log(additionalDriver.customer);

  // const [vehicleHomeDelivery, setVehicleHomeDelivery] = useState(
  //   booking?.vehicleDetails?.delivery?.acceptHomeDelivery
  // );

  const [selectedVehicle, setSelectedVehicle] = useState(
    booking?.vehicleDetails?._id
  );

  const [vehicleAvailability, setVehicleAvailability] = useState(
    booking?.vehicleDetails?.availability
  );

  const [startTime, setStartTime] = useState(booking?.startTime);
  const [endTime, setEndTime] = useState(booking?.endTime);
  const [chauffeurFee, setChauffeurFee] = useState(booking?.chauffeurFee ?? 0);
  const [additionalDriverFee, setAdditionalDriverFee] = useState(
    booking?.additionalDriverFee ?? 0
  );
  const [deliveryFee, setDeliveryFee] = useState(booking?.deliveryFee ?? 0);
  const [chauffeurDetails, setChauffeurDetails] = useState(
    booking?.chauffeurDetails || ""
  );

  const [bookingProgress, setBookingProgress] = useState(
    booking?.bookingProgress || ""
  );

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setVehicle("");
      setDealership("");
      setPickUpLocation("");
      setDropOffLocation("");
      setSelectedVehicle("");
      setChauffeurFee(0);
      setAdditionalDriverFee(0);
      setDeliveryFee(0);
      setBookingProgress("");
      setVehicleAvailability("");
      setChauffeurDetails("");
      setPickUpLocationInDepth({
        type: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
      });
      setDropOffLocationInDepth({
        type: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
      });
      setStartDate("");
      setEndDate("");
      setDateOfBooking("");
      setOrderNumber("");
      setTotalPrice("");
      setChauffeur(false);
      setDescription("");
      setMainCustomer({
        customer: "",
        fullname: "",
        drivingLicense: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
          placeOfIssue: "",
        },
        passport: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
        },
        dateOfBirth: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
        connections: {
          email: "",
          number: "",
        },
      });
      setAdditionalDriver({
        customer: "",
        drivingLicense: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
          placeOfIssue: "",
        },
        passport: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
        },
        dateOfBirth: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
        connections: {
          email: "",
          number: "",
        },
      });
      setBookingStatus("");
      setComplete(false);
      setInsuranceDetails({
        insuranceCompany: "",
        policyNumber: "",
        validFrom: "",
        validUntil: "",
      });
      setBeforeImages([]);
      setAfterImages([]);

      navigate("/dashboard/myOrders"); // change to dashboard/bookings
    }
  }, [isSuccess, navigate, isDelSuccess]);

  const onVehicleChanged = (e) => setVehicle(e.target.value);
  const onDealershipChanged = (e) => setDealership(e.target.value);
  // const onPickUpLocationChanged = (e) => setPickUpLocation(e.target.value);
  // const onDropOffLocationChanged = (e) => setDropOffLocation(e.target.value);
  const onChauffeurDetailsChanged = (e) => setChauffeurDetails(e.target.value);
  const onBookingProgressChanged = (e) => setBookingProgress(e.target.value);

  // Handler for changing chauffeurFee
  const onChauffeurFeeChanged = (e) => {
    setChauffeurFee(e.target.value);
  };

  // Handler for changing additionalDriverFee
  const onAdditionalDriverFeeChanged = (e) => {
    setAdditionalDriverFee(e.target.value);
  };

  // Handler for changing deliveryFee
  const onDeliveryFeeChanged = (e) => {
    setDeliveryFee(e.target.value);
  };

  const handlePickUpLocationChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      // Update the type field directly
      setPickUpLocationInDepth((prevState) => ({
        ...prevState,
        type: value,
      }));
    } else {
      // Update the nested address fields
      setPickUpLocationInDepth((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  const handleDropOffLocationChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      // Update the type field directly
      setDropOffLocationInDepth((prevState) => ({
        ...prevState,
        type: value,
      }));
    } else {
      // Update the nested address fields
      setDropOffLocationInDepth((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  const onStartDateChanged = (e) => setStartDate(e.target.value);
  const onEndDateChanged = (e) => setEndDate(e.target.value);
  // const onDateOfBookingChanged = (e) => setDateOfBooking(e.target.value);
  const onStartTimeChanged = (e) => setStartTime(e.target.value);
  const onEndTimeChanged = (e) => setEndTime(e.target.value);
  const onOrderNumberChanged = (e) => setOrderNumber(e.target.value); // we need to generate this
  const onTotalPriceChanged = (e) => setTotalPrice(e.target.value);
  const onChauffeurChanged = (e) => setChauffeur(e.target.checked);
  const onDescriptionChanged = (e) => setDescription(e.target.value);
  // For mainCustomer
  // const onMainCustomerChanged = (e) => {
  //   const { name, value } = e.target;
  //   setMainCustomer((prevMainCustomer) => ({
  //     ...prevMainCustomer,
  //     [name]: value,
  //   }));
  // };
  const onMainCustomerChanged = (e) => {
    const { name, value } = e.target;

    // Split the name into parts based on the dot (.) to handle nested fields
    const nameParts = name.split(".");

    // Create a copy of the current mainCustomer object to modify
    let updatedMainCustomer = { ...mainCustomer };

    // Use a loop to navigate through the nested structure
    let currentLevel = updatedMainCustomer;
    for (let i = 0; i < nameParts.length; i++) {
      const part = nameParts[i];
      if (i === nameParts.length - 1) {
        // If it's the last part, update the value
        currentLevel[part] = value;
      } else {
        // Otherwise, navigate deeper into the nested structure
        currentLevel = currentLevel[part];
      }
    }

    // Update the mainCustomer state with the modified object
    setMainCustomer(updatedMainCustomer);
  };

  // For additionalDriver
  // const onAdditionalDriverChanged = (e) => {
  //   const { name, value } = e.target;
  //   setAdditionalDriver((prevAdditionalDriver) => ({
  //     ...prevAdditionalDriver,
  //     [name]: value,
  //   }));
  // };
  const onAdditionalDriverChanged = (e) => {
    const { name, value } = e.target;

    // Check if the name includes a dot before splitting
    const nameParts = name.includes(".") ? name.split(".") : [name];

    // Split the name into parts based on the dot (.) to handle nested fields
    // const nameParts = name.split(".");

    // Create a copy of the current additionalDriver object to modify
    let updatedAdditionalDriver = { ...additionalDriver };

    // Use a loop to navigate through the nested structure
    let currentLevel = updatedAdditionalDriver;
    for (let i = 0; i < nameParts.length; i++) {
      const part = nameParts[i];
      if (i === nameParts.length - 1) {
        // If it's the last part, update the value
        currentLevel[part] = value;
      } else {
        // Otherwise, navigate deeper into the nested structure
        currentLevel = currentLevel[part];
      }
    }
    // console.log("Updated additionalDriver:", updatedAdditionalDriver);

    // Update the additionalDriver state with the modified object
    setAdditionalDriver(updatedAdditionalDriver);
  };

  const onBookingStatusChanged = (e) => setBookingStatus(e.target.value);
  const onCompleteChanged = (e) => setComplete(e.target.checked); // this shouldnt be visible for customers on bookings only in the edit bookings

  // For insuranceDetails note that the insurance detail bit should not be visible to the public since they dont deal with the insurance
  const onInsuranceDetailsChanged = (e) => {
    const { name, value } = e.target;
    setInsuranceDetails((prevInsuranceDetails) => ({
      ...prevInsuranceDetails,
      [name]: value,
    }));
  };

  const onBeforeImagesChanged = (e) => setBeforeImages([e.target.value]);
  const onAfterImagesChanged = (e) => setAfterImages([e.target.value]);

  const canSave =
    [
      vehicle,
      dealership,
      // pickUpLocation,
      // dropOffLocation,
      pickUpLocationInDepth,
      dropOffLocationInDepth,
      startDate,
      endDate,
      dateOfBooking,
      orderNumber,
      totalPrice,
      chauffeur,
      mainCustomer,
      complete,
    ].every((item) => item !== null && item !== undefined) && !isLoading;

  // console.log(startTime);

  const onSaveBookingClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await updateBooking({
        vehicle,
        dealership,
        pickUpLocation,
        dropOffLocation,
        pickUpLocationInDepth,
        dropOffLocationInDepth,
        startDate,
        endDate,
        startTime,
        endTime,
        dateOfBooking,
        orderNumber,
        totalPrice,
        chauffeur,
        description,
        mainCustomer,
        additionalDriver,
        bookingStatus,
        complete,
        insuranceDetails,
        beforeImages: beforeImages.length > 0 ? beforeImages[0] : "",
        afterImages: afterImages.length > 0 ? afterImages[0] : "",
        id: booking._id,
        chauffeurFee,
        additionalDriverFee,
        deliveryFee,
        bookingProgress,
        chauffeurDetails,
      });
    }
  };

  // console.log(additionalDriverFee);

  const onDeleteBookingClicked = async () => {
    await deleteBooking({ id: booking._id });
  };

  const startTimeOptions = useMemo(() => {
    return vehicleAvailability ? vehicleAvailability.startTimes : [];
  }, [vehicleAvailability]);

  const endTimeOptions = useMemo(() => {
    return vehicleAvailability ? vehicleAvailability.endTimes : [];
  }, [vehicleAvailability]);

  useEffect(() => {
    // Only set startTime if it is not already set
    if (startTimeOptions.length > 0 && !startTime) {
      setStartTime(booking?.startTime || startTimeOptions[0]);
    }
    // Only set endTime if it is not already set
    if (endTimeOptions.length > 0 && !endTime) {
      setEndTime(booking?.endTime || endTimeOptions[0]);
    }
  }, [startTimeOptions, endTimeOptions, startTime, endTime, booking]);

  const userOptions = users.map((user) => {
    // use this for the dealerhipTest
    return (
      <option key={user.id} value={user.id}>
        {" "}
        {user.email}
      </option>
    );
  });

  const vehicleOptions = vehicles.map((vehicle) => {
    // might have to change this to vehicles
    return (
      <option key={vehicle.id} value={vehicle.id}>
        {" "}
        {vehicle.registration}
      </option>
    );
  });

  const bookingStatusOptions = [
    "Preparing Vehicle",
    "Vehicle Ready",
    "Vehicle Sent Out",
    "Awaiting Vehicle Return",
    "Vehicle Fufilled and Returned",
  ];

  const bookingProgressOptions = [
    "Rejected",
    "To Be Confirmed",
    "Accepted Payment Not Received",
    "Accepted Payment Received",
  ];

  const timeOptions = [
    "12:00am",
    "12:30am",
    "1:00am",
    "1:30am",
    "2:00am",
    "2:30am",
    "3:00am",
    "3:30am",
    "4:00am",
    "4:30am",
    "5:00am",
    "5:30am",
    "6:00am",
    "6:30am",
    "7:00am",
    "7:30am",
    "8:00am",
    "8:30am",
    "9:00am",
    "9:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "1:00pm",
    "1:30pm",
    "2:00pm",
    "2:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
    "4:30pm",
    "5:00pm",
    "5:30pm",
    "6:00pm",
    "6:30pm",
    "7:00pm",
    "7:30pm",
    "8:00pm",
    "8:30pm",
    "9:00pm",
    "9:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
  ];

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validVehicleClass = !vehicle ? "form__input--incomplete" : "";
  const validDealershipClass = !dealership ? "form__input--incomplete" : "";
  // const validDealershipTestClass = !dealershipTest
  //   ? "form__input--incomplete"
  //   : "";
  // const validPickUpLocationClass = !pickUpLocation
  //   ? "form__input--incomplete"
  //   : "";
  // const validDropOffLocationClass = !dropOffLocation
  //   ? "form__input--incomplete"
  //   : "";

  // Date Fields
  const validStartDateClass = !startDate ? "form__input--incomplete" : "";
  const validEndDateClass = !endDate ? "form__input--incomplete" : "";
  // const validDateOfBookingClass = !dateOfBooking
  //   ? "form__input--incomplete"
  //   : "";

  // Numeric Fields
  const validOrderNumberClass = !orderNumber ? "form__input--incomplete" : "";
  const validTotalPriceClass = !totalPrice ? "form__input--incomplete" : "";

  // Boolean Field
  const validChauffeurClass = chauffeur ? "" : "form__input--incomplete";

  // Description Field

  // console.log(complete);

  // Main Customer Fields
  const validMainCustomerClass = !mainCustomer.customer
    ? "form__input--incomplete"
    : "";
  const validMainCustomerFullnameClass = !mainCustomer.fullname
    ? "form__input--incomplete"
    : "";
  const validDrivingLicenseClass = !mainCustomer.drivingLicense.number
    ? "form__input--incomplete"
    : "";
  const validPassportClass = !mainCustomer.passport.number
    ? "form__input--incomplete"
    : "";
  const validDateOfBirthClass = !mainCustomer.dateOfBirth
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !mainCustomer.address.line1
    ? "form__input--incomplete"
    : "";

  const validExtraDetailsClass = !mainCustomer.connections.email
    ? "form__input--incomplete"
    : "";

  const validDescriptionClass = !description ? "form__input--incomplete" : "";

  // Additional Driver Fields (similar to Main Customer)
  const validAdditionalDriverClass = !additionalDriver.customer
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverDrivingLicenseClass = !additionalDriver
    .drivingLicense.number
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverPassportClass = !additionalDriver.passport.number
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverDateOfBirthClass = !additionalDriver.dateOfBirth
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverAddressClass = !additionalDriver.address.line1
    ? "form__input--incomplete"
    : "";

  const validAdditionalDriverExtraDetailsClass = !mainCustomer.connections.email
    ? "form__input--incomplete"
    : "";

  // Booking Status
  const validBookingStatusClass = !bookingStatus
    ? "form__input--incomplete"
    : "";

  // Complete Field (Boolean)
  const validCompleteClass = complete ? "" : "form__input--incomplete";

  // Insurance Details Fields
  const validInsuranceCompanyClass = !insuranceDetails.insuranceCompany
    ? "form__input--incomplete"
    : "";
  const validPolicyNumberClass = !insuranceDetails.policyNumber
    ? "form__input--incomplete"
    : "";
  const validValidFromClass = !insuranceDetails.validFrom
    ? "form__input--incomplete"
    : "";
  const validValidUntilClass = !insuranceDetails.validUntil
    ? "form__input--incomplete"
    : "";

  // Image Fields
  const validBeforeImagesClass = !beforeImages ? "form__input--incomplete" : "";
  const validAfterImagesClass = !afterImages ? "form__input--incomplete" : "";

  const getVehicleFromBooking = (booking, vehicles) => {
    const vehicleId = booking.vehicle;

    // Find the vehicle object with the matching ID
    const matchingVehicle = vehicles.find(
      (vehicle) => vehicle._id === vehicleId
    );

    return matchingVehicle;
  };

  const matchingVehicle = getVehicleFromBooking(booking, vehicles);

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  const content = (
    <>
      <p className={errClass}>{errContent}</p>
      <form className="newBookingForm" onSubmit={onSaveBookingClicked}>
        <h3 className="modelAndModelVariantBookingForm">
          {matchingVehicle.make} {matchingVehicle.model}{" "}
          {matchingVehicle.modelVariant} ⚠️ Warning - if you change booking
          duration, chauffeur, or an extra driver added you have to manually
          adjust corrected price
        </h3>

        <div className="newBookingFormSaveBtn">
          <button
            className="saveBtn"
            title="save"
            disabled={!canSave}
            onClick={onSaveBookingClicked}
          >
            Save
          </button>
          {/* <button
            className="delBtn"
            title="del"
            onClick={onDeleteBookingClicked}
            disabled
          >
            Delete
          </button> */}
        </div>
        <div className="vehicleDetailsBookingForm">
          <div className="table-container">
            <p>Vehicle Details</p>
            <table>
              <thead>
                <tr>
                  <th>Technical Details</th>
                  <th>Year</th>
                  <th>Colour</th>
                  <th>Drivetrain</th>
                  <th>Bodytype</th>
                  <th>Seats</th>
                  <th>Doors</th>
                  <th>Power</th>
                </tr>
              </thead>
              <tbody>
                <tr key={matchingVehicle.id}>
                  <td>{matchingVehicle.details}</td>
                  <td>{matchingVehicle.year}</td>
                  <td>{matchingVehicle.colour}</td>
                  <td>{matchingVehicle.drivetrain}</td>
                  <td>{matchingVehicle.bodyType}</td>
                  <td>{matchingVehicle.seats}</td>
                  <td>{matchingVehicle.doors}</td>
                  <td>{matchingVehicle.power} Hp</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="bookingFormInputFields">
          <div className="customerDetailsBookingFormEdit">
            <div className="sectionHeader">
              <p>Customer Details</p>
            </div>

            <div className="personalDetailsBookingForm">
              <p>Personal</p>

              <label className="form__label" htmlFor="customer">
                Customer
              </label>
              <span>Customer</span>
              {/* <select
                id="customer"
                name="customer"
                className={`form__input__booking ${validMainCustomerClass}`}
                value={mainCustomer.customer}
                onChange={onMainCustomerChanged}
                disabled
              >
                {userOptions}
              </select> */}
              {mainCustomer.customer ? (
                <select
                  id="customer"
                  name="customer"
                  className={`form__input__booking ${validMainCustomerClass}`}
                  value={mainCustomer.customer}
                  onChange={onMainCustomerChanged}
                  disabled
                >
                  {userOptions}
                </select>
              ) : (
                <input
                  id="customer"
                  name="fullname"
                  type="text"
                  className={`form__input__booking ${validMainCustomerFullnameClass}`}
                  value={mainCustomer.fullname}
                  onChange={onMainCustomerChanged}
                  placeholder="Full Name"
                />
              )}

              {/* Date of Birth */}
              <label className="form__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <span>Date Of Birth</span>
              <input
                className={`form__input__booking ${validDateOfBirthClass}`}
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                autoComplete="off"
                value={formatDate(mainCustomer.dateOfBirth)}
                onChange={onMainCustomerChanged}
                placeholder="Date of Birth"
              />

              {/* Address */}
              <label className="form__label" htmlFor="address.line1">
                Address Line 1
              </label>
              <span>Address Line 1</span>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.line1"
                name="address.line1"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.line1}
                onChange={onMainCustomerChanged}
                placeholder="Address Line 1"
              />

              <label className="form__label" htmlFor="address.line2">
                Address Line 2
              </label>
              <span>Address Line 2 </span>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.line2"
                name="address.line2"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.line2}
                onChange={onMainCustomerChanged}
                placeholder="Address Line 2"
              />

              <label className="form__label" htmlFor="address.city">
                City
              </label>
              <span>City</span>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.city"
                name="address.city"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.city}
                onChange={onMainCustomerChanged}
                placeholder="City"
              />

              <label className="form__label" htmlFor="address.county">
                County
              </label>
              <span>County</span>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.county"
                name="address.county"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.county}
                onChange={onMainCustomerChanged}
                placeholder="County"
              />

              <label className="form__label" htmlFor="address.postCode">
                Post Code
              </label>
              <span>Post Code</span>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.postCode"
                name="address.postCode"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.postCode}
                onChange={onMainCustomerChanged}
                placeholder="Postcode"
              />
            </div>

            <div className="drivingLisenceDetails">
              <p>Driving Lisence Details</p>
              {/* Driving License */}
              <label className="form__label" htmlFor="drivingLicense.number">
                Driving License Number
              </label>
              <span>Driving License Number</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.number"
                name="drivingLicense.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.drivingLicense.number}
                onChange={onMainCustomerChanged}
                placeholder="Driving License Number"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfIssue"
              >
                Date of Issue (Driving License)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.dateOfIssue"
                name="drivingLicense.dateOfIssue"
                type="date"
                autoComplete="off"
                value={formatDate(mainCustomer.drivingLicense.dateOfIssue)}
                onChange={onMainCustomerChanged}
                placeholder="Date of Issue (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfExpiry"
              >
                Date of Expiry (Driving License)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.dateOfExpiry"
                name="drivingLicense.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={formatDate(mainCustomer.drivingLicense.dateOfExpiry)}
                onChange={onMainCustomerChanged}
                placeholder="Date of Expiry (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.placeOfIssue"
              >
                Place of Issue (Driving License)
              </label>
              <span>Place of Issue (Driving License)</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.placeOfIssue"
                name="drivingLicense.placeOfIssue"
                type="text"
                autoComplete="off"
                value={mainCustomer.drivingLicense.placeOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Place of Issue (Driving License)"
              />
            </div>

            <div className="passportDetails">
              {/* Passport */}
              <p>Passport Details</p>
              <label className="form__label" htmlFor="passport.number">
                Passport Number
              </label>
              <span>Passport Number</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.number"
                name="passport.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.passport.number}
                onChange={onMainCustomerChanged}
                placeholder="Passport Number"
              />

              <label className="form__label" htmlFor="passport.dateOfIssue">
                Date of Issue (Passport)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.dateOfIssue"
                name="passport.dateOfIssue"
                type="date"
                autoComplete="off"
                value={formatDate(mainCustomer.passport.dateOfIssue)}
                onChange={onMainCustomerChanged}
                placeholder="Date of Issue (Passport)"
              />

              <label className="form__label" htmlFor="passport.dateOfExpiry">
                Date of Expiry (Passport)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.dateOfExpiry"
                name="passport.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={formatDate(mainCustomer.passport.dateOfExpiry)}
                onChange={onMainCustomerChanged}
                placeholder="Date of Expiry (Passport)"
              />
            </div>
            <div className="extraDetailsConnections">
              <p>Extra Details</p>

              <label className="form__label" htmlFor="connections.email">
                email
              </label>
              <span>Email</span>
              <input
                className={`form__input__booking ${validExtraDetailsClass}`}
                id="connections.email"
                name="connections.email"
                type="text"
                autoComplete="off"
                value={mainCustomer.connections.email}
                onChange={onMainCustomerChanged}
                onFocus={(e) => {
                  if (e.target.value === "email") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "email";
                  }
                }}
                placeholder="email"
              />

              <label className="form__label" htmlFor="connections.number">
                number
              </label>
              <span>Number</span>
              <input
                className={`form__input__booking ${validExtraDetailsClass}`}
                id="connections.number"
                name="connections.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.connections.number}
                onChange={onMainCustomerChanged}
                placeholder="number"
              />
            </div>
          </div>

          {/* just making a gap lol  */}

          {/* <div className="bookingDetails">
            <div className="sectionHeaderBookingDetails">
              <p>Booking Details</p>
            </div>
            <div
              className="pickUpAndDropOffLocation"
              style={{ marginTop: "20px" }}
            >
              <p>Pick Up Location</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="pickUpLocation"
              >
                Pick Up Location
              </label>
              <input
                className={`form__input__booking ${validPickUpLocationClass}`}
                id="pickUpLocation"
                name="pickUpLocation"
                type="text"
                autoComplete="off"
                value={pickUpLocation}
                onChange={onPickUpLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "pickUpLocation") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "pickUpLocation";
                  }
                }}
                placeholder="pickUpLocation"
              />

              <p>Drop Off Location</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="dropOffLocation"
              >
                Drop Off Location
              </label>
              <input
                className={`form__input__booking ${validDropOffLocationClass}`}
                id="dropOffLocation"
                name="dropOffLocation"
                type="text"
                autoComplete="off"
                value={dropOffLocation}
                onChange={onDropOffLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "dropOffLocation") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "dropOffLocation";
                  }
                }}
                placeholder="dropOffLocation"
              />
            </div>

            <div className="chauffeurDetails">
              <p>Chauffeur?</p>
              <label className="form__label" htmlFor="chauffeur">
                Chauffeur Service
              </label>
              <input
                className={`form__input__booking ${validChauffeurClass}`}
                id="chauffeur"
                name="chauffeur"
                type="checkbox" // Set the type to "checkbox" for a boolean input
                checked={chauffeur} // Use the checked attribute to control the checkbox state
                onChange={onChauffeurChanged} // Provide an onChange handler to update the state
              />
            </div>

            <div className="durationDetails">
              <p>Duration</p>
              <div className="durationInputFields">
                <label className="form__label" htmlFor="startDate">
                  Start Date
                </label>
                <span>Start Date</span>
                <input
                  className={`form__input__booking ${validStartDateClass}`}
                  id="startDate"
                  name="startDate"
                  type="date" // Set the type to "date" for date input
                  value={formatDate(startDate)}
                  onChange={onStartDateChanged}
                  required // Add the "required" attribute for validation
                />

                <label className="form__label" htmlFor="endDate">
                  End Date
                </label>
                <span>End Date</span>
                <input
                  className={`form__input__booking ${validEndDateClass}`}
                  id="endDate"
                  name="endDate"
                  type="date" // Set the type to "date" for date input
                  value={formatDate(endDate)}
                  onChange={onEndDateChanged}
                  required // Add the "required" attribute for validation
                />

                <label
                  className="form__label--visually-hidden"
                  htmlFor="dateOfBooking"
                >
                  Date of Booking
                </label>
                <span>Date of Booking</span>
                <input
                  className={`form__input__booking ${validDateOfBookingClass}`}
                  id="dateOfBooking"
                  name="dateOfBooking"
                  type="text"
                  autoComplete="off"
                  value={formatDate(dateOfBooking)}
                  onChange={onDateOfBookingChanged}
                  onFocus={(e) => {
                    if (e.target.value === "dateOfBooking") {
                      e.target.value = "";
                    }
                    e.target.readOnly = true; // Make the input field readonly
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "dateOfBooking";
                    }
                    e.target.readOnly = true; // Make the input field readonly
                  }}
                  placeholder="dateOfBooking"
                />
              </div>
            </div>
          </div> */}
          <div className="bookingDetails">
            <div className="sectionHeaderBookingDetails">
              <p>Booking Details</p>
            </div>
            <div
              className={`pickUpAndDropOffLocation ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              {/* <p>Pick Up Location</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="pickUpLocation"
              >
                Pick Up Location
              </label>
              <input
                className={`form__input__booking ${validPickUpLocationClass}`}
                id="pickUpLocation"
                name="pickUpLocation"
                type="text"
                autoComplete="off"
                value={pickUpLocation}
                onChange={onPickUpLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "pickUpLocation") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "pickUpLocation";
                  }
                }}
                placeholder="pickUpLocation"
              />

              <p>Drop Off Location</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="dropOffLocation"
              >
                Drop Off Location
              </label>
              <input
                className={`form__input__booking ${validDropOffLocationClass}`}
                id="dropOffLocation"
                name="dropOffLocation"
                type="text"
                autoComplete="off"
                value={dropOffLocation}
                onChange={onDropOffLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "dropOffLocation") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "dropOffLocation";
                  }
                }}
                placeholder="dropOffLocation"
              /> */}

              <p>Pick Up Location Details</p>
              <label className="form__label--visually-hidden">
                Pick Up Location Type:{" "}
              </label>
              <select
                name="type"
                value={pickUpLocationInDepth.type}
                onChange={handlePickUpLocationChange}
                className={`form__input__booking ${validDealershipClass}`}
              >
                <option value="Dealership">Dealership</option>
                <option value="Custom">Custom</option>
              </select>

              {pickUpLocationInDepth.type === "Custom" && (
                <div className="customLocationNewBookingForm">
                  <input
                    type="text"
                    name="line1"
                    value={pickUpLocationInDepth.address.line1}
                    onChange={handlePickUpLocationChange}
                    placeholder="Address Line 1"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="line2"
                    value={pickUpLocationInDepth.address.line2}
                    onChange={handlePickUpLocationChange}
                    placeholder="Address Line 2"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="city"
                    value={pickUpLocationInDepth.address.city}
                    onChange={handlePickUpLocationChange}
                    placeholder="City"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="county"
                    value={pickUpLocationInDepth.address.county}
                    onChange={handlePickUpLocationChange}
                    placeholder="County"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="postCode"
                    value={pickUpLocationInDepth.address.postCode}
                    onChange={handlePickUpLocationChange}
                    placeholder="Post Code"
                    className={`form__input__booking `}
                  />
                </div>
              )}
              <p>Drop Off Location Details</p>
              <label className="form__label--visually-hidden">
                Drop Off Location Type:{" "}
              </label>
              <select
                name="type"
                value={dropOffLocationInDepth.type}
                onChange={handleDropOffLocationChange}
                className={`form__input__booking `}
              >
                <option value="Dealership">Dealership</option>
                <option value="Custom">Custom</option>
              </select>

              {dropOffLocationInDepth.type === "Custom" && (
                <div className="customLocationNewBookingForm">
                  <input
                    type="text"
                    name="line1"
                    value={dropOffLocationInDepth.address.line1}
                    onChange={handleDropOffLocationChange}
                    placeholder="Address Line 1"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="line2"
                    value={dropOffLocationInDepth.address.line2}
                    onChange={handleDropOffLocationChange}
                    placeholder="Address Line 2"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="city"
                    value={dropOffLocationInDepth.address.city}
                    onChange={handleDropOffLocationChange}
                    placeholder="City"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="county"
                    value={dropOffLocationInDepth.address.county}
                    onChange={handleDropOffLocationChange}
                    placeholder="County"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="postCode"
                    value={dropOffLocationInDepth.address.postCode}
                    onChange={handleDropOffLocationChange}
                    placeholder="Post Code"
                    className={`form__input__booking`}
                  />
                </div>
              )}
            </div>

            <div
              className={`chauffeurDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <p>Chauffeur?</p>
              <label className="form__label" htmlFor="chauffeur">
                Chauffeur Service
              </label>
              <input
                className={`form__input__booking__chauffeur ${validChauffeurClass}`}
                id="chauffeur"
                name="chauffeur"
                type="checkbox" // Set the type to "checkbox" for a boolean input
                checked={chauffeur} // Use the checked attribute to control the checkbox state
                onChange={onChauffeurChanged} // Provide an onChange handler to update the state
              />
              <p>
                Chauffeur Details (please include an accurate description of
                requirements)
              </p>
              <textarea
                className="form__input__booking__chauffeurDetails"
                id="chauffeurDetails"
                name="Chauffeur Details"
                value={chauffeurDetails}
                onChange={onChauffeurDetailsChanged}
                placeholder="Chauffeur Details"
              ></textarea>
            </div>

            <div
              className={`durationDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <p>Duration</p>
              <div className="durationInputFields">
                <label className="form__label" htmlFor="startDate">
                  Start Date
                </label>
                <span>Start Date</span>
                <input
                  className={`form__input__booking ${validStartDateClass}`}
                  id="startDate"
                  name="startDate"
                  type="date" // Set the type to "date" for date input
                  value={startDate}
                  onChange={onStartDateChanged}
                  required // Add the "required" attribute for validation
                />

                <label className="form__label" htmlFor="endDate">
                  End Date
                </label>
                <span>End Date</span>
                <input
                  className={`form__input__booking ${validEndDateClass}`}
                  id="endDate"
                  name="endDate"
                  type="date" // Set the type to "date" for date input
                  value={endDate}
                  onChange={onEndDateChanged}
                  required // Add the "required" attribute for validation
                />
              </div>
            </div>
            <div
              className={`startEndDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <div className="startEndInputFields">
                <p>Start Time</p>
                <label htmlFor="startTime" className="form__label">
                  Start Time:
                </label>
                <select
                  id="startTime"
                  value={startTime}
                  onChange={onStartTimeChanged}
                  className="form__input__booking"
                >
                  {timeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <p>End Time</p>
                <label htmlFor="endTime" className="form__label">
                  End Time:
                </label>
                <select
                  id="endTime"
                  value={endTime}
                  onChange={onEndTimeChanged}
                  className="form__input__booking"
                >
                  {timeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="additionalDriverBookingFormEdit">
            <div className="sectionHeaderAdditionalDriver">
              <p>Additional Driver Details</p>
            </div>

            <div className="personalDetailsAdditionalDriverEdit">
              <p>Personal</p>

              <label
                className="form__label--visually-hidden"
                htmlFor="additionalCustomer"
              >
                Additional Customer
              </label>
              <span>Additional Customer</span>
              <input
                id="customer"
                name="customer"
                className={`form__input__booking ${validAdditionalDriverClass}`}
                type="text"
                value={additionalDriver.customer}
                onChange={onAdditionalDriverChanged}
                placeholder="Full Name"
              />

              {/* Date of Birth */}
              <label className="form__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <span>Date of Birth</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDateOfBirthClass}`}
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                autoComplete="off"
                value={formatDate(additionalDriver.dateOfBirth)}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Birth"
              />

              {/* Address */}
              <label className="form__label" htmlFor="address.line1">
                Address Line 1
              </label>
              <span>Address Line 1</span>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.line1"
                name="address.line1"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.line1}
                onChange={onAdditionalDriverChanged}
                placeholder="Address Line 1"
              />

              <label className="form__label" htmlFor="address.line2">
                Address Line 2
              </label>
              <span>Address Line 2</span>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.line2"
                name="address.line2"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.line2}
                onChange={onAdditionalDriverChanged}
                placeholder="Address Line 2"
              />

              <label className="form__label" htmlFor="address.city">
                City
              </label>
              <span>City</span>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.city"
                name="address.city"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.city}
                onChange={onAdditionalDriverChanged}
                placeholder="City"
              />

              <label className="form__label" htmlFor="address.county">
                County
              </label>
              <span>County</span>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.county"
                name="address.county"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.county}
                onChange={onAdditionalDriverChanged}
                placeholder="County"
              />

              <label className="form__label" htmlFor="address.postCode">
                Post Code
              </label>
              <span>Post Code</span>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.postCode"
                name="address.postCode"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.postCode}
                onChange={onAdditionalDriverChanged}
                placeholder="Postcode"
              />
            </div>

            <div className="drivingLisenceDetailsAdditionalCustomer">
              <p>Driving Lisence Details</p>
              {/* Driving License */}
              <label className="form__label" htmlFor="drivingLicense.number">
                Driving License Number
              </label>
              <span>Driving License Number</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.number"
                name="drivingLicense.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.drivingLicense.number}
                onChange={onAdditionalDriverChanged}
                placeholder="Driving License Number"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfIssue"
              >
                Date of Issue (Driving License)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.dateOfIssue"
                name="drivingLicense.dateOfIssue"
                type="date"
                autoComplete="off"
                value={formatDate(additionalDriver.drivingLicense.dateOfIssue)}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Issue (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfExpiry"
              >
                Date of Expiry (Driving License)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.dateOfExpiry"
                name="drivingLicense.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={formatDate(additionalDriver.drivingLicense.dateOfExpiry)}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Expiry (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.placeOfIssue"
              >
                Place of Issue (Driving License)
              </label>
              <span>Place of Issue (Driving License)</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.placeOfIssue"
                name="drivingLicense.placeOfIssue"
                type="text"
                autoComplete="off"
                value={additionalDriver.drivingLicense.placeOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Place of Issue (Driving License)"
              />
            </div>

            <div className="passportDetailsAdditionalDriver">
              <p>PassportDetails</p>
              {/* Passport */}
              <label className="form__label" htmlFor="passport.number">
                Passport Number
              </label>
              <span>Passport Number</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.number"
                name="passport.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.passport.number}
                onChange={onAdditionalDriverChanged}
                placeholder="Passport Number"
              />

              <label className="form__label" htmlFor="passport.dateOfIssue">
                Date of Issue (Passport)
              </label>
              <span>Date Of Issue</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.dateOfIssue"
                name="passport.dateOfIssue"
                type="date"
                autoComplete="off"
                value={formatDate(additionalDriver.passport.dateOfIssue)}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Issue (Passport)"
              />

              <label className="form__label" htmlFor="passport.dateOfExpiry">
                Date of Expiry (Passport)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.dateOfExpiry"
                name="passport.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={formatDate(additionalDriver.passport.dateOfExpiry)}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Expiry (Passport)"
              />
            </div>
            <div className="extraDetailsConnectionsAdditionalDriver">
              <p>Extra Details</p>

              <label className="form__label" htmlFor="connections.email">
                email
              </label>
              <span>Email</span>
              <input
                className={`form__input__booking ${validAdditionalDriverExtraDetailsClass}`}
                id="connections.email"
                name="connections.email"
                type="text"
                autoComplete="off"
                value={additionalDriver.connections.email}
                onChange={onAdditionalDriverChanged}
                onFocus={(e) => {
                  if (e.target.value === "email") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "email";
                  }
                }}
                placeholder="email"
              />

              <label className="form__label" htmlFor="connections.number">
                number
              </label>
              <span>Number</span>
              <input
                className={`form__input__booking ${validAdditionalDriverExtraDetailsClass}`}
                id="connections.number"
                name="connections.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.connections.number}
                onChange={onAdditionalDriverChanged}
                placeholder="number"
              />
            </div>
          </div>

          <div className="paymentDetails">
            <div className="paymentDetailsHeader">
              <p>Payment Details</p>
            </div>

            <div className="vehicleOption">
              <p>Vehicle</p>
              <label className="form__label--visually-hidden" htmlFor="vehicle">
                Vehicle
              </label>
              <select
                id="vehicle"
                name="vehicle"
                className={`form__input__booking ${validVehicleClass}`}
                value={vehicle}
                onChange={onVehicleChanged}
                disabled
              >
                {vehicleOptions}
              </select>
            </div>

            <div className="dealershipOption">
              <p>Dealership</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="dealership"
              >
                Dealership
              </label>
              <select
                id="dealership"
                name="dealership"
                className={`form__input__booking ${validDealershipClass}`}
                value={dealership}
                onChange={onDealershipChanged}
                disabled
              >
                {userOptions}
              </select>
            </div>

            <div className="orderNumber">
              <p>Order Number</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="orderNumber"
              >
                Order Number
              </label>
              <input
                className={`form__input__booking ${validOrderNumberClass}`}
                id="orderNumber"
                name="orderNumber"
                type="text"
                autoComplete="off"
                value={orderNumber}
                onChange={onOrderNumberChanged}
                onFocus={(e) => {
                  if (e.target.value === "orderNumber") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "orderNumber";
                  }
                }}
                placeholder="orderNumber"
              />
            </div>

            <div className="totalPrice">
              <p>Chauffeur Fee</p>
              <input
                type="number"
                className="form__input__booking"
                id="Chauffeur Fee"
                name="Chauffeur Fee"
                autoComplete="off"
                value={chauffeurFee}
                onChange={onChauffeurFeeChanged}
                placeholder="Chauffeur Fee"
              />
              <p>Delivery Fee</p>
              <input
                type="number"
                className="form__input__booking"
                id="Delivery Fee"
                name="Delivery Fee"
                autoComplete="off"
                value={deliveryFee}
                onChange={onDeliveryFeeChanged}
                placeholder="Delivery Fee"
              />
              <p>Additional Driver Fee</p>
              <input
                type="number"
                className="form__input__booking"
                id="Additional Driver Fee"
                name="Additional Driver Fee"
                autoComplete="off"
                value={additionalDriverFee}
                onChange={onAdditionalDriverFeeChanged}
                placeholder="Additional Driver Fee"
              />
              <p>Total Price incl All Fees</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="totalPrice"
              >
                Vehicle TotalPrice
              </label>
              <input
                className={`form__input__booking ${validTotalPriceClass}`}
                id="totalPrice"
                name="totalPrice"
                type="number"
                autoComplete="off"
                value={totalPrice}
                onChange={onTotalPriceChanged}
                onFocus={(e) => {
                  if (e.target.value === "TotalPrice") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "TotalPrice";
                  }
                }}
                placeholder="TotalPrice"
              />
            </div>
          </div>

          <div className="insuranceDetails">
            {/* Insurance Company */}
            <div className="insuranceDetailsHeader">
              <p>Insurance Details</p>
            </div>

            <div className="insuranceCompany">
              <label className="form__label" htmlFor="insuranceCompany">
                Insurance Company
              </label>
              <p>Insurance Company</p>
              <input
                className={`form__input__booking ${validInsuranceCompanyClass}`}
                id="insuranceCompany"
                name="insuranceCompany"
                type="text"
                autoComplete="off"
                value={insuranceDetails.insuranceCompany || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Insurance Company"
              />
            </div>

            <div className="policyNumber">
              {/* Policy Number */}
              <label className="form__label" htmlFor="policyNumber">
                Policy Number
              </label>
              <p>Policy Number</p>
              <input
                className={`form__input__booking ${validPolicyNumberClass}`}
                id="policyNumber"
                name="policyNumber"
                type="text"
                autoComplete="off"
                value={insuranceDetails.policyNumber || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Policy Number"
              />
            </div>

            <div className="validFrom">
              {/* Valid From */}
              <label className="form__label" htmlFor="validFrom">
                Valid From
              </label>
              <p>Valid From</p>
              <input
                className={`form__input__booking ${validValidFromClass}`}
                id="validFrom"
                name="validFrom"
                type="date"
                autoComplete="off"
                value={formatDate(insuranceDetails.validFrom) || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Valid From"
              />
            </div>

            <div className="validUntil">
              {/* Valid Until */}
              <label className="form__label" htmlFor="validUntil">
                Valid Until
              </label>
              <p>Valid Until</p>
              <input
                className={`form__input__booking ${validValidUntilClass}`}
                id="validUntil"
                name="validUntil"
                type="date"
                autoComplete="off"
                value={formatDate(insuranceDetails.validUntil) || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Valid Until"
              />
            </div>
          </div>

          <div className="extraDetails">
            <div className="extraDetailsHeader">
              <p>Extra Details</p>
            </div>
            <div className="extraDetailsDescription">
              <label
                className="form__label--visually-hidden"
                htmlFor="description"
              >
                description
              </label>
              <p>Description</p>
              <input
                className={`form__input__booking ${validDescriptionClass}`}
                id="description"
                name="description"
                type="text"
                autoComplete="off"
                value={description || ""}
                onChange={onDescriptionChanged}
                onFocus={(e) => {
                  if (e.target.value === "description") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "description";
                  }
                }}
                placeholder="description"
              />
            </div>

            {/* Booking Status */}

            <div className="extraDetailsBookingStatus">
              <label
                className="form__label--visually-hidden"
                htmlFor="bookingStatus"
              >
                Vehicle BookingStatus
              </label>
              <p>Booking Status</p>
              <select
                className={`form__input__booking ${validBookingStatusClass}`}
                name="bookingStatus"
                value={bookingStatus}
                onChange={onBookingStatusChanged}
              >
                {bookingStatusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <p className="bookingProgressPTag">Booking Progress</p>
              <select
                className={`form__input__booking`}
                name="bookingProgress"
                value={bookingProgress}
                onChange={onBookingProgressChanged}
              >
                <option value="" disabled selected>
                  Please Select Booking Progress
                </option>
                {bookingProgressOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Complete  */}

            <div className="extraDetailsComplete">
              <label className="form__label" htmlFor="complete">
                Complete
              </label>
              <p>Complete?</p>
              <input
                className={`form__input__booking ${validCompleteClass}`}
                id="complete"
                name="complete"
                type="checkbox"
                checked={complete}
                onChange={onCompleteChanged}
              />
            </div>

            <div className="extraDetailsImages">
              {/* Before Images */}
              <div className="beforeImages">
                <label className="form__label" htmlFor="beforeImages">
                  Before Images
                </label>
                <p>Before Images</p>
                <input
                  className={`form__input__booking ${validBeforeImagesClass}`}
                  id="beforeImages"
                  name="beforeImages"
                  type="text"
                  autoComplete="off"
                  value={beforeImages || ""}
                  onChange={onBeforeImagesChanged}
                  placeholder="Coming Soon"
                  disabled
                />
              </div>
              <div className="space"></div> {/* Add an empty div for spacing */}
              {/* After Images */}
              <div className="afterImages">
                <label className="form__label" htmlFor="afterImages">
                  After Images
                </label>
                <p>After Images</p>
                <input
                  className={`form__input__booking ${validAfterImagesClass}`}
                  id="afterImages"
                  name="afterImages"
                  type="text"
                  autoComplete="off"
                  value={afterImages || ""}
                  onChange={onAfterImagesChanged}
                  placeholder="Coming Soon"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );

  return content;
};

export default EditBookingForm;
