import { useGetBookingsQuery } from "../../bookings/bookingsApiSlice";
import { useGetUsersQuery } from "../../users/usersApiSlice";
import useAuth from "../../../hooks/useAuth";
import { HashLoader } from "react-spinners";
import { useState, useEffect } from "react";
import { FaRandom, FaSearch, FaRegCopy } from "react-icons/fa";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "react-tooltip";

const DashboardMyCustomers = () => {
  const { email } = useAuth();
  const [dealership, setDealership] = useState([]);
  const [showCopyPopup, setShowCopyPopup] = useState(false);

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: users,
    isLoading: isLoadingUsers,
    isSuccess: isSuccessUsers,
    isError: isErrorUsers,
    error: errorUsers,
  } = useGetUsersQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccessUsers && !isLoadingUsers) {
      const filteredUserId = Object.keys(users.entities).find(
        (userId) => users.entities[userId].email === email
      );
      const user = filteredUserId ? users.entities[filteredUserId] : null;
      setDealership(user); // Update filteredUser state
    }
  }, [isSuccessUsers, isLoadingUsers, users, email, dealership]); // Dependency array updated

  // console.log(dealership);

  useEffect(() => {
    if (showCopyPopup) {
      const timer = setTimeout(() => {
        setShowCopyPopup(false);
      }, 10000); // Hide pop-up after 2 seconds
      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [showCopyPopup]);

  const handleCopy = () => {
    setShowCopyPopup(true); // Show the pop-up
  };

  const [filteredBookings, setFilteredBookings] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("mostOrders");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking.vehicleOwner.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]);

  // console.log(filteredBookings);

  // Function to sort customers based on the selected filter
  const sortCustomers = (customers) => {
    switch (selectedFilter) {
      case "mostOrders":
        return customers.sort((a, b) => b.orders - a.orders);
      case "leastOrders":
        return customers.sort((a, b) => a.orders - b.orders);
      case "mostSpent":
        return customers.sort((a, b) => b.totalSpent - a.totalSpent);
      case "leastSpent":
        return customers.sort((a, b) => a.totalSpent - b.totalSpent);
      default:
        return customers;
    }
  };

  // Function to filter customers based on search query
  const filterCustomers = (customers) => {
    const searchTerms = searchQuery.toLowerCase().split(" ");
    return customers.filter((customer) => {
      return (
        searchTerms.length === 0 ||
        searchTerms.some((term) => {
          const termLowerCase = term.toLowerCase();
          return (
            customer?.name.toLowerCase().includes(termLowerCase) ||
            customer.email.toLowerCase().includes(termLowerCase) ||
            customer.number.toString().includes(termLowerCase) || // Search by number
            customer.orders.toString().includes(termLowerCase) || // Search by number of orders
            customer.totalSpent.toString().includes(termLowerCase) // Search by total spent
          );
        })
      );
    });
  };

  let content;

  if (isLoading && isLoadingUsers) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;
  if (isErrorUsers)
    content = <p className="errmsg">{errorUsers?.data?.message}</p>;

  if (isSuccess) {
    // Extract unique customers from filtered bookings
    const uniqueCustomers = Array.from(
      new Set(filteredBookings.map((booking) => booking.mainCustomer.customer))
    );

    const customerRows = uniqueCustomers.map((customerId) => {
      const customerDetails = filteredBookings.find(
        (booking) => booking.mainCustomer.customer === customerId
      ).mainCustomer;

      // Count the number of orders for the current customer
      const numberOfOrders = filteredBookings.filter(
        (booking) => booking.mainCustomer.customer === customerId
      ).length;

      // Find the first name and last name of the customer
      // const { firstname, lastname } = filteredBookings.find(
      //   (booking) => booking.mainCustomer.customer === customerId
      // ).customer;

      const booking = filteredBookings.find(
        (booking) => booking.mainCustomer.customer === customerId
      );

      // Destructure firstname and lastname with a fallback to an empty object if `booking.customer` is null or undefined
      const { firstname, lastname } = booking.customer || {};

      // Determine the customer name, falling back to mainCustomer.fullname if necessary
      const customerName =
        firstname && lastname
          ? `${firstname} ${lastname}`
          : booking.mainCustomer.fullname;

      // Filter bookings for the current customer
      const customerBookings = filteredBookings.filter(
        (booking) => booking.mainCustomer.customer === customerId
      );

      // Calculate total spent by summing up totalPrice from each booking
      // const totalSpent = customerBookings.reduce(
      //   (acc, booking) => acc + booking.totalPrice,
      //   0
      // );

      // Calculate total spent by summing up totalPrice minus deposit from each booking
      const totalSpent = customerBookings.reduce(
        (acc, booking) =>
          acc + (booking.totalPrice - booking.vehicleDetails.deposit), // Subtract deposit
        0
      );

      return {
        id: customerId,
        // name: `${firstname} ${lastname}`,
        name: customerName,
        email: customerDetails.connections.email,
        number: customerDetails.connections.number,
        orders: numberOfOrders,
        totalSpent: totalSpent,
      };
    });

    // Sort customers based on the selected filter
    const sortedCustomers = sortCustomers(customerRows);

    // Filter customers based on search query
    const filteredCustomers = filterCustomers(sortedCustomers);

    content = (
      <div className="dashboardMyCustomers">
        <h3
          data-tooltip-id="infoTooltip"
          data-tooltip-content="This excludes customers coming from manual bookings, feature coming soon!"
        >
          Customers
        </h3>
        <Tooltip
          id="infoTooltip"
          type="dark"
          effect="float"
          place="right"
          className="tooltipMyCustomers"
        />

        <div className="dashboardMyCustomersTable">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("mostOrders")}>
                    Most Orders
                  </li>
                  <li onClick={() => setSelectedFilter("leastOrders")}>
                    Least Orders
                  </li>
                  <li onClick={() => setSelectedFilter("mostSpent")}>
                    Most Spent
                  </li>
                  <li onClick={() => setSelectedFilter("leastSpent")}>
                    Least Spent
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Email</th>
                <th>Number</th>
                <th>Orders</th>
                <th>Total Spent</th>
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer) => (
                <tr key={customer.id}>
                  <td className="customerOrdersLink">
                    <Link to={`/dashboard/myCustomers/${customer.id}`}>
                      {customer.name}{" "}
                    </Link>
                  </td>
                  <td className="customerOrdersLink">
                    <Link to={`/dashboard/myCustomers/${customer.id}`}>
                      {customer.email}{" "}
                    </Link>
                  </td>
                  <td className="customerOrdersLink">
                    {" "}
                    <Link to={`/dashboard/myCustomers/${customer.id}`}>
                      {customer.number}{" "}
                    </Link>
                  </td>
                  <td className="customerOrdersLink">
                    <Link to={`/dashboard/myCustomers/${customer.id}`}>
                      {customer.orders}
                    </Link>
                  </td>
                  <td className="customerOrdersLink">
                    {" "}
                    <Link to={`/dashboard/myCustomers/${customer.id}`}>
                      {customer.totalSpent}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="dashboardMyCustomersLink">
          Share this Link So Your Customers Can View Your Vehicles! -{""}{" "}
          <a href={`https://imascars.co.uk/${dealership.id}`}>
            https://imascars.co.uk/{dealership.id}
          </a>{" "}
          <CopyToClipboard
            text={`https://imascars.co.uk/${dealership.id}`}
            onCopy={handleCopy}
          >
            <button>
              <FaRegCopy className="copyIcon" />
            </button>
          </CopyToClipboard>
          {showCopyPopup && (
            <div className="copyPopup">Copied to clipboard!</div>
          )}
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardMyCustomers;
