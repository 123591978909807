import { useParams } from "react-router-dom";
import EditVehicleForm from "./EditVehicleForm";

import { useGetVehiclesQuery } from "./vehiclesApiSlice";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { HashLoader } from "react-spinners";
import useAuth from "../../hooks/useAuth";

const EditVehicle = () => {
  const { id } = useParams();

  const { email, isDealership, isAdmin } = useAuth();

  const { vehicle } = useGetVehiclesQuery("vehiclesList", {
    selectFromResult: ({ data }) => ({
      vehicle: data?.entities[id],
    }),
  });

  const { users } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      users: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  if (!vehicle || !users?.length) return <HashLoader color="#36d7b7" />;

  if (!isDealership && !isAdmin) {
    if (vehicle.email !== email) {
      return <p className="errmsg">No access</p>;
    }
  }

  const content = <EditVehicleForm vehicle={vehicle} users={users} />;

  return content;
};

export default EditVehicle;
