import DealerVehicleList from "../features/vehicles/DealerVehicleList";
import HomeSideBar from "./HomeSideBar";
import { DateRangeProvider } from "../context/DateRangeContext";

const DealerVehicle = () => {
  const content = (
    <>
      <DateRangeProvider>
        <main>
          <HomeSideBar />
          <DealerVehicleList />
        </main>
      </DateRangeProvider>
    </>
  );

  return content;
};

export default DealerVehicle;
