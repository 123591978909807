import NewVehicleForm from "./NewVehicleForm";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { HashLoader } from "react-spinners";

import useAuth from "../../hooks/useAuth";

const NewVehicle = () => {
  const { email } = useAuth();

  const { users } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      users: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  if (!users?.length) return <HashLoader color="#36d7b7" />;

  // Filter users based on email directly within the JSX
  const filteredUser = users.filter((user) => user.email === email);

  const content = <NewVehicleForm filteredUser={filteredUser} />;

  return content;
};

export default NewVehicle;
