import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleTermsClick = () => {
    navigate("/termsAndConditions"); // Navigate to the terms and conditions page
  };

  const handlePrivClick = () => {
    navigate("/privacyPolicy"); // Navigate to the terms and conditions page
  };

  const handleCookiesClick = () => {
    navigate("/cookiesPolicy"); // Navigate to the terms and conditions page
  };

  return (
    <div className="Footer">
      <p onClick={handleTermsClick}>Terms And Conditions</p>
      <p onClick={handlePrivClick}>Privacy Policy</p>
      <p onClick={handleCookiesClick}>Cookies</p>
    </div>
  );
};

export default Footer;
