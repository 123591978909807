import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="termsAndConditions">
      <h3>Privacy Policy</h3>

      <p>Last Updated - 15/10/24</p>

      <p>
        At IMAS Cars (accessible from{" "}
        <a href="https://imascars.co.uk">https://imascars.co.uk</a>), we are
        committed to protecting and respecting your privacy. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your personal
        information when you visit our website or use our services. Please read
        this policy carefully to understand how we manage your data.
      </p>

      <h2>1. Information We Collect</h2>

      <h2>1.1 Information Collected from Dealerships</h2>

      <p>
        When a dealership registers on IMAS Cars, we collect the following
        personal information:
      </p>

      <ul>
        <li>
          <strong>Email:</strong> Required for account creation and
          communication.
        </li>
        <li>
          <strong>Telephone:</strong> Required for contact and communication.
        </li>
        <li>
          <strong>First Name and Last Name:</strong> For account identification
          and personalization.
        </li>
        <li>
          <strong>Password:</strong> Stored securely for account authentication.
        </li>
        <li>
          <strong>Roles:</strong> Defines your access (e.g., Customer,
          Dealership, Admin).
        </li>
        <li>
          <strong>Company Name:</strong> Required if the user is a dealership.
        </li>
        <li>
          <strong>Location:</strong> Latitude and longitude to define your
          business's physical presence.
        </li>
        <li>
          <strong>Address:</strong> This includes house number, street name,
          locality, city or town, postcode, and country (default: United
          Kingdom).
        </li>
        <li>
          <strong>Profile Picture:</strong> Optional, provided by the dealership
          to represent their profile.
        </li>
        <li>
          <strong>Stripe Customer ID:</strong> This is collected if you have a
          Stripe account linked for subscription payments.
        </li>
        <li>
          <strong>Price ID:</strong> This is used to manage your subscription
          plan.
        </li>
        <li>
          <strong>Allow Access:</strong> Boolean field indicating if the account
          has been approved for access.
        </li>
      </ul>

      <h2>1.2 Information Collected from Customers</h2>

      <p>
        When a customer signs up on IMAS Cars, we collect the following personal
        information:
      </p>

      <ul>
        <li>
          <strong>Email:</strong> For account creation, communication, and
          sending booking details.
        </li>
        <li>
          <strong>Telephone:</strong> For contacting the customer regarding
          bookings.
        </li>
        <li>
          <strong>First Name and Last Name:</strong> For identification and
          communication.
        </li>
        <li>
          <strong>Password:</strong> Stored securely for account authentication.
        </li>
        <li>
          <strong>Roles:</strong> Defines your access (e.g., Customer).
        </li>
      </ul>

      <p>
        Customers are not required to provide a company name or a Stripe
        account, as dealerships manage payments separately.
      </p>

      <h2>1.3 Information Collected for Payments (via Stripe)</h2>

      <p>
        We use Stripe for processing all payments related to subscriptions for
        dealerships. When dealerships make payments via Stripe, we do not
        directly store their payment details, but Stripe collects and stores the
        following:
      </p>

      <ul>
        <li>
          <strong>Email:</strong> To send payment confirmations and invoices.
        </li>
        <li>
          <strong>Billing Address:</strong> Necessary for processing payments.
        </li>
        <li>
          <strong>Telephone:</strong> For verification and communication
          regarding payments.
        </li>
      </ul>

      <p>
        For more details on how Stripe handles your data, please review Stripe’s
        Privacy Policy directly at{" "}
        <a href="https://stripe.com/privacy">Stripe Privacy Policy</a>.
      </p>

      <h2>1.4 Automatically Collected Information</h2>

      <p>
        We may collect certain information automatically when you interact with
        the platform, such as:
      </p>

      <ul>
        <li>
          <strong>Log Data:</strong> Including your IP address, browser type,
          device type, and how you interact with our website (e.g., pages
          viewed, time spent on the platform).
        </li>
        <li>
          <strong>Cookies:</strong> We use cookies to improve user experience
          and provide analytics. Please refer to our Cookie Policy for more
          information.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>

      <p>We use the collected information to:</p>

      <ul>
        <li>
          <strong>Provide Services:</strong> Facilitate account creation,
          dealership and customer management, and bookings.
        </li>
        <li>
          <strong>Communicate:</strong> Send relevant updates, account
          information, and promotional materials (if opted-in).
        </li>
        <li>
          <strong>Process Payments:</strong> Stripe handles dealership
          subscription payments; we do not store payment details on our servers.
        </li>
        <li>
          <strong>Improve Our Platform:</strong> Analyze usage data to enhance
          the functionality and performance of IMAS Cars.
        </li>
        <li>
          <strong>Ensure Compliance:</strong> Use your data where necessary to
          comply with legal and regulatory requirements.
        </li>
      </ul>

      <h2>3. Data Sharing and Disclosure</h2>

      <p>
        We do not sell, trade, or share your personal data with third parties,
        except in the following circumstances:
      </p>

      <ul>
        <li>
          <strong>Service Providers:</strong> We use trusted third-party
          providers (like Stripe for payment processing) to manage specific
          functions. These service providers only access the necessary data to
          perform their services.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information if
          required by law or in response to valid legal processes (e.g., court
          orders, government investigations).
        </li>
      </ul>

      <p>
        Note: All data is processed within the United Kingdom, and we do not
        transfer or share data across borders, except as required by third-party
        services such as Stripe, which may operate internationally.
      </p>

      <h2>4. Data Security</h2>

      <p>
        We take the security of your personal data seriously. We implement
        appropriate technical and organisational measures to safeguard your
        information from unauthorised access, use, alteration, or disclosure.
        However, please be aware that no data transmission over the internet is
        entirely secure, and we cannot guarantee the absolute security of your
        data.
      </p>

      <h2>5. Data Retention</h2>

      <p>
        We retain personal information for as long as necessary to fulfill the
        purposes outlined in this Privacy Policy. If you cancel your account, we
        will delete your personal information unless a longer retention period
        is required by law.
      </p>

      <h2>6. Your Rights Under Data Protection Laws</h2>

      <p>
        In accordance with GDPR and UK data protection laws, you have the
        following rights regarding your personal data:
      </p>

      <ul>
        <li>
          <strong>Right to Access:</strong> You can request access to the
          personal information we hold about you.
        </li>
        <li>
          <strong>Right to Rectification:</strong> You can request that any
          incorrect or incomplete data be corrected.
        </li>
        <li>
          <strong>Right to Erasure:</strong> In certain circumstances, you can
          request the deletion of your personal data.
        </li>
        <li>
          <strong>Right to Restrict Processing:</strong> You can request that we
          limit how we process your data.
        </li>
        <li>
          <strong>Right to Object:</strong> You have the right to object to the
          processing of your personal data in specific circumstances.
        </li>
        <li>
          <strong>Right to Data Portability:</strong> You can request the
          transfer of your data to another service provider in a structured,
          commonly used, and machine-readable format.
        </li>
        <li>
          <strong>Right to Withdraw Consent:</strong> If you have provided
          consent for the processing of your personal data, you can withdraw
          this consent at any time.
        </li>
      </ul>

      <p>
        If you wish to exercise any of these rights, please contact us at{" "}
        <a href="mailto:IMAS-Cars@outlook.com">IMAS-Cars@outlook.com</a>.
      </p>

      <h2>7. Cookies and Tracking Technologies</h2>

      <p>
        We use cookies to enhance your browsing experience, remember
        preferences, and analyze site traffic. For more details on how we use
        cookies and manage your preferences, please refer to our Cookie Policy.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>

      <p>
        We may occasionally update this Privacy Policy to reflect changes in our
        practices or legal obligations. We will notify you of any significant
        updates by posting the updated policy on our website. Please check this
        page regularly for any updates.
      </p>

      <h2>9. Contact Us</h2>

      <p>
        If you have any questions or concerns regarding this Privacy Policy,
        please contact us at:
      </p>

      <p>
        IMAS Cars
        <a href="https://imascars.co.uk">https://imascars.co.uk</a> : Email
        <a href="mailto:IMAS-Cars@outlook.com">IMAS-Cars@outlook.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
