const DashboardCustomerSupport = () => {
  return (
    <div className="dashboardCustomerSupport">
      <p>
        Please Contact{" "}
        <span style={{ textDecoration: "underline" }}>
          IMAS-Cars@outlook.com
        </span>
      </p>
      <p style={{ marginTop: "8px" }}> if you are experiencing issues</p>
    </div>
  );
};

export default DashboardCustomerSupport;
