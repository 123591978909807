import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import jwtDecode from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isDealership = false;
  let isAdmin = false;
  let status = "Customer";

  if (token) {
    const decoded = jwtDecode(token);

    const {
      email,
      roles,
      firstname,
      lastname,
      companyName,
      allowAccess,
      customerId,
      stripeCustomerId,
    } = decoded.UserInfo;

    isDealership = roles.includes("Dealership");
    isAdmin = roles.includes("Admin");

    if (isDealership) status = "Dealership";
    if (isAdmin) status = "Admin";

    return {
      email,
      roles,
      status,
      isDealership,
      isAdmin,
      firstname,
      lastname,
      companyName,
      allowAccess,
      customerId,
      stripeCustomerId,
    };
  }

  return {
    email: "",
    roles: [],
    isDealership,
    isAdmin,
    status,
  };
};

export default useAuth;
