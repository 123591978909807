import { useGetVehiclesQuery } from "../../vehicles/vehiclesApiSlice";
import { HashLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import { FaRandom, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const DealerVehicleList = () => {
  const { companyName } = useAuth();

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const {
    data: vehicles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetVehiclesQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  // Filter vehicles based on companyName
  const filteredVehicles = vehicles
    ? Object.values(vehicles.entities).filter(
        (vehicle) => vehicle.companyName === companyName
      )
    : [];

  const handleClick = (vehicleId) => {
    navigate(`/dashboard/timeline/${vehicleId}`);
  };

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="dealerVehicleTable">
        <div className="myOrdersDealershipFilterAndSearch">
          <div className="filterBtn">
            <button onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}>
              <FaRandom className="filterIconMyOrdersPageCustomer" />
              Filter
            </button>
            {filterDropdownOpen && (
              <ul className={`filterDropdown ${filterDropdownOpen && "show"}`}>
                <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                <li onClick={() => setSelectedFilter("highestPrice")}>
                  Highest Price
                </li>
                <li onClick={() => setSelectedFilter("lowestPrice")}>
                  Lowest Price
                </li>
                {/* Add more filter options as needed */}
              </ul>
            )}
          </div>
          <div className="searchBarOrdersPage">
            <FaSearch className="searchIconMyOrdersPageCustomer" />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
        </div>

        {/* Rest of your table rendering logic */}
        <table>
          {/* Table header */}
          <thead>
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Model Variant</th>
              <th>Year</th>
              <th>Colour</th>
              <th>Mileage</th>
              <th>Registration</th>
              <th>Price Per Day</th>
              <th>Status</th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {filteredVehicles
              .filter((vehicle) => {
                const searchTerms = searchQuery.toLowerCase().split(" ");
                return (
                  searchTerms.length === 0 ||
                  searchTerms.some(
                    (term) =>
                      vehicle.make.toLowerCase().includes(term) ||
                      vehicle.model.toLowerCase().includes(term) ||
                      vehicle.modelVariant.toLowerCase().includes(term) ||
                      vehicle.year.toString().includes(term) ||
                      vehicle.colour.toLowerCase().includes(term) ||
                      vehicle.mileage.toString().includes(term) ||
                      vehicle.registration.toLowerCase().includes(term) ||
                      vehicle.pricePerDay.toString().includes(term) ||
                      vehicle.status.toLowerCase().includes(term)
                  )
                );
              })
              .sort((a, b) => {
                switch (selectedFilter) {
                  case "highestPrice":
                    return b.pricePerDay - a.pricePerDay;
                  case "lowestPrice":
                    return a.pricePerDay - b.pricePerDay;
                  case "newest":
                    return b.year - a.year;
                  case "oldest":
                    return a.year - b.year;
                  default:
                    return 0;
                }
              })
              .map((vehicle) => (
                <tr key={vehicle._id}>
                  {/* Render each cell based on the vehicle properties */}
                  <td
                    onClick={() => handleClick(vehicle._id)}
                    className="dealerVehicleListMake"
                  >
                    {vehicle.make}
                  </td>
                  <td>{vehicle.model}</td>
                  <td>{vehicle.modelVariant}</td>
                  <td>{vehicle.year}</td>
                  <td>{vehicle.colour}</td>
                  <td>{vehicle.mileage}</td>
                  <td>{vehicle.registration}</td>
                  <td>{vehicle.pricePerDay}</td>
                  <td>{vehicle.status}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  return content;
};

export default DealerVehicleList;
