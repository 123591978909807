import { useGetBookingsQuery } from "../../bookings/bookingsApiSlice";
import { HashLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import IndividualTimeLine from "./IndividualTimeLine";

const IndividualVehicleTimeLine = () => {
  const { id: vehicleId } = useParams();

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    // Filter bookings based on the vehicleId
    const filteredBookings = Object.values(bookings.entities).filter(
      (booking) => booking.vehicleDetails._id === vehicleId
    );

    const vehicleInfo =
      filteredBookings.length > 0
        ? `${filteredBookings[0].vehicleDetails.make} ${filteredBookings[0].vehicleDetails.model} ${filteredBookings[0].vehicleDetails.modelVariant} (${filteredBookings[0].vehicleDetails.registration})`
        : "Vehicle Details Not Available";

    content = (
      <div className="individualVehicleTimeline">
        <h1>{vehicleInfo}</h1>
        <IndividualTimeLine
          filteredBookings={filteredBookings}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          error={error}
          vehicleId={vehicleId}
        />
      </div>
    );
  }

  return content;
};

export default IndividualVehicleTimeLine;
