import { store } from "../../app/store";
import { vehiclesApiSlice } from "../vehicles/vehiclesApiSlice";
import { usersApiSlice } from "../users/usersApiSlice";
import { bookingsApiSlice } from "../bookings/bookingsApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Prefetch = () => {
  useEffect(() => {
    store.dispatch(
      vehiclesApiSlice.util.prefetch("getVehicles", "vehiclesList", {
        force: true,
      })
    );
    store.dispatch(
      usersApiSlice.util.prefetch("getUsers", "usersList", { force: true })
    );
    store.dispatch(
      bookingsApiSlice.util.prefetch("getBookings", "bookingsList", {
        force: true,
      })
    );
  }, []);

  return <Outlet />;
};
export default Prefetch;
