import NewBookingForm from "./NewBookingForm";
import { useGetUsersQuery } from "../users/usersApiSlice";
import { useGetVehiclesQuery } from "../vehicles/vehiclesApiSlice";
import { HashLoader } from "react-spinners";

const NewBooking = () => {
  const { users } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      users: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  const { vehicles } = useGetVehiclesQuery("vehiclesList", {
    selectFromResult: ({ data }) => ({
      vehicles: data?.ids.map((id) => data?.entities[id]),
    }),
  });

  if (!users?.length) return <HashLoader color="#36d7b7" />;
  if (!vehicles?.length) return <HashLoader color="#36d7b7" />;

  const content = <NewBookingForm users={users} vehicles={vehicles} />;

  return content;
};

export default NewBooking;
