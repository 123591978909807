import useAuth from "../hooks/useAuth";

import { Link, NavLink } from "react-router-dom";

import {
  FaAngleRight,
  FaHome,
  FaUserEdit,
  FaShoppingCart,
  FaMailBulk,
  FaRegQuestionCircle,
  FaUserFriends,
  FaCar,
  FaCalendarAlt,
  FaCogs,
} from "react-icons/fa";

const DashSideBar = () => {
  const { companyName } = useAuth();

  const content = (
    <div className="dashSideBar">
      <div className="dashSideBarCustomerAccountDivForSeperation">
        <Link to="/dashboard/profile">
          <div className="dashSideBarAccount">
            <p>{companyName}</p>
            <FaAngleRight className="angleRightIcon" />
          </div>
        </Link>
      </div>

      <div className="dashSideBarRestOfLinks">
        <div className="dashSideBarHomeCustomer">
          <NavLink
            to="/dashboard"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            end
          >
            <FaHome className="home" size={22} />
            Home
          </NavLink>
        </div>

        <div className="dashSideBarProfileCustomer">
          <NavLink
            to="/dashboard/profile"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaUserEdit className="profileEdit" size={22} />
            Profile
          </NavLink>
        </div>

        <div className="dashSideBarOrdersCustomer">
          <NavLink
            to="/dashboard/myOrders"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaShoppingCart className="orders" size={22} />
            My Orders
          </NavLink>
        </div>

        <div className="dashSideBarRemindersCustomer">
          <NavLink
            to="/dashboard/reports"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaMailBulk className="reminders" size={22} />
            Reports and Requests
          </NavLink>
        </div>

        <div className="dashSideBarOrdersCustomer">
          <NavLink
            to="/dashboard/manualBooking"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaCogs className="manualBookingIcon" size={22} />
            Manual Booking
          </NavLink>
        </div>

        <div className="dashSideBarOrdersCustomer">
          <NavLink
            to="/dashboard/timeline"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaCalendarAlt className="orders" size={22} />
            Timeline
          </NavLink>
        </div>

        <div className="dashSideBarMyVehicles">
          <NavLink
            to="/dashboard/myVehicles"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaCar className="orders" size={22} />
            My Vehicles
          </NavLink>
        </div>

        <div className="dashSideBarMyCustomers">
          <NavLink
            to="/dashboard/myCustomers"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaUserFriends className="orders" size={22} />
            My Customers
          </NavLink>
        </div>

        <div className="dashSideBarSupportCustomer">
          <NavLink
            to="/dashboard/support"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <FaRegQuestionCircle className="support" size={22} />
            Support
          </NavLink>
        </div>
      </div>
    </div>
  );

  return content;
};

export default DashSideBar;
