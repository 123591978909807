import { useGetBookingsQuery } from "../../bookings/bookingsApiSlice";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { FaRandom, FaSearch } from "react-icons/fa";
import useAuth from "../../../hooks/useAuth";

const DashboardMyOrders = () => {
  const { email } = useAuth();
  const navigate = useNavigate();

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [filteredBookings, setFilteredBookings] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking.vehicleOwner.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]);

  // console.log(filteredBookings);

  const getTodaysBookings = (bookings) => {
    const today = new Date().toISOString().slice(0, 10);
    return bookings.filter((booking) => booking.dateOfBooking === today);
  };

  const getBookingsByStatus = (bookings, statuses) => {
    return bookings.filter((booking) =>
      statuses.includes(booking.bookingStatus)
    );
  };

  const countCompletedOrders = (bookings) => {
    return bookings.filter(
      (booking) => booking.bookingStatus === "Vehicle Fufilled and Returned"
    ).length;
  };

  const handleH3Click = () => {
    // Navigate to the desired route
    navigate("/dashboard/timeline");
  };

  const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);

  // Set the number of pages you want to show at once
  const maxVisiblePages = 8;

  // Calculate the start and end of the range of buttons to show
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  // Adjust startPage in case we're near the end
  const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    const todaysBookings = getTodaysBookings(filteredBookings);

    const todaysOrdersCount = todaysBookings.length;

    const targetStatuses = ["Vehicle Sent Out", "Awaiting Vehicle Return"];
    const vehicleSentOrReturningBookings = getBookingsByStatus(
      filteredBookings,
      targetStatuses
    );
    const vehicleSentOrReturningCount = vehicleSentOrReturningBookings.length;

    const completedOrdersCount = countCompletedOrders(filteredBookings);

    const handleActiveVehiclesClick = () => {
      // Navigate to the desired route
      navigate("/dashboard/activeVehicles");
    };

    const handleOrdersTodayClick = () => {
      // Navigate to the desired route
      navigate("/dashboard/ordersToday");
    };

    const handleCompletedOrdersClick = () => {
      // Navigate to the desired route
      navigate("/dashboard/completedOrders");
    };

    content = (
      <div className="dashboardDealership">
        <div className="myOrdersSearchbarDealer">
          <div className="myOrdersSearchbarDivDealer">
            <div className="myOrdersSearchbarIcon">
              <FaSearch />
            </div>
            <div className="myOrdersSearchbarInputField">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>
        </div>
        <h2>Analytics</h2>
        <div className="dashboardDealershipAnalytics">
          <div className="dashbaordDealershipAnalyticsTotalRevenue">
            <div className="dashbaordDealershipAnalyticsTotalRevenueIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/IMG_2704.JPG"
                alt="Active Vehicles"
              />
            </div>
            <div className="dashbaordDealershipAnalyticsTotalRevenueDetails">
              <p>Active Vehicles</p>
              <h3
                onClick={handleActiveVehiclesClick}
                className="dashboardActiveVehicles"
              >
                {vehicleSentOrReturningCount}
              </h3>
            </div>
          </div>
          <div className="dashbaordDealershipAnalyticsTodayRevenue">
            <div className="dashbaordDealershipTodayRevenueIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.06.40.png"
                alt="Orders Today"
              />
            </div>
            <div className="dashbaordDealershipTodayRevenueDetails">
              <p>Orders Today</p>
              <h3
                onClick={handleOrdersTodayClick}
                className="dashboardActiveVehicles"
              >
                {todaysOrdersCount}
              </h3>
            </div>
          </div>
          <div className="dashbaordDealershipAnalyticsOrdersToday">
            <div className="dashbaordDealershipAnalyticsOrdersTodayIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.53.png"
                alt="Completed Orders"
              />
            </div>
            <div className="dashbaordDealershipAnalyticsOrdersTodayDetails">
              <p>Completed Orders</p>
              <h3
                onClick={handleCompletedOrdersClick}
                className="dashboardActiveVehicles"
              >
                {completedOrdersCount}
              </h3>
            </div>
          </div>
          <div className="dashbaordDealershipAnalyticsActiveVehicles">
            <div className="dashbaordDealershipAnalyticsActiveVehiclesIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.14.png"
                alt="Vehicle Time lines"
              />
            </div>
            <div className="dashbaordDealershipAnalyticsActiveVehiclesDetails">
              <p>Vehicle Time Lines</p>
              <h3
                className="dashboardMyOrdersVehicleTimeLineh3"
                onClick={handleH3Click}
              >
                Click Here
              </h3>
            </div>
          </div>
        </div>
        <h2>Recent Orders</h2>
        <div className="dashboardDealershipRecentOrders">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>

          <table className="dashboardDealershipRecentOrdersTable">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Item</th>
                <th>Duration</th>
                <th>Customer</th>
                <th>Fulfilment Status</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings
                .filter((booking) => {
                  const searchTerms = searchQuery.toLowerCase().split(" ");
                  // return (
                  //   searchTerms.length === 0 ||
                  //   searchTerms.some(
                  //     (term) =>
                  //       booking.id.toLowerCase().includes(term) ||
                  //       booking.dateOfBooking.toLowerCase().includes(term) ||
                  //       booking.vehicleDetails.make
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.vehicleDetails.model
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.vehicleDetails.modelVariant
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.customer.firstname
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.customer.lastname
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.bookingStatus.toLowerCase().includes(term) ||
                  //       booking.totalPrice.toString().includes(term)
                  //   )
                  // );
                  return (
                    searchTerms.length === 0 ||
                    searchTerms.some(
                      (term) =>
                        booking.id?.toLowerCase().includes(term) ||
                        booking.dateOfBooking?.toLowerCase().includes(term) ||
                        booking.vehicleDetails?.make
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails?.model
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails?.modelVariant
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.customer?.firstname
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.customer?.lastname
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.bookingStatus?.toLowerCase().includes(term) ||
                        booking.totalPrice?.toString().includes(term)
                    )
                  );
                })
                .sort((a, b) => {
                  switch (selectedFilter) {
                    case "newest":
                      return (
                        new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                      );
                    case "oldest":
                      return (
                        new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                      );
                    case "mostExpensive":
                      return b.totalPrice - a.totalPrice;
                    case "leastExpensive":
                      return a.totalPrice - b.totalPrice;
                    default:
                      return 0;
                  }
                })
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((booking) => (
                  <tr key={booking.id}>
                    <td
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      className="dashboardOrdersBookingId"
                    >
                      {booking.id}
                    </td>
                    <td>{booking.dateOfBooking}</td>
                    <td>
                      {booking.vehicleDetails.make}{" "}
                      {booking.vehicleDetails.model}{" "}
                      {booking.vehicleDetails.modelVariant}
                    </td>
                    <td>
                      {new Date(booking.startDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}
                      -
                      {new Date(booking.endDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}{" "}
                      (
                      {Math.max(
                        0,
                        Math.ceil(
                          (new Date(booking.endDate) -
                            new Date(booking.startDate)) /
                            (1000 * 3600 * 24)
                        )
                      )}
                      days)
                    </td>
                    <td>
                      {booking.customer
                        ? `${booking.customer.firstname} ${booking.customer.lastname}`
                        : booking.mainCustomer.fullname}
                    </td>

                    <td>{booking.bookingStatus}</td>
                    <td>£{booking.totalPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="pagination">
            {/* Previous Button */}
            {currentPage > 1 && (
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="paginationArrows"
              >
                Prev
              </button>
            )}

            {/* {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={index + 1 === currentPage ? "active" : ""}
              >
                {index + 1}
              </button>
            ))} */}

            {Array.from(
              { length: endPage - adjustedStartPage + 1 },
              (_, index) => adjustedStartPage + index
            ).map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={pageNumber === currentPage ? "active" : ""}
              >
                {pageNumber}
              </button>
            ))}

            {/* Next Button */}
            {currentPage < totalPages && (
              <button onClick={() => setCurrentPage(currentPage + 1)}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardMyOrders;
