import Header from "../../components/Header";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";

import usePersist from "../../hooks/usePersist";

import { HashLoader } from "react-spinners";

import jwtDecode from "jwt-decode";

const LoginCustomer = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist(true);

  useEffect(() => {
    if (!persist) {
      setPersist(true);
    }
  }, [persist, setPersist]);

  // console.log(persist);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const { accessToken } = await login({ email, password }).unwrap();
  //     dispatch(setCredentials({ accessToken }));
  //     setEmail("");
  //     setPassword("");
  //     navigate("/customerdashboard");
  //   } catch (err) {
  //     if (!err.status) {
  //       setErrMsg("No Server Response");
  //     } else if (err.status === 400) {
  //       setErrMsg("Missing Email or Password");
  //     } else if (err.status === 401) {
  //       setErrMsg("Unauthorized");
  //     } else {
  //       setErrMsg(err.data?.message);
  //     }
  //     errRef.current.focus();
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken } = await login({ email, password }).unwrap();
      dispatch(setCredentials({ accessToken }));

      setEmail("");
      setPassword("");

      // Decode access token to get roles
      const decodedToken = jwtDecode(accessToken);
      const roles = decodedToken.UserInfo.roles;

      console.log(roles);

      // Navigate based on user role
      if (roles.includes("Dealership")) {
        navigate("/dashboard"); // Dealership dashboard
      } else if (roles.includes("Customer")) {
        navigate("/customerdashboard"); // Customer dashboard
      } else if (roles.includes("Admin")) {
        navigate("/customerdashboard");
      } else {
        setErrMsg("User role not recognized");
      }
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
      errRef.current.focus();
    }
  };

  const handleEmailInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  // const handleToggle = () => setPersist((prev) => !prev);

  const errClass = errMsg ? "errmsg" : "offscreen";

  if (isLoading) return <HashLoader color="#36d7b7" />;

  const content = (
    <section className="Login">
      <Header />
      <main className="login">
        <p ref={errRef} className={errClass} aria-live="assertive">
          {errMsg}
        </p>
        <h2 className="signUp">Please Login</h2>

        <form className="form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            className="form__input--Login"
            type="text"
            id="email"
            ref={userRef}
            value={email}
            onChange={handleEmailInput}
            autoComplete="off"
            required
          />

          <label htmlFor="password">Password</label>
          <input
            className="form__input--Login"
            type="password"
            id="password"
            onChange={handlePwdInput}
            value={password}
            required
          />
          <button className="form__submit-button">Sign In</button>
          <div className="form__persist-container">
            {/* <label htmlFor="persist" className="form__persist-label">
              <input
                type="checkbox"
                className="form__checkbox"
                id="persist"
                onChange={handleToggle}
                checked={persist}
              /> */}
            <span className="form__persist-text">
              Don't have an account?{" "}
              <a href="/users/newCustomer">Sign Up Here</a>
              <br /> Dealerships <a href="/users/newDealership">Sign up Here</a>
            </span>{" "}
            {/* </label> */}
          </div>
        </form>
      </main>
    </section>
  );

  return content;
};

export default LoginCustomer;
