import React from "react";
import { FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetBookingsQuery } from "./bookingsApiSlice";
import { memo } from "react";

const Booking = ({ bookingId }) => {
  const { booking } = useGetBookingsQuery("bookingsList", {
    selectFromResult: ({ data }) => ({
      booking: data?.entities[bookingId],
    }),
  });

  const navigate = useNavigate();

  if (booking) {
    const handleEdit = () => navigate(`/bookings/${bookingId}`);

    return (
      <tr className="table__row booking">
        <td className="tableCell">{booking.vehicle}</td>
        <td className="tableCell">{booking.dealership}</td>
        <td className="tableCell">{booking.pickUpLocation}</td>
        <td className="tableCell">{booking.dropOffLocation}</td>
        <td className="tableCell">{booking.startDate}</td>
        <td className="tableCell">{booking.endDate}</td>
        <td className="tableCell">{booking.dateOfBooking}</td>
        <td className="tableCell">{booking.orderNumber}</td>
        <td className="tableCell">{booking.totalPrice}</td>
        <td className="tableCell">{booking.chauffeur ? "Yes" : "No"}</td>
        <td className="tableCell">{booking.description}</td>
        <td className="tableCell">{booking.mainCustomer.customer}</td>
        <td className="tableCell">{booking.mainCustomer.customer}</td>
        {/* Add more properties from mainCustomer if needed */}
        <td className="tableCell">{booking.additionalDriver ? "Yes" : "No"}</td>
        {/* Add more properties from additionalDriver if needed */}
        <td className="tableCell">{booking.bookingStatus}</td>
        <td className="tableCell">{booking.complete ? "Yes" : "No"}</td>
        <td className="tableCell">
          {booking.insuranceDetails
            ? booking.insuranceDetails.insuranceCompany
            : "no"}
        </td>

        <td className="tableCell">{booking.beforeImages}</td>
        <td className="tableCell">{booking.afterImages}</td>
        <td className="tableCell">
          <button className="icon-button table__button" onClick={handleEdit}>
            <FaPen />
          </button>
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

const memoizedBooking = memo(Booking);
export default memoizedBooking;
