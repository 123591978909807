import { FaMapMarkerAlt } from "react-icons/fa";
import { useGetVehiclesQuery } from "./vehiclesApiSlice";
import { memo } from "react";
// import memoizedVehicleDetails from "./VehicleDetails";

const Vehicle = ({ vehicleId }) => {
  const { vehicle } = useGetVehiclesQuery("vehiclesList", {
    selectFromResult: ({ data }) => ({
      vehicle: data?.entities[vehicleId],
    }),
  });

  if (vehicle) {
    return (
      <div className="vehicleCard">
        <img src={vehicle.images[0]} alt="Vehicle" className="vehicleCardImg" />

        <h2 className="vehicleCardMakeModel">
          {vehicle.make} {vehicle.model} {vehicle.modelVariant}
        </h2>
        <p className="vehicleCardPricePerDay">
          {vehicle.pricePerDay}
          <span>/day</span>
        </p>
        <p className="vehicleCardMileageLimit">
          {vehicle.mileageLimit} <span>Miles</span>
        </p>
        <p className="vehicleCardDeposit">
          {vehicle.deposit} <span>deposit</span>
        </p>
        <div className="location">
          <img
            src={vehicle.profilePicture}
            alt="ownerProfilePicture"
            className="vehicleCardProfileImage"
          />
          <FaMapMarkerAlt className="locationIcon" />{" "}
          {/* Fix this so it is not hard coded */}
          <p>{vehicle.address.cityOrTown}</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const memoizedVehicle = memo(Vehicle);
export default memoizedVehicle;
