import { useGetBookingsQuery } from "./bookingsApiSlice";
import Booking from "./Booking.js";
import { HashLoader } from "react-spinners";

const BookingsList = () => {
  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = bookings;

    const tableContent = ids?.length
      ? ids.map((bookingId) => (
          <Booking key={bookingId} bookingId={bookingId} />
        ))
      : null;
    // fix the table with all the fields from the booking schema
    content = (
      <table className="tableOfUsers">
        <thead className="tableThead">
          <tr>
            <th scope="col" className="userTableVehicle">
              Vehicle
            </th>
            <th scope="col" className="userTabledealership">
              dealership
            </th>
            <th scope="col" className="userTablepickUpLocation">
              pickUpLocation
            </th>
            <th scope="col" className="userTabledropOffLocation">
              dropOffLocation
            </th>
            <th scope="col" className="userTablestartDate">
              startDate
            </th>
            <th scope="col" className="userTableendDate">
              endDate
            </th>
            <th scope="col" className="userTabledateOfBooking">
              dateOfBooking
            </th>
            <th scope="col" className="userTableorderNumber">
              orderNumber
            </th>
            <th scope="col" className="userTabletotalPrice">
              totalPrice
            </th>
            <th scope="col" className="userTablechauffeur">
              chauffeur
            </th>
            <th scope="col" className="userTabledescription">
              description
            </th>
            <th scope="col" className="userTablemainCustomer">
              mainCustomer
            </th>
            <th scope="col" className="userTableadditionalDriver">
              additionalDriver
            </th>
            <th scope="col" className="userTablebookingStatus">
              bookingStatus
            </th>
            <th scope="col" className="userTablecomplete">
              complete
            </th>
            <th scope="col" className="userTableinsuranceDetails">
              insuranceDetails
            </th>
            <th scope="col" className="userTablebeforeImages">
              beforeImages
            </th>
            <th scope="col" className="userTableafterImages">
              afterImages
            </th>
          </tr>
        </thead>
        <tbody>{tableContent}</tbody>
      </table>
    );
  }

  return content;
};

export default BookingsList;
