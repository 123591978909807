import { useState } from "react";
import { HashLoader } from "react-spinners";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

const localizer = momentLocalizer(moment);

const IndividualTimeLine = ({
  filteredBookings,
  isSuccess,
  isLoading,
  isError,
  error,
  vehicleId,
}) => {
  const [tooltip, setTooltip] = useState(null);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    const events = filteredBookings
      .filter((booking) => booking.vehicleDetails._id === vehicleId)
      .map((booking) => {
        // const customerName = `${booking.customer.firstname} ${booking.customer.lastname}`;
        const customerName = booking.customer
          ? `${booking.customer.firstname} ${booking.customer.lastname}`
          : booking.mainCustomer.fullname;

        const vehicleDetails = `${booking.vehicleDetails.make} ${booking.vehicleDetails.model} - ${booking.vehicleDetails.registration}`;
        const bookingStatus = `${booking.bookingStatus}`;
        const bookingId = `${booking.id}`;
        const vehicleColour = `${booking.vehicleDetails.colour}`;

        // Calculate duration in days
        const durationInDays = Math.max(
          0,
          Math.ceil(
            (new Date(booking.endDate) - new Date(booking.startDate)) /
              (1000 * 3600 * 24)
          )
        );

        return {
          start: new Date(booking.startDate),
          end: new Date(booking.endDate),
          title: `${customerName} - ${vehicleDetails}`,
          duration: durationInDays, // Add duration property
          // You can add more properties as needed
          bookingStatus,
          bookingId,
          vehicleColour,
        };
      });

    // Customize event rendering to change the height of events
    const eventStyleGetter = (event, start, end, isSelected) => {
      const style = {
        height: 20, // Set the height of each event (adjust as needed)
        fontSize: "12px",
        cursor: "pointer",
        backgroundColor: event.vehicleColour,
      };

      return {
        style,
      };
    };

    const handleSelectEvent = (event, e) => {
      setTooltip({
        title: `${event.title}`,
        content: (
          <div>
            <div>
              {event.start.toLocaleDateString("en-GB", {
                month: "short",
                day: "numeric",
              })}{" "}
              -{" "}
              {event.end.toLocaleDateString("en-GB", {
                month: "short",
                day: "numeric",
              })}{" "}
              ({event.duration} days)
            </div>
            <p>{event.bookingStatus}</p>
            <p>Booking ID - {event.bookingId}</p>
          </div>
        ),
        x: e.pageX,
        y: e.pageY,
      });
    };

    const handleCloseTooltip = () => {
      setTooltip(null);
    };

    content = (
      <div className="individualVehicleTimeline">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 750, width: 1100 }}
          eventPropGetter={eventStyleGetter}
          timeslots={10}
          onSelectEvent={handleSelectEvent}
        />

        {/* Tooltip for displaying more information */}
        {tooltip && (
          <div
            style={{
              position: "absolute",
              zIndex: 1000,
              background: "white",
              padding: 10,
              border: "1px solid #ccc",
              borderRadius: 4,
              top: tooltip.y + 10,
              left: tooltip.x,
            }}
          >
            <p>{tooltip.title}</p>
            {tooltip.content}
            <button
              onClick={handleCloseTooltip}
              className="individualVehicleTimelineToolTipCloseBtn"
            >
              Close
            </button>
          </div>
        )}
      </div>
    );
  }

  return content;
};

export default IndividualTimeLine;
