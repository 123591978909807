import useAuth from "../../hooks/useAuth";
import { useState, useEffect } from "react";

import { useGetBookingsQuery } from "../bookings/bookingsApiSlice";

import { HashLoader } from "react-spinners";

const DashboardCustomerReminders = () => {
  const { email } = useAuth();

  const [reminders, setReminders] = useState({});

  // Fetch bookings using the hook directly
  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (bookings && bookings.entities) {
      const { entities, ids } = bookings;

      const qualifyingBookingIds = ids.filter(
        (id) => entities[id].customer?.email === email
      ); // Filter all matching booking IDs

      const updatedReminders = []; // Array to store reminders

      qualifyingBookingIds.forEach((bookingId) => {
        const booking = entities[bookingId];

        const today = new Date();
        const startDate = new Date(booking.startDate);
        const endDate = new Date(booking.endDate);

        // Pick Up Reminder logic
        const pickUpReminderDays = Math.floor(
          (startDate - today) / (1000 * 60 * 60 * 24)
        );
        if (pickUpReminderDays <= 3 && pickUpReminderDays > 0) {
          const getPickUpLocationDetails = () => {
            if (booking.pickUpLocationInDepth.type === "Custom") {
              return `
                Address Line 1: ${
                  booking.pickUpLocationInDepth.address.line1 || "N/A"
                }
                Address Line 2: ${
                  booking.pickUpLocationInDepth.address.line2 || "N/A"
                }
                City: ${booking.pickUpLocationInDepth.address.city || "N/A"}
                County: ${booking.pickUpLocationInDepth.address.county || "N/A"}
                Postcode: ${
                  booking.pickUpLocationInDepth.address.postCode || "N/A"
                }
              `;
            } else if (booking.pickUpLocationInDepth.type === "Dealership") {
              return `
                Address Line 1: ${
                  booking.vehicleDetails.address.houseNumberAndStreetName ||
                  "N/A"
                }
                Address Line 2: ${
                  booking.vehicleDetails.address.locality || "N/A"
                }
                City: ${booking.vehicleDetails.address.cityOrTown || "N/A"}
                Postcode: ${booking.vehicleDetails.address.postcode || "N/A"}
                Country: ${booking.vehicleDetails.address.country || "N/A"}
              `;
            } else {
              return "Pick-up location details are not available.";
            }
          };

          const reminder = {
            bookingId: booking.id,
            message: `Your car booking for ${booking.vehicleDetails.make} ${
              booking.vehicleDetails.model
            } starts in ${pickUpReminderDays} days! Pick up location: ${
              booking.pickUpLocationInDepth.type
            }
          
            ${getPickUpLocationDetails()}`,
            // message: `Your car booking for ${booking.vehicleDetails.make} ${booking.vehicleDetails.model} starts in ${pickUpReminderDays} days! Pick up location: ${booking.pickUpLocationInDepth.type}`,
            daysLeft: pickUpReminderDays,
          };
          updatedReminders.push(reminder);
        }

        // Drop Off Reminder logic
        const dropOffReminderDays = Math.floor(
          (endDate - today) / (1000 * 60 * 60 * 24)
        );
        if (dropOffReminderDays <= 3 && dropOffReminderDays > 0) {
          const getDropOffLocationDetails = () => {
            if (booking.dropOffLocationInDepth.type === "Custom") {
              return `
                Address Line 1: ${
                  booking.dropOffLocationInDepth.address.line1 || "N/A"
                }
                Address Line 2: ${
                  booking.dropOffLocationInDepth.address.line2 || "N/A"
                }
                City: ${booking.dropOffLocationInDepth.address.city || "N/A"}
                County: ${
                  booking.dropOffLocationInDepth.address.county || "N/A"
                }
                Postcode: ${
                  booking.dropOffLocationInDepth.address.postCode || "N/A"
                }
              `;
            } else if (booking.dropOffLocationInDepth.type === "Dealership") {
              return `
                Address Line 1: ${
                  booking.vehicleDetails.address.houseNumberAndStreetName ||
                  "N/A"
                }
                Address Line 2: ${
                  booking.vehicleDetails.address.locality || "N/A"
                }
                City: ${booking.vehicleDetails.address.cityOrTown || "N/A"}
                Postcode: ${booking.vehicleDetails.address.postcode || "N/A"}
                Country: ${booking.vehicleDetails.address.country || "N/A"}
              `;
            } else {
              return "Drop-off location details are not available.";
            }
          };
          const reminder = {
            bookingId: booking.id,
            message: `Your car booking for ${booking.vehicleDetails.make} ${
              booking.vehicleDetails.model
            } starts in ${pickUpReminderDays} days! Drop off location: ${
              booking.dropOffLocationInDepth.type
            } ${getDropOffLocationDetails()}`,
            daysLeft: dropOffReminderDays,
          };
          updatedReminders.push(reminder);
        }
      });

      setReminders(updatedReminders);
    } else {
      console.log("Loading bookings or bookings data is empty");
    }
  }, [bookings, email]);

  const reminderList = Object.values(reminders).filter(
    (reminder) => reminder.daysLeft <= 3
  );

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="dashboardCustomerRemindersPage">
        <h3 className="dashboardCustomerHomePageRemindersH3">Reminders</h3>
        <div className="dashboardCustomerHomePageReminders">
          {/* Display reminders if there are any */}
          {reminderList.length > 0 && (
            <ul>
              {reminderList.map((reminder) => (
                <li key={reminder.bookingId}>{reminder.message}</li>
              ))}
            </ul>
          )}
          {/* Display a message if there are no reminders */}
          {reminderList.length === 0 && (
            <p style={{ marginLeft: "20px" }}>No reminders at this time.</p>
          )}
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardCustomerReminders;
